import { action, observable, decorate, computed, toJS } from 'mobx';
import { handleError, geoCodeAndParse } from '../utilities';
import api from '../api';
import { mapIndexToReadableTitle, mapSingularTitleToPlural, mapTitleToSingularTitle } from '../constants';
import profileStore from './profileStore';

function restructureEntitiesForServer(entities) {
  return {
    subscribeToVisitingLocation: !entities.visitingLocation.hidden,
    subscribeToCurrentLocation: !entities.currentLocation.hidden,
    visitingLocation: entities.visitingLocation.subscriptions.map(i => i._id),
    currentLocation: entities.currentLocation.subscriptions.map(i => i._id),
    wantingToCall: entities.wantingToCall.value
  };
}

class SubscriptionStore {
  constructor() { }

  inProgress = false;
  loadingSubscriptions = false;
  subscriptions = [];
  entities = {
    wantingToCall: {
      id: 2,
      title: "Wanting to call",
      type: 'toggle',
      subtype: 'wantingToCall',
      value: false
    },
    currentLocation: {
      id: 1,
      title: "Changing where they reside",
      type: 'location',
      subtype: "current",
      hidden: false,
      subscriptions: [],
      placeholder: "Ex: Palo Alto"
    },
    visitingLocation: {
      id: 0,
      title: "Visiting a location",
      type: 'location',
      subtype: 'visiting',
      subscriptions: [],
      hidden: false,
      placeholder: "Ex: Palo Alto"
    }
  };
  searching = false;
  initialSearch = false;

  toggleValue(type) {
    this.entities[type].value = !this.entities[type].value
  }

  toggleHidden(type) {
    let entityType;
    switch (type) {
      case 'visiting':
        entityType = 'visitingLocation';
        break;
      case 'current':
        entityType = 'currentLocation'
        break;
      default:
        return;
    }
    this.entities[entityType].hidden = !this.entities[entityType].hidden
  }

  populateState(data) {
    this.entities.visitingLocation.hidden = !data.subscribeToVisitingLocation;
    this.entities.visitingLocation.subscriptions = data.visitingLocation;
    this.entities.currentLocation.hidden = !data.subscribeToCurrentLocation
    this.entities.currentLocation.subscriptions = data.currentLocation;
    this.entities.wantingToCall.value = data.wantingToCall;
  }

  updateSelectedEntities(type, value, action = 'ADD') {
    let entityType;
    switch (type) {
      case 'visiting':
        entityType = 'visitingLocation';
        break;
      case 'current':
        entityType = 'currentLocation'
        break;
      default:
        return;
    }
    if (action === 'ADD') {
      if (!this.entities[entityType].subscriptions.find(e => e._id === value._id)) {
        this.entities[entityType].subscriptions = [
          ...this.entities[entityType].subscriptions,
          value
        ];
      }
    } else if (action === "REMOVE") {
      this.entities[entityType].subscriptions = this.entities[entityType].subscriptions.filter(
        i => i._id !== value
      )
    }
  }

  async subscribe(id, type) {
    const subscriptions = profileStore.userData.subscriptions;
    
    if (type == 'location') {
      if (!subscriptions.visitingLocation.find(i => i._id == id)) {
        subscriptions.visitingLocation.push(id);
      }

      if (!subscriptions.currentLocation.find(i => i._id == id)) {
        subscriptions.currentLocation.push(id);
      }
    }

    try {
      const response = await api.user.updateSubscriptions(subscriptions);
      profileStore.updateUserObject(response);
      this.inProgress = false;
      this.subscriptions = [...this.subscriptions, response];
    } catch (error) {
      throw handleError(error);
    }
  }

  async getSubscriptions() {
    await profileStore.getUserData()
    this.populateState(profileStore.userData.subscriptions)
  }

  async searchThroughEntity(entityName, value) {
    let response = [];
    try {
      switch (entityName) {
        case mapIndexToReadableTitle.dev_skills:
          response = await this.searchForSkills(value);
          break;
        case mapIndexToReadableTitle.dev_companies:
          response = await this.searchForCompany(value);
          break;
        case mapIndexToReadableTitle.dev_universities:
          response = await this.searchForUniversity(value);
          break;
        default:
          break;
      }
      return response;
    } catch (error) {
      throw handleError(error);
    }
  }

  async addNewEntity(entityName, value) {
    let response = [];
    try {
      switch (entityName) {
        case mapIndexToReadableTitle.dev_skills:
          response = await this.addSkill(value);
          break;
        case mapIndexToReadableTitle.dev_companies:
          response = await this.addCompany(value);
          break;
        case mapIndexToReadableTitle.dev_universities:
          response = await this.addUniversity(value);
          break;
        default:
          return;
      }
      return response;
    } catch (error) {
      throw handleError(error);
    }
  }

  async searchWrapper(apiRequest, q) {
    try {
      this.searching = true;
      this.initialSearch = true;
      const data = await apiRequest(q);
      this.searching = false;
      this.initialSearch = false;
      return data;
    } catch (error) {
      this.searching = false;
      throw handleError(error);
    }
  }

  async searchForCompany(value) {
    try {
      let q = encodeURIComponent(value);
      let apiRequest = api.company.searchForCompany;
      const data = await this.searchWrapper(apiRequest, q);
      return data;
    } catch (error) {
      throw handleError(error);
    }
  }

  async searchForUniversity(value) {
    try {
      let q = encodeURIComponent(value);
      let apiRequest = api.university.searchForUniversity;
      const data = await this.searchWrapper(apiRequest, q);
      return data;
    } catch (error) {
      throw handleError(error);
    }
  }

  async searchForSkills(value) {
    try {
      let q = encodeURIComponent(value);
      let apiRequest = api.skill.searchForSkill;
      const data = await this.searchWrapper(apiRequest, q);
      return data;
    } catch (error) {
      throw handleError(error);
    }
  }

  async addCompany(name) {
    try {
      let apiRequest = api.company.addCompany;
      const data = await this.searchWrapper(apiRequest, name);
      return data;
    } catch (error) {
      throw handleError(error);
    }
  }

  async addSkill(name) {
    try {
      let apiRequest = api.skill.addSkill;
      const data = await this.searchWrapper(apiRequest, name);
      return data;
    } catch (error) {
      throw handleError(error);
    }
  }

  async addUniversity(name) {
    try {
      let apiRequest = api.university.addUniversity;
      const data = await this.searchWrapper(apiRequest, name);
      return data;
    } catch (error) {
      throw handleError(error);
    }
  }

  async lookupLocation(value) {
    try {
      this.searching = true;
      this.initialSearch = true;
      const payload = await geoCodeAndParse(value);
      const data = await api.location.lookupLocation(payload);
      this.searching = false;
      this.initialSearch = false;
      return data;
    } catch (error) {
      this.searching = false;
      throw handleError(error);
    }
  }

  async updateSubscriptions() {
    const restructured = restructureEntitiesForServer(this.entities);
    try {
      this.inProgress = true;
      const data = await api.user.updateSubscriptions(restructured);
      this.inProgress = false;
      profileStore.updateUserObject(data);
    } catch (error) {
      this.inProgress = false;
      throw handleError(error);
    }
  }

  cleanSubscriptions(type) {
    let entityType;
    switch (type) {
      case 'visiting':
        entityType = 'visitingLocation';
        break;
      case 'current':
        entityType = 'currentLocation'
        break;
      default:
        return;
    }
    this.entities[entityType].subscriptions = [];
  }
}

decorate(SubscriptionStore, {
  updateSubscriptions: action,
  lookupLocation: action,
  addNewEntity: action,
  searching: observable,
  initialSearch: observable,
  entities: observable,
  searchThroughEntity: action,
  loadingSubscriptions: observable,
  inProgress: observable,
  subscriptions: observable,
  getSubscriptions: action,
  subscribe: action,
  updateSelectedEntities: action,
  toggleValue: action,
  toggleHidden: action
});

export default new SubscriptionStore();
