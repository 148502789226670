import * as yup from 'yup';
import { errors } from '../messages';

const schema = yup.object().shape({
  description: yup.string().required(errors.MISSING_MEETUP_DESCRIPTION),
  address: yup.string().required(errors.MISSING_MEETUP_ADDRESS),
  end: yup.string().required(errors.MISSING_MEETUP_END),
  start: yup.string().required(errors.MISSING_MEETUP_START),
  month: yup.number().required(errors.MISSING_MEETUP_MONTH),
  day: yup.number().required(errors.MISSING_MEETUP_DAY),
  url: yup.string().matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i, errors.MISSING_MEETUP_URL),
  name: yup.string().required(errors.MISSING_MEETUP_NAME),
});

export default schema;
