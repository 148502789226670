import { action, observable, decorate, autorun, computed } from 'mobx';
import { handleError, hermes, trimChar } from '../utilities';
import api from '../api';
import loadingStore from './loadingStore';
import { errors } from '../messages';


class ReferenceStore {
  constructor() {
    this.handler = autorun(() => {
      // console.log(this.referenceResult);
    });
    this.loadMoreResults = this.loadMoreResults.bind(this);
  }

  loadingUsers = false;
  _results = {};
  referenceName = '';
  filteredResults = [];
  locations = [{ text: 'Select a location', value: 'All', key: 0 }];
  selectedLocation = 'All';
  resultType = 'All';
  selectedLocationType = 'All';
  endOfResults = false;
  loadingMoreResults = false;
  // if this creates a bug, just reset it's value
  // at the place wehere we call getReferenceData
  firstSearchMade = false;

  locationTypes = [
    {
      key: 0,
      text: 'All associations',
      value: 'All',
    },
    {
      key: 1,
      text: 'Residing',
      value: 'Current',
    },
    {
      key: 2,
      text: 'Origin',
      value: 'Previous',
    },
    {
      key: 3,
      text: 'Visiting',
      value: 'Visiting',
    },
  ];
  referenceResult = [];

  get resultsByLocationType() {
    const locType = this.selectedLocationType;
    if (locType == 'all') {
      return this._results;
    }
  }

  get results() {
    return this._results[this.resultType];
  }

  async changeLocation(filter) {
    loadingStore.setLoading()

    this.endOfResults = false;
    
    this.selectedLocation = filter;
    const [type, slug] = trimChar(window.location.pathname, '/').split('/').map(el => decodeURIComponent(el));

    if (filter == 'All') {
      this.resultType = filter;
    } else {
      if (!this._results[filter]) {
        try {
          const response = await api.reference.getFilteredReferenceData({ slug, type, filter });
          this._results[filter] = response;
  
        } catch(error) {
          handleError(error);
        }
      }
      this.resultType = filter; 
    }
    
    loadingStore.setLoaded();
  }

  async changeLocationType(value) {
    loadingStore.setLoading();
    
    this.endOfResults = false;

    this.selectedLocationType = value;
    const [type, slug] = trimChar(window.location.pathname, '/').split('/').map(el => decodeURIComponent(el));

    if (value == 'All') {
      this.resultType = value;
    } else {
      const filter = value.toLowerCase() + 'Location';

      if (!this._results[filter]) {
        try {
          const response = await api.reference.getFilteredReferenceData({ slug, type, filter });
          this._results[filter] = response;
  
        } catch(error) {
          handleError(error);
        }
      }
      this.resultType = filter; 
    }

    loadingStore.setLoaded();
  }

  async loadMoreResults() {
    loadingStore.setLoading();
    const [type, slug] = trimChar(window.location.pathname, '/').split('/').map(el => decodeURIComponent(el));
    const page = Math.ceil(this.results.length / 20);

    let filter;
    if (this.resultType !== 'All') {
      filter = this.resultType;
    }
    
    try {
      const response = await api.reference.getFilteredReferenceData({ slug, type, filter, page });
      this._results[this.resultType] = [...this._results[this.resultType], ...response];
      if (!response.length || response.length %20 !== 0) {
        this.endOfResults = true;
      }
    } catch(error) {
      handleError(error);
    }

    loadingStore.setLoaded();
  }


  async getReferenceData(slug, type) {
    loadingStore.setLoading()
    this.firstSearchMade = false;
    this.endOfResults = false;
    this._results = {};
    try {
      const response = await api.reference.getReferenceData({ slug, type});
      this.referenceName = response.name;
      this.firstSearchMade = true;
      this._results['All'] = response.users;
      if (type !== 'location') {
        this.locations = [{ text: 'Select a location', value: 'All', key: 0 }, ...response.locations.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          return -1;
        }).map((location, i) => {
          return { text: location.name, value: location._id, key: i + 1 }
        })];
      }
      loadingStore.setLoaded();

    } catch (error) {
      loadingStore.setLoaded();
      throw handleError(error);
    }
  }
}

decorate(ReferenceStore, {
  loadMoreResults: action,
  firstSearchMade: observable,
  endOfResults: observable,
  loadingMoreResults: observable,
  selectedLocation: observable,
  loadingUsers: observable,
  referenceResult: observable,
  getReferenceData: action,
  changeLocation: action,
  _results: observable,
  filteredResults: observable,
  selectedLocationType: observable,
  changeLocationType: action,
  results: computed,
  resultsByLocationType: computed,
  referenceName: observable,
  locations: observable,
  resultType: observable,
});

export default new ReferenceStore();
