import * as yup from 'yup';
import { errors } from '../messages';

const currentDate = new Date()
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth();


const objectIdRegex = /^[a-fA-F0-9]{24}$/;
const objectIdSchema = yup.string().matches(objectIdRegex);

const studentDetail = yup.object({
  university: objectIdSchema.nullable().required(),
  degreeType: yup
    .string()
    .nullable()
    .required(),
  programme: yup
    .string()
    .nullable()
    .required(),
  description: yup.string(),
  currentlyStudyHere: yup.boolean().default(false),
});


const studentDetails = yup.object({
  universities: yup.array(studentDetail).min(1),
});

const workerDetail = yup.object({
  company: objectIdSchema.nullable().required(),
  title: yup
    .string()
    .nullable()
    .required(),
  description: yup.string(),
  startYear: yup.number().required(),
  currentlyWorkHere: yup.boolean(),
  endYear: yup.mixed().when('currentlyWorkHere', {
    is: true, // alternatively: (val) => val == true
    then: yup.number().integer(),
    otherwise: yup.number().integer().max(currentYear).required(errors.MISSING_END_YEAR),
  }),
  endMonht: yup.mixed().when('endYear', {
    is: endYear => endYear == currentYear,
    then: yup.number().integer().max(currentMonth),
    otherwise: yup.number().integer()
  }),
});

const workerDetails = yup.object({
  companies: yup.array(workerDetail).min(1),
});

export default {
  workerDetails,
  studentDetails,
};
