import { inject, observer } from 'mobx-react';
import React, { Fragment, Component } from 'react';
import DatePicker from 'react-datepicker';
import SearchWithBubble from '../../components/Forms/SearchWithBubble';
import TextAreaWithCounter from '../../components/Forms/TextAreaWithCounter';
import { Button, Image, Dropdown, Card, Checkbox, Form } from 'semantic-ui-react';
import { hermes, capitalize } from '../../utilities';
import InputWithCounter from '../Forms/InputWithCounter';
import { withFormik, ErrorMessage } from 'formik';
import newExperienceSchema from '../../schemas/newExperienceSchema';
import { bgMagentaBright } from 'ansi-colors';
import { months } from '../../constants';
import { errors, success } from '../../messages';
import _ from 'lodash'

function restructureWorkerData(data) {

  const payload = {
    title: data.title,
    company: data.company ? data.selectedCompany._id : undefined,
    dates: {
      start: {
        month: data.startMonth,
        year: data.startYear,
      },
      end: data.currentlyWorkHere ? undefined : {
        month: Number.parseInt(data.endMonth),
        year: data.endYear,
      },
      currentlyWorkHere: data.currentlyWorkHere || false
    },
    description: data.description,
  };
  return payload;
}

class NewExperienceEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCompany: null,
      searchResults: [],
    };
    //
    // this.collectData = this.collectData.bind(this);
    // this.saveOrUpdate = this.saveOrUpdate.bind(this);
    this.addCompany = this.addCompany.bind(this);
    this.searchForCompany = this.searchForCompany.bind(this);
    this.handleResultSelect = this.handleResultSelect.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTxtChange = this.handleTxtChange.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleChecked = this.handleChecked.bind(this);

    let currentYear = new Date().getFullYear();

    this.startYearRange = [{ key: 'default', text: 'Start Year', value: null }];
    this.endYearRange = [{ key: 'default', text: 'End Year', value: null }];


    for (let i = currentYear; i >= 1900; i--) {
      this.startYearRange.push({ key: i, text: i, value: i });
      this.endYearRange.push({ key: i, text: i, value: i });
    }

    this.startMonthOptions = [{ key: 'default', text: 'Start Month', value: null }];
    this.endMonthOptions = [{ key: 'default', text: 'End Month', value: null }];

    for (let i = 0; i < 12; i++) {
      this.startMonthOptions.push({ key: i, text: months[i], value: i });
      this.endMonthOptions.push({ key: i, text: months[i], value: i });
    }

  }

  componentDidMount() {
    const { onboardingStore, previousData } = this.props;
    // console.log(previousData, 'previss');
    if (previousData) {
      this.setState({
        selectedCompany: previousData.company || null,
        searchResults: onboardingStore.companySearchResults,
      });
    } else {
      this.setState({
        selectedCompany: null,
        searchResults: onboardingStore.companySearchResults,
      });
    }
  }

  handleTxtChange(option) {
    return e => {
      this.setState({
        [option]: e.target.value,
      });
    };
  }

  async searchForCompany(e, { value }) {
    const { onboardingStore } = this.props;
    await this.setState({
      company: value,
    });
    this.props.handleChange(
      {
        target: {
          type: 'text',
          name: 'company',
          id: 'company',
          value,
        },
      },
      { value },
    );

    if (this.state.timeout) {
      window.clearTimeout(this.state.timeout);
    }

    const id = setTimeout(() => {
      onboardingStore.searchForCompany(value);
    }, 600);

    await this.setState({
      timeout: id,
    });
  }

  async handleResultSelect(e, { result }) {
    await this.setState({
      selectedCompany: result,
    });


    this.props.setFieldValue('selectedCompany', result);
  }

  handleResultRemove = () => {
    this.setState({
      company: '',
      selectedCompany: null,
    });

    this.props.handleChange(
      {
        target: {
          type: 'text',
          name: 'company',
          id: 'company',
          value: '',
        },
      },
      { value: '' },
    );

    this.props.setFieldValue('company', '');
    this.props.setFieldValue('selectedCompany', null);
  }

  async addCompany(name) {
    const { onboardingStore } = this.props;
    try {
      const response = await onboardingStore.addCompany({ name });
      await this.setState({
        selectedCompany: response,
      });
      this.props.setFieldValue('company', '');
      this.props.setFieldValue('selectedCompany', response);
    } catch (error) {
      hermes.error(error);
    }
  }

  handleDateChange(option) {
    return async date => {
      await this.setState({ [option]: date });
      this.props.handleChange(
        {
          target: {
            type: 'date',
            name: option,
            id: option,
            value: date,
          },
        },
        { value: date },
      );
    };
  }

  // collectData() {
  //   const { state } = this;
  //   const prePayload = {
  //     company: state.selectedCompany._id,
  //     description: state.description,
  //     title: state.title,
  //     startDate: state.startDate,
  //     endDate: state.endDate,
  //     currentlyWorkHere: state.currentlyWorkHere,
  //   };
  //   const payload = restructureWorkerData(prePayload);
  //   console.log(payload);
  //   return payload;
  // }
  //
  // async saveOrUpdate() {
  //   const { previousData, profileStore } = this.props;
  //   const payload = this.collectData();
  //   if (!previousData) {
  //     await profileStore.saveExperience(payload);
  //     this.toggleEdit();
  //   } else {
  //     const { _id } = previousData;
  //     await profileStore.updateExperience(_id, payload);
  //     this.toggleEdit();
  //   }
  // }

  toggleEdit() {
    const { toggleState } = this.props;
    toggleState();
  }

  async handleChecked(e, { checked: currentlyWorkHere }) {
    console.log(e.persist());
    // console.log(e);
    this.props.handleChange(
      {
        target: {
          type: 'mouseup',
          name: 'currentlyWorkHere',
          id: 'currentlyWorkHere',
          value: currentlyWorkHere,
        },
      },
      { value: currentlyWorkHere },
    );
    await this.setState({ currentlyWorkHere });
  }

  render() {
    const { values, setFieldValue, isSaving, handleChange, handleBlur, handleSubmit } = this.props;
    const _errors = this.props.errors;

    const { onboardingStore, toggleState } = this.props;
    const { title, company, startMonth, startYear, endMonth, endYear, description, currentlyWorkHere, selectedCompany } = values;
    const { initialCompanySearch } = onboardingStore;
    // console.log(values, errors);

    const computedCompanies = [...onboardingStore.computedCompanies];


    // if (errors.company) {
    //   hermes.error(errors.MISSING_COMPANY);
    // } else if (_errors.endDate) {
    //   hermes.error(errors.MISSING_END_YEAR);
    // } else if (_errors.startDate) {
    //   hermes.error(errors.MISSING_START_YEAR);
    // } else if (_errors.title) {
    //   hermes.error(errors.MISSING_JOB_TITLE);
    // } else if (_errors.selectedCompany) {
    //   hermes.error(errors.MISSING_COMPANY_NAME);
    // }

    const exists = computedCompanies[0] && computedCompanies[0].name.toLowerCase().trim() === company.toLowerCase("").trim();

    if (!initialCompanySearch && !exists) {
      computedCompanies.push({
        name: capitalize(company.trim()),
        isAddition: true,
        onClick: () => this.addCompany(capitalize(company.trim()))
      })
    }

    return (
      <Card>
        <div className="new-experience">
          <Image
            width="80"
            height="80"
            src="https://react.semantic-ui.com/images/wireframe/image.png"
          />
          <Form onSubmit={handleSubmit} autoComplete="off">
            <div className="details">
              <SearchWithBubble
                icon=""
                name="company"
                results={computedCompanies}
                value={company}
                selectedEntity={selectedCompany ? selectedCompany.name : ""}
                onChange={this.searchForCompany}
                onSelect={this.handleResultSelect}
                onRemove={this.handleResultRemove}
                addNewValue={this.addCompany}
                labelName="Company"
                placeholder="Company"
                noResultsMessage={(
                  <p>Searching for company</p>
                )}
              />

              <div className="dates">
                <div className="start ">
                  <Dropdown
                    name="startMonth"
                    options={this.startMonthOptions}
                    search
                    selection
                    placeholder="Start month"
                    value={startMonth}
                    onChange={(e, { value }) => setFieldValue('startMonth', value)}
                  />
                  <Dropdown
                    name="startYear"
                    options={this.startYearRange}
                    search
                    selection
                    placeholder="Start year"
                    value={startYear}
                    onChange={(e, { value }) => setFieldValue('startYear', value)}
                  />
                </div>
                {!currentlyWorkHere ?
                  <div className="end">
                    <Dropdown
                      name="endMonth"
                      options={this.startMonthOptions}
                      search
                      selection
                      placeholder="End month"
                      value={endMonth}
                      onChange={(e, { value }) => setFieldValue('endMonth', value)}
                    />
                    <Dropdown
                      name="endYear"
                      options={this.endYearRange}
                      search
                      selection
                      placeholder="End year"
                      value={endYear}
                      onChange={(e, { value }) => setFieldValue('endYear', value)}
                    />
                  </div> : <div className="present-date"> - Present</div>}
                <Checkbox
                  label="Tick if you currently work here"
                  checked={currentlyWorkHere}
                  onChange={(e, { checked }) => setFieldValue('currentlyWorkHere', checked)}
                  onBlur={handleBlur}
                />
              </div>
              <div className="job-title">
                <InputWithCounter
                  name="title"
                  value={title}
                  placeholder="Role"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxCount={80}
                />
              </div>
              <div className="description">
                <TextAreaWithCounter
                  name="description"
                  value={description}
                  placeholder="Enter a decription"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxCount={120}
                />
              </div>
            </div>

            <div className="actions">
              <Button className="save" type="submit" primary>
                Save
              </Button>
              <Button onClick={toggleState} basic>
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    );
  }
}

function createNeatObject({ previousData: pd }) {
  let previousData = pd || { company: {} };
  const { dates, title, company, description } = previousData;

  if (dates) {
    var { start, end, currentlyWorkHere } = dates;
  } else {
    var start, end, currentlyWorkHere;
  }

  return {
    company: company.name || '',
    dates: dates || undefined,
    startMonth: start ? start.month : null,
    startYear: start ? start.year : null,
    endMonth: end ? end.month : null,
    endYear: end ? end.year : null,
    title: title || '',
    description: description || '',
    selectedCompany: company || undefined,
    currentlyWorkHere: currentlyWorkHere,
  };
}

const MyEnhancedForm = withFormik({
  mapPropsToValues: props => {
    return createNeatObject(props);
  },
  validationSchema: newExperienceSchema,

  handleSubmit: async (values, bag) => {
    let { selectedCompany } = values;
    if(_.isEmpty(selectedCompany)){
      hermes.error(errors.UNKNOWN_COMPANY_NAME);
      return;
    }
    const { previousData, profileStore, toggleState } = bag.props;

    const payload = restructureWorkerData(values);

    try {
      if (!previousData) {
        await profileStore.saveExperience(payload);
        toggleState();
        hermes.success(success.EXPERIENCE_ADDED);
      } else {
        const { _id } = previousData;
        await profileStore.updateExperience(_id, payload);
        toggleState();
        hermes.success(success.EXPERIENCE_UPDATED);
      }
    } catch (error) {
      console.log(error)
      hermes.error(errors.EXPERIENCE_UPDATE_ERROR);
    }
  },

  displayName: 'BasicForm',
})(observer(NewExperienceEntry));

export default inject('profileStore', 'onboardingStore')(observer(MyEnhancedForm));
