import * as yup from 'yup';
import { errors } from '../messages';

const objectIdRegex = /^[a-fA-F0-9]{24}$/;
const yupSchema = yup.string().required(errors.LOCATION_NOT_SELECTED);
const schema = yup.object({
  currentLocation: yup.string().required(errors.LOCATION_NOT_SELECTED),
  previousLocation: yup.string(),
});

export default schema;
