import React from 'react';
import gif1 from '../../images/1.gif';
import gif2 from '../../images/2.gif';
import gif3 from '../../images/3.gif';
import gif4 from '../../images/4.gif';

const Slide4 = ({ className }) => (
  <div className={className + " s4"}>
    <div className="images">
      <img src={gif1} />
      <img src={gif4} />
      <img src={gif2} />
      <img src={gif3} />
    </div>
    <div className="s4-wrapper">
      <div className="centralize">
        <h1>Supported by tech we built ourselves.</h1>
        <p>Our mission is to make it seamless for high potentials to meaningfully connect. For free in minutes, not dollars and hours. That means rethinking the way people match, connect and meet. Broadcast useful signals to listeners and ping their phones.</p>
      </div>
    </div>
  </div>
)

export default Slide4;
