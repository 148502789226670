import React, { Fragment } from 'react';
import { Image, Button, Grid, Card, Header } from 'semantic-ui-react';
import { getOrdinalNum, expired } from '../../utilities';
import LinkWithLoader from '../LinkWithLoader';
import { inject, observer } from 'mobx-react';

const lookupentityType = {
  university: 'university',
  skill: 'skill',
  location: 'location',
  company: 'company',
};

function buildUrl(entityType, slug) {
  return `/${lookupentityType[entityType.toLowerCase()]}/${slug}`;
}

function generateDate(date) {
  const parsedDate = new Date(date);
  const ordinalNumber = getOrdinalNum(Number.parseInt(parsedDate.toLocaleDateString('en-us', {
    day: 'numeric'
  })));

  return `${parsedDate.toLocaleDateString('en-us', {
    month: 'long',
  })} ${ordinalNumber}${parsedDate.getFullYear() == new Date().getFullYear() ? '' : ' ' + parsedDate.getFullYear()}`;
}

function getMinutesDifference(start, end) {

  const parsedStart = new Date(start);
  const parsedEnd = new Date(end);
  
  return Math.ceil((parsedEnd - parsedStart) / 60000)
}

function getDaysDifference(start, end) {

  const parsedStart = new Date(start);
  const parsedEnd = new Date(end);
  
  // console.log(parsedStart, parsedEnd)

  return Math.ceil((parsedEnd - parsedStart) / (1000 * 60 * 60 * 24))
}


function generateTime(date) {
  const parsedTime = new Date(date).toLocaleTimeString().replace(/(.*)\D\d+/, '$1');
  return parsedTime;
}

function generateLocationChangeFragment({ type, actionType, entityType, entity, createdAt, expireAt }) {
  if (type === 'visiting') {
    const days = getDaysDifference(createdAt, expireAt);
    return (
      <span style={expired(expireAt) ? { textDecoration: 'line-through'} : undefined}>
        I'm visiting{' '}
        <LinkWithLoader to={buildUrl(entityType, entity.slug)}>{entity.name}</LinkWithLoader>{' '}
        for {days} day{days !== 1 ? 's': null}
      </span>
    );
  } else if (type === 'current') {
    return (
      <Fragment>
        I relocated to{' '}
        <LinkWithLoader to={buildUrl(entityType, entity.slug)}>{entity.name}</LinkWithLoader>
      </Fragment>
    )
  }
  return (
    <Fragment>
      I'm originally from{' '}
      <LinkWithLoader to={buildUrl(entityType, entity.slug)}>{entity.name}</LinkWithLoader>
    </Fragment>
  );
}

const UserFeedItem = ({
  _id,
  entitySubtype,
  entityType,
  addNewFavourite,
  deleteSignal,
  user,
  entity,
  createdAt,
  expireAt,
  actionType,
  profileStore: { userData }
}) => {
  let description;
  if (actionType == 'WANT_TO_CALL') {
    const minutes = getMinutesDifference(createdAt, expireAt)
    description = (
      <span style={expired(expireAt) ? { textDecoration: 'line-through'} : undefined}>
           I want to call on Facebook Messenger within {minutes} minute{minutes !== 1 ? 's' : null}
      </span>
    )
  }
  else if (actionType == 'ADDED') {
    if (entitySubtype == 'VISITING_LOCATION') {
      const days = getDaysDifference(createdAt, expireAt);
      description = (
        <span style={expired(expireAt) ? { textDecoration: 'line-through'} : undefined}>
          I'm visiting{' '}
          <LinkWithLoader to={buildUrl(entityType, entity.slug)}>{entity.name}</LinkWithLoader>{' '}
          for {days} day{days !== 1 ? 's': null}
        </span>
      );
    } else if (entitySubtype === 'CURRENT_LOCATION') {
      description = (
        <Fragment>
          I just signed up and I'm currently in{' '}
          <LinkWithLoader to={buildUrl(entityType, entity.slug)}>{entity.name}</LinkWithLoader>
        </Fragment>
      );
    } else {
      description = (
        <Fragment>
          I'm originally from{' '}
          <LinkWithLoader to={buildUrl(entityType, entity.slug)}>{entity.name}</LinkWithLoader>
        </Fragment>
      );
    }
  } else if (actionType == 'CHANGED') {
    if (entitySubtype == 'VISITING_LOCATION') {
      const days = getDaysDifference(createdAt, expireAt);
      description = (
        <span style={expired(expireAt) ? { textDecoration: 'line-through'} : undefined}>
          I'm visiting{' '}
          <LinkWithLoader to={buildUrl(entityType, entity.slug)}>{entity.name}</LinkWithLoader>{' '}
          for {days} day{days !== 1 ? 's': null}
        </span>
      );
    } else if (entitySubtype == 'PREVIOUS_LOCATION') {
      description = (
        <Fragment>
          I'm originally from{' '}
          <LinkWithLoader to={buildUrl(entityType, entity.slug)}>{entity.name}</LinkWithLoader>
        </Fragment>
      );
    } else if (entitySubtype == 'CURRENT_LOCATION') {
      description = generateLocationChangeFragment({
        type: 'current',
        entity,
        entityType,
        actionType,
      });
    }
  }

  return (
    <Card>
    {/* aded this to fix the bug cannot find user image of undefine */}
    {user && 
    <React.Fragment>
      <Header inverted>
        {user.image && <Image circular src={user.image} />}
        <Header.Content>
          <LinkWithLoader to={`/${user.slug}`}>{user.name}</LinkWithLoader>
          <Header.Subheader>{generateDate(createdAt)} at {generateTime(createdAt)}</Header.Subheader>
        </Header.Content>
        <div className="inline tablet-and-up right-floated borderless">
          {userData && userData._id === user._id ?
            new Date() - new Date(createdAt) <= 24*60*60*1000 ? (
              <Button
                basic
                floated="right"
                onClick={() => {
                  deleteSignal(_id);
                  }}
                >
                  Delete
              </Button>
            ) : null 
          : (
            <Button
              basic
              floated="right"
              onClick={() => {
                addNewFavourite(user._id);
                }}
              >
                Save
            </Button>
          )}
        </div>
      </Header>


      <div className="feed-description">
        <p>{description}</p>
      </div>

      <div className="mobile-only center">
      {userData && userData._id === user._id ?
        new Date() - new Date(createdAt) <= 24*60*60*1000 ? (
          <Button
            basic
            onClick={() => {
              deleteSignal(_id);
              }}
            >
              Delete
          </Button>
        ) : null 
      : (
        <Button
          basic
          onClick={() => {
            addNewFavourite(user._id);
            }}
          >
            Save
        </Button>
      )}
      </div>
      </React.Fragment>
    }
    </Card>
  );
};

export default inject('profileStore')(observer(UserFeedItem));
