import React, { Fragment } from 'react';
import { Button } from 'semantic-ui-react';
import profileStore from '../../stores/profileStore';
import { observer } from 'mobx-react';
import withRouter from 'react-router-dom/withRouter';

/* Im actually doing this wrong, tbh, I need to place this in
my router config, and render it on every page, that way it's easier to
whitelist certain routes. */

const EmailVerificationBar = observer(({ send, sending, emailSent, loggedIn, history }) => {
  const user = profileStore.userData;
  if (
    (user && user.isVerifiedEmail) ||
    !user ||
    !loggedIn ||
    new RegExp('/verify-email/').test(history.location.pathname)
  ) {
    return null;
  } else if (user) {
    return (
      <Fragment>
        <div className="email-verification">
          <span style={{ margin: "5px 0" }}>Verify your email address ({user.email})</span>{' '}
          <Button disabled={sending} primary onClick={send}>
            {!emailSent ? "Resend verification email" : "Email sent" }
          </Button>
        </div>
      </Fragment>
    );
  }
});

export default withRouter(EmailVerificationBar);
