import authStore from './authStore';
import onboardingStore from './onboardingStore';
import favouriteStore from './favouriteStore';
import referenceStore from './referenceStore';
import profileStore from './profileStore';
import searchStore from './searchStore';
import usertimelineStore from './usertimelineStore';
import notificationStore from './notificationStore';
import subscriptionStore from './subscriptionStore';
import loadingStore from './loadingStore';
import faqStore from './faqStore';

export default {
  notificationStore,
  onboardingStore,
  referenceStore,
  favouriteStore,
  profileStore,
  authStore,
  searchStore,
  usertimelineStore,
  subscriptionStore,
  loadingStore,
  faqStore
};
