import React, { Component } from 'react';
import { LocationTable } from '../../components/Reference/index';
import { inject, observer } from 'mobx-react';
import { Dropdown, Header } from 'semantic-ui-react';
import reference from '../../api/reference';

class LocationReference extends Component {
  constructor(props) {
    super(props);
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  componentDidMount() {
    window.ga('set', {
      page: '/location/:id',
      title: 'Location Reference Page'
    })

    window.ga('send', 'pageview')
  }

  handleLocationChange(e, { value }) {
    const { referenceStore, loadingStore } = this.props;
    if (!loadingStore.loading) referenceStore.changeLocationType(value);
  }

  render() {
    const { match, referenceStore, addNewFavourite } = this.props;
    let children, { selectedLocationType, results, loadingUsers, locationTypes } = referenceStore;

    if (loadingUsers) {
      children = null;
    } else {
      children = <LocationTable users={results ? results : []} addNewFavourite={addNewFavourite} />;
    }

    return (
      <div className="reference">
        <div className="column">
          <h1 className="ui center aligned">{referenceStore.referenceName}</h1>
          <p className="ui center aligned">Location</p>
          <div className="filter-dropdown">
            <Dropdown
              placeholder="Filter locations"
              search
              selection
              value={selectedLocationType}
              options={locationTypes}
              onChange={this.handleLocationChange}
            />
          </div>
        </div>
        {children}
      </div>
    );
  }
}

export default inject('referenceStore', 'loadingStore')(observer(LocationReference));
