import React, { Fragment } from 'react';
import { Card, Image, Button } from 'semantic-ui-react';
import LinkWithLoader from '../LinkWithLoader';


const Person = ({ _id, name, location, bio, image, addNewFavourite, slug, entitySlug, profile }) => {
  const company = profile && profile.experiences && profile.experiences.find(exp => {
    if (exp.company) {
      return exp.company.slug == entitySlug;
    }
  });
  const year = company && company.dates && company.dates.end ? company.dates.end.year : null;
  const present = company && company.dates && company.dates.currentlyWorkHere;

  return (
    <Card>
      <div className="reference-item">
        <div className="user">
          <Image circular width="60" height="60" src={image || 'https://avatars2.githubusercontent.com/u/44?v=4'} />
          <div className="user-details">
            <div className="name">
              <LinkWithLoader to={`/${slug}`}>{name}</LinkWithLoader>
            </div>
            <div className="location">
              <LinkWithLoader to={`/location/${location.currentLocation.slug}`}>
                {location && location.currentLocation ? location.currentLocation.name : ''}
              </LinkWithLoader>
            </div>
            <div className="headline">{bio ? bio.headline : null}</div>
          </div>
        </div>

        <div className="year">
          <span className="mobile-only">Final year</span>
          <span>{present ? 'Present' : year ? year : 'Not specified'}</span>
        </div>
        <div className="save">
          <Button
            onClick={() => {
              addNewFavourite(_id);
            }}
            basic
          >
            Save
          </Button>
        </div>
      </div>
    </Card>
  );
};

const CompanyTable = ({ users, slug, addNewFavourite }) => {
  return (
    <div className="references">
      <header className={users.length == 0 ? 'hidden' : 'tablet-and-up'}>
        <div className="user">Person</div>
        <div className="association">Final year</div>
      </header>

      {users.map((user, index) => (
        <Person key={index} {...user} addNewFavourite={addNewFavourite} entitySlug={slug} />
      ))}
    </div>
  );
};

export default CompanyTable;
