import * as yup from 'yup';
import { errors } from '../messages';
// const yup = require('yup');

const schema = yup.object({
  name: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  password: yup
    .string()
    .required()
    .min(8, errors.PASSWORD_TOO_SHORT),
  // confirmPassword: yup.string().oneOf([yup.ref('password'), null], "Passwords don't match"),
});

export default schema;

// const valid = schema.isValid({
//   name: 'Griffith',
//   email: 'griffithgmail.com',
//   password: 'gwuah2018'
// }).then(valid => {
//   console.log(valid)
//   schema.validate({
//     name: 'Griffith',
//     email: 'griffithgmail.com',
//     password: 'gwuah2018'
//   }).then(valid => {
//     console.log(valid)
//   }).catch(e => {
//     console.log(e, 'err')
//   })
// }).catch(e => {
//   console.log('error', e)
// })

// console.log(valid);
// console.log(clean);
