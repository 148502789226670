import React, { Component } from 'react';
import { Container } from 'semantic-ui-react';

class Pitch extends Component {
  componentDidMount() {
    window.ga('set', {
      page: '/pitch',
      title: 'Pitch'
    })

    window.ga('send', 'pageview')
  }

  render() {
    return (
      <Container className="legal-page">
        <div style={{
             position: "relative",
              display: 'block',
              width: '100%'}}>
          <iframe width="97%" 
            height="315" 
            src="https://www.youtube.com/embed/-RgzQEJutE4" 
            frameborder="0" 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen
            marginWidth="auto"
            ></iframe>
        </div>
        <h1>The Gathrer Platform</h1>
        <p>Our mission is to make it seamless for people to meaningfully connect. For free in minutes, not dollars and hours. That means rethinking the way people match, connect and meet. Broadcast useful signals to listeners and ping their phones in your communities, be it your alumni networks, colleges, schools, incubators or companies.</p>

        <h2>How do we reach out?</h2>
        <p>Contact Joshua Vantard on Linkedin or via email at <a href="mailto:joshua@intcrzyppl.com">joshua@intcrzyppl.com</a>.</p>
        <h2>What will I use this for?</h2>
        <p>We are building a modular system rolled out to real companies, universities, alumni networks and schools for free so their members can:</p>
        <ul>
          <li>Meet more</li>
          <li>Raise money</li>
          <li>Get jobs and hire</li>
          <li>Call more</li>
          <li>Brainstorm more</li>
          <li>Date</li>
          <li>Collaborate on new projects</li>
          <li>Know when they are visiting each others towns</li>
          <li>etc.</li>
        </ul>
        <p>This was a basic introduction done in 10 minutes before the EQT Ventures Boat Party.</p>

      </Container>
    )
  }
}

export default Pitch;
