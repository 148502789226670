import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { inject } from 'mobx-react';
import { Container, Header, Segment, Form, Input, Button } from 'semantic-ui-react';
import '../../css/reference.css';
import { hermes } from '../../utilities';
import api from '../../api';
import { errors, success } from '../../messages';
import { goTo } from '../../routing';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyingToken: false,
      password: '',
      confirmpassword: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const { match, history } = this.props;

    window.ga('set', {
      page: '/password/new',
      title: 'Reset Password'
    })

    window.ga('send', 'pageview')

    await this.setState({ verifyingToken: true });

    try {
      const response = await api.user.verifyPasswordToken({ token: match.params.token });
      if (response.valid) {
        this.setState({ verifyingToken: false });
      } else {
        hermes.error(errors.INVALID_PASSWORD_TOKEN);
        goTo('/', history);
      }
    } catch (error) {
      hermes.error(error);
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  async handleSubmit(e) {
    e.preventDefault();

    const { match, history, authStore } = this.props;
    const { password, confirmPassword } = this.state;


    if (password == confirmPassword) {
      const response = await api.user.resetPassword({ password, token: match.params.token });
      if (response.updated) {
        hermes.success(success.PASSWORD_DID_RESET);
        authStore.logout();
        goTo('/', history);
      }
    } else {
      hermes.error(errors.PASSWORDS_DONT_MATCH);
    }
  }

  render() {
    let children = null;
    if (this.state.verifyingToken) {
      children = (
        <div
          style={{
            textAlign: 'center',
            color: 'white',
          }}
        >
          <br />
          <h2>Verifying token ...</h2>
        </div>
      );
    } else {
      children = (
        <Fragment>
          <Header inverted>Reset Password</Header>
          <Segment>
            <Form onSubmit={this.handleSubmit} autoComplete="off">
              <Form.Field>
                <label>New Password</label>
                <Input
                  value={this.state.password}
                  placeholder="must be at least 8 characters"
                  type="password"
                  name="password"
                  onChange={this.handleChange}
                  required
                />
              </Form.Field>

              <Form.Field>
                <label>Confirm New Password</label>
                <Input
                  placeholder="Confirm your new password"
                  value={this.state.confirmPassword}
                  type="password"
                  name="confirmPassword"
                  onChange={this.handleChange}
                  required
                />
              </Form.Field>
              <Button
                primary
                type="submit"
                disabled={this.state.saving}
              >
                Save
              </Button>
            </Form>
          </Segment>
        </Fragment>
      );
    }
    return (
      <Container
        fluid
        className="form-template"
        style={{
          paddingTop: '50px',
          paddingBottom: '50px'
        }}
      >
        {children}
      </Container>
    );
  }
}

export default withRouter(inject('profileStore', 'authStore')(ResetPassword));
