import React, { Component } from 'react';
import { Header, Favourites } from '../../components/Favourites';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Container } from 'semantic-ui-react';
import '../../css/reference.css';
import { hermes, isOnBottom } from '../../utilities';
import { success } from '../../messages';

class FavouritePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.saveNote = this.saveNote.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.removeFavourite = this.removeFavourite.bind(this);
  }

  handleLocationChange(e, { value }) {
    const { favouriteStore } = this.props;
    favouriteStore.changeLocation(value);
  }

  handleNameChange(e, { value }) {
    const { favouriteStore } = this.props;
    favouriteStore.changeName(value);
  }

  async saveNote(data) {
    const { favouriteStore } = this.props;

    try {
      const response = await favouriteStore.updateFavourite(data);
      hermes.success(success.NOTE_ADDED);
      return response;
    } catch (error) {
      hermes.error(error);
    }
  }

  async removeFavourite(data) {
    const { favouriteStore } = this.props;
    try {
      await favouriteStore.removeFromFavourite(data);
      hermes.success(success.REMOVED_FROM_FAVOURITES);
    } catch (error) {
      hermes.error(error);
    }
  }


  componentDidMount() {
    window.ga('set', {
      page: '/favourites',
      title: 'Saved'
    })

    window.ga('send', 'pageview')

    window.addEventListener('scroll', this.onScroll, true);
    const { favouriteStore } = this.props;
    window.fstore = favouriteStore;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  onScroll = () => {
    const { firstSearchMade, loadMoreResults, endOfResults } = this.props.favouriteStore;
    const { loading } = this.props.favouriteStore;

    if (
      isOnBottom() &&
      !loading &&
      !endOfResults &&
      firstSearchMade
    ) {
      loadMoreResults();
    }
  };

  render() {
    const { favouriteStore } = this.props;
    const { favourites, names, locations, filter } = favouriteStore;

    return (
      <Container className="reference">
        <Header
          filter={filter}
          handleNameChange={this.handleNameChange}
          handleLocationChange={this.handleLocationChange}
          names={names}
          locations={locations}
        />

        <Favourites
          favourites={favourites}
          saveNote={this.saveNote}
          removeFavourite={this.removeFavourite}
        />
      </Container>
    );
  }
}

export default withRouter(inject('favouriteStore', 'loadingStore')(observer(FavouritePage)));
