import React from 'react';
import { TextArea, Icon } from 'semantic-ui-react';

const TextAreaWithCounter = ({ name, id, label, value, className, onChange, placeholder, maxCount }) => {

  return (
    <div>
      <TextArea
        name={name}
        id={id}
        label={label}
        className={className}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxCount}
      />
      {
        // <span>
        // {value.length}/{maxCount}
        // </span>
      }
    </div>
  );
};

export default TextAreaWithCounter;
