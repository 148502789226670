import React, { Component } from 'react';
import { Button, Checkbox, Form, Segment, Header, Container, Input, TextArea } from 'semantic-ui-react';
import applyPage1Schema from '../../schemas/applyPage1Schema';    
import { withFormik } from 'formik';
import { hermes } from '../../utilities';

class ApplyPage1 extends Component {
  handleCheckBoxChange = name => (_, { checked }) => {
    const { setFieldValue } = this.props;
    setFieldValue(name, checked);
  }
  
  render() {
    let {
      values,
      handleChange,
      handleBlur,
      handleSubmit,
    } = this.props;

    const { 
      readMediumArticle,
      requestToJoinFacebookGroup,
      linkedin,
      facebook,
      otherLinks,
      referredBy,
      messagedJoshua,
      fetchDataFromLinkedIn
    } = values;

    return (
      <div className="form-template-wrapper">
        <Container fluid id="form-template-container" className="form-template edit-profile">
          <Header inverted>
            Apply
          </Header>
          <Segment>
            <Form onSubmit={handleSubmit} autoComplete="off">

              <Form.Field required>
                <label>Linkedin username</label>
                <Input
                  required
                  placeholder="End of your Linkedin profile url"
                  name="linkedin"
                  value={linkedin}
                  onChange={handleChange}
                />
              </Form.Field>

              <Form.Field>
                <label>Any webpages, social media accounts or media referring to you</label>
                <TextArea
                  name="otherLinks"
                  placeholder="Ex: www.myportfolio.com and nytimes.com/article"
                  value={otherLinks}
                  onChange={handleChange}
                />
              </Form.Field>

              <Form.Field required>
                <label>Facebook username</label>
                <Input
                  required
                  placeholder="End of your Facebook profile url"
                  name="facebook"
                  value={facebook}
                  onChange={handleChange}
                />
              </Form.Field>

              <Form.Field>
                <label>How did you find out about us?</label>
                <Input
                  name="referredBy"
                  placeholder="Ex: from Mark Zuckerberg"
                  autoComplete="new-password"
                  value={referredBy}
                  onChange={handleChange}
                />
              </Form.Field>

              <Form.Field className="checkbox-with-link" required>
                <Checkbox
                  name="requestToJoinFacebookGroup"
                  checked={requestToJoinFacebookGroup}
                  onChange={this.handleCheckBoxChange('requestToJoinFacebookGroup')}
                />
                <label>I requested to join the <a href="//www.facebook.com/groups/joinicp" target="_blank">Facebook group</a></label>
              </Form.Field>

              <Form.Field className="checkbox-with-link">
                <Checkbox
                  name="readMediumArticle"
                  checked={readMediumArticle}
                  onChange={this.handleCheckBoxChange('readMediumArticle')}
                />
                <label>I've skim read the <a href="//medium.com/icp-community/what-is-intelligent-crazy-people-cbaa52781a03" target="_blank">Medium article</a></label>
              </Form.Field>

              <Form.Field className="checkbox-with-link" required>
                <Checkbox
                  name="messagedJoshua"
                  checked={messagedJoshua}
                  onChange={this.handleCheckBoxChange('messagedJoshua')}
                />
                <label>I messaged Joshua on <a href="//m.me/Joshua.Vantard" target="_blank">Messenger</a> to fast-track my application</label>
              </Form.Field> 

              <Form.Field className="checkbox-with-link">
                <Checkbox
                  name="fetchDataFromLinkedIn"
                  checked={fetchDataFromLinkedIn}
                  onChange={this.handleCheckBoxChange('fetchDataFromLinkedIn')}
                  />
                <label>Save me time signing up and fill out my profile using my Linkedin</label>
              </Form.Field>

              <Button type="submit" primary>Save & Continue</Button>
            </Form>
          </Segment >
       </Container >
      </div >
    );
  }
}


export default withFormik({
  mapPropsToValues: () => {
    return {
      readMediumArticle: false,
      requestToJoinFacebookGroup: false,
      linkedin: "",
      facebook: "",
      otherLinks: "",
      referredBy: "",
      messagedJoshua: false,
      fetchDataFromLinkedIn: false,
    }
  },
  validate: async (values) => {
    const error = await applyPage1Schema.validate(values).then(() => false).catch(err => {
      hermes.error(err.errors[0]);
      return err
    })
    if (error) {
      throw error
    }

    return {}
  },

  handleSubmit: (values, bag) => {
    console.log('helloooooo')
    bag.props.handleSubmit({
      ...values,
      facebook: `https://facebook.com/${values.facebook.trim()}`,
      linkedin: `https://linkedin.com/in/${values.linkedin.trim()}`
    });
  },

  displayName: 'ApplyPage1',
  validateOnBlur: false,
  validateOnChange: false,
})(ApplyPage1);;