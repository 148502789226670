import React, { Component, Fragment } from 'react';
import NewExperienceEntry from './NewExperienceEntry';
import { Button, Card, Image, Dropdown, Input, TextArea, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { months } from '../../constants';
import { DEFAULT_IMAGES } from '../../constants'
import LinkWithLoader from '../LinkWithLoader';


const DatesComponent = ({ startMonth, startYear, endMonth, endYear, currentlyWorkHere }) => {
  console.log('startmonth', startMonth)

  let end = (
    <Fragment>
      <span className="date-divider">-</span>
      <span className="end">
        {currentlyWorkHere ? 'Present' : endMonth ? endMonth + ' ' + endYear : endYear}
      </span>
    </Fragment>
  );

  return (
    <div className="dates">
      <span className="start">
        {startMonth ? startMonth + ' ' : ''}{startYear}
      </span>
      {endMonth == startMonth && endYear == startYear ? null : end}
    </div>
  )

};

const ExperienceView = ({ otherUser, details, removeExperience, toggleEdit }) => {
  if (!details) return null;

  let startMonth, startYear, endMonth, endYear;

  let currentlyWorkHere = details.dates ? details.dates.currentlyWorkHere : false;


  if (details.dates) {
    // startMonth = new Date(details.from).toGMTString().split(' ')[2];
    if (details.dates.start) {
      console.log(details.dates.start)
      startMonth = typeof details.dates.start.month != 'undefined' ? months[details.dates.start.month] : null;
      startYear = details.dates.start.year;
    }

    if (!currentlyWorkHere) {
      // endMonth = new Date(details.to).toGMTString().split(' ')[2];
      endMonth = details.dates.end ? months[details.dates.end.month] : null;
      endYear = details.dates.end ? details.dates.end.year : null;
    }
  }
  return (
    <Card>
      <div className="experience-wrapper">

        <div className="header">
          <Image
            width="80"
            height="80"
            src={DEFAULT_IMAGES.Company}
          />
          <div className="wrapper">
            <div className="details">
              <div className="name">
                <LinkWithLoader to={`/company/${details.company.slug}`}>
                  {details.company ? details.company.name : 'Company Has Been Deleted'}
                </LinkWithLoader>
              </div>
              <div className="info">
                <div className="title">{details.title ? details.title : null}</div>
                {DatesComponent({ startMonth, startYear, endMonth, endYear, currentlyWorkHere })}
              </div>
              <div className="description">{details.description}</div>
            </div>
            {otherUser ? null : (
              <div className="actions">
                <Button className="first" basic onClick={toggleEdit}>
                  Edit
              </Button>
                <Button
                  basic
                  onClick={() => {
                    removeExperience(details._id);
                  }}
                >
                  Remove
              </Button>
              </div>
            )}
          </div>
        </div>

      </div>
    </Card>
  );
};

class Experience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };

    this.toggleEdit = this.toggleEdit.bind(this);
    this.removeExperience = this.removeExperience.bind(this);
  }

  toggleEdit() {
    this.setState({ editing: !this.state.editing });
  }

  removeExperience(id) {
    const { profileStore } = this.props;
    profileStore.deleteExperience(id);
  }

  render() {
    const { editing } = this.state;
    const { details, profileStore } = this.props;


    if (editing) {
      // we pass existing data to the editing component
      return <NewExperienceEntry isSaving={this.props.inProgress} previousData={details} toggleState={this.toggleEdit} />;
    } else {
      // console.log(this.props, 'pross');
      // just render
      return <ExperienceView {...this.props} toggleEdit={this.toggleEdit} removeExperience={this.removeExperience} />;
    }
  }
}

export default inject('profileStore')(observer(Experience));
