import React, { Fragment, Component } from 'react';
import { Card, Header, Button, Image, Grid } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import '../../css/applications.css'
import api from '../../api'

class Applicant extends Component {
    constructor(props){
        super(props)
        this.state = {
            approved: false
        }
    }

    approveApplicant = () => {
        const { loadingStore } = this.props;
        this.props.loadingStore.setLoading()
        let result  = api.applications.acceptApplication(this.props._id)
        this.setState({
            approved: true
        })
        loadingStore.setLoaded();
    }
    render() {
        return (
            <Card>
                <Grid>
                    <Grid.Column width={3}>
                        <Image
                            width="80"
                            height="80"
                            circular
                            src={
                            this.props.image ||
                            'https://t4.ftcdn.net/jpg/02/15/84/43/240_F_215844325_ttX9YiIIyeaR7Ne6EaLLjMAmy4GvPC69.jpg'
                            }
                            />
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <Header>
                            {this.props.name}
                        </Header>
                        <p>{this.props.email}</p>
                        <p>Submitted at {new Date(this.props.createdAt).toString()}</p>
                        <div className="social">
                            {[
                            {
                                name: 'facebook',
                                url: 'https://facebook.com/',
                            },
                            {
                                name: 'linkedin',
                                url: 'https://linkedin.com/in/',
                            },
                            ].map(({ name, url }, key) => {
                            if (this.props.bio && this.props.bio[name]) {
                                return (
                                    <a className="applicant-social" href={this.props.bio[name].startsWith('http') ? this.props.bio[name] : `http://${this.props.bio[name]}`} target="_blank">
                                    {name}    
                                    </a>
                                );
                            } else {
                                return null;
                            }
                            })}
                        </div>
                        {
                            this.props.referredBy ? <p>Referred by: {this.props.referredBy}</p> :
                            ""
                        }
                        {this.props.otherLinks ? <p><pre>Any other links of note: {this.props.otherLinks}</pre></p> : null}
                        
                        <p>Fetch data from LinkedIn: {String(this.props.fetchDataFromLinkedIn)}</p>
                        
                        {this.props.phoneNumber ? <p>Phone number: {this.props.phoneNumber}</p> : null}
                        
                        {this.props.isApplying?
                        <Button onClick={this.approveApplicant} secondary>
                            {this.state.approved? "Approved" : "Approve"}
                        </Button> : ""}

                        
                    </Grid.Column>
                </Grid>
            </Card>
        );
    }
}

export default inject('loadingStore')(observer(Applicant))