import config from './config';
import requests from './http';
import { request } from 'https';
const parseResponse = res => res.data;

const endpoint = `${config.getApiEndpoint()}/faq`;

export default {
  async getFAQ(data) {
    const res = await requests.get(endpoint);
    return parseResponse(res);
  },
};
