import React from 'react';
import { Card, Image, Button } from 'semantic-ui-react';
import LinkWithLoader from '../LinkWithLoader';
const Person = ({ _id, name, location, bio, image, addNewFavourite, slug }) => (
  <Card>
    <div className="reference-item">
      <div className="user">
        <Image circular width="60" height="60" src={image || 'https://avatars2.githubusercontent.com/u/44?v=4'} />
        <div className="user-details">
          <div className="name">
            <LinkWithLoader to={`/${slug}`}>{name}</LinkWithLoader>
          </div>
          <div className="location">
            <LinkWithLoader to={`/location/${location && location.currentLocation ? location.currentLocation.slug : ''}`}>
              {location && location.currentLocation ? location.currentLocation.name : ''}
            </LinkWithLoader>
          </div>
          <div className="headline">{bio ? bio.headline : null}</div>
        </div>
      </div>

      <div className="save">
        <Button
          onClick={() => {
            addNewFavourite(_id);
          }}
          basic
        >
          Save
        </Button>
      </div>
    </div>
  </Card>
);

const SkillTable = ({ users, addNewFavourite }) => {
  return (
    <div className="references skills">
      <header className={users.length == 0 ? 'hidden' : 'tablet-and-up'}>
        <div className="user">Person</div>
      </header>

      {users.map((user, index) => (
        <Person key={index} {...user} addNewFavourite={addNewFavourite} />
      ))}

    </div>
  );
};

export default SkillTable;
