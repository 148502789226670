import { action, observable, decorate } from 'mobx';
import React from 'react';
import { storage } from '../utilities';

class NotificationStore {
  constructor() {}
  position = null;
  transition = null;
  load() {}

  success() {}
}

decorate(NotificationStore, {});

export default new NotificationStore();
export const placementTypes = {
  bl: 'bottom-left',
  bc: 'bottom-center',
  br: 'bottom-right',
  tl: 'top-left',
  tc: 'top-center',
  tr: 'top-right',
};

export const transitionType = {
  entering: 'entering',
  entered: 'entered',
  exiting: 'exiting',
  exited: 'exited',
};
