import profileStore from '../stores/profileStore';
import usertimelineStore from '../stores/usertimelineStore';
import favouriteStore from '../stores/favouriteStore';
import referenceStore from '../stores/referenceStore';
import searchStore from '../stores/searchStore';
import { mapReadableTitleToIndex } from '../constants';
import { userIndex } from '../api/algolia';
import authStore from '../stores/authStore';
import faqStore from '../stores/faqStore';
import subscriptionStore from '../stores/subscriptionStore';

/**
 * Load necessary data for feed
 */
 export async function LoadFeed() {
  if (authStore.loggedIn) await usertimelineStore.retrieveFeed('all');
}


/**
 * Load necessary data for favourites
 */
 export async function LoadFavourites() {
  await favouriteStore.getFavourites();
}


/**
 * Load necessary data for FAQ
 */
 export async function LoadFAQ() {
   await faqStore.getQuestions();
}


/**
 * Load necessary data for profile
 */
 export async function LoadProfile({ pathname }) {
  await profileStore.getProfile(pathname.slice(1));
}


/**
 * Load necessary data for Reference pages
 */
 export async function LoadReference({ pathname }) {
    const id = pathname.split('/')[2];
    const path = pathname.split('/')[1];
    
    await referenceStore.getReferenceData(id, path);

}
/**
 * Load necessary data for search
 */
export async function LoadSearch(location, history) {
    let splitPath = location.pathname.split('/')
    let view = splitPath[2];
    let search = ''

    if ((splitPath.length == 3 || (splitPath.length == 4 && splitPath[3] === '')) && mapReadableTitleToIndex[view]) {
      searchStore.activeView = view
    } else {
      searchStore.activeView = 'all'
    }

    let pathname = '/search/' + searchStore.activeView;

    if (location.query['q']) {
      searchStore.updateSearchValue(location.query['q'], history);
      search = '?q=' + encodeURIComponent(searchStore.searchValue);
    }

    if (searchStore.activeView == 'people' && location.query['filters']) {
      try {
        let filters = JSON.parse(location.query['filters']);
        let clearedFilters = {};
        for (let key of Object.keys(filters)) {
          if (searchStore.selectedFilters[key] && filters[key] && filters[key].length) {
            searchStore.selectedFilters[key] = [...filters[key]];
            searchStore.selectedValues[key] = [...filters[key]];
            clearedFilters[key] = [...filters[key]];
          }
        }

        searchStore.qfilters = encodeURIComponent(JSON.stringify(clearedFilters))

        if (Object.keys(clearedFilters).length) {
          search = (search ? search + '&filters=' : '?filters=') + encodeURIComponent(JSON.stringify(clearedFilters))
        }
      } catch (e) {
        return
      }
    }

    await searchStore.search();

    try {
      const results = await userIndex.searchForFacetValues({ facetName: 'profile.education.degreeType', facetQuery: '*' });
      if (results.facetHits.length) {
        results.facetHits.forEach(el => {
          searchStore.degreeTypeFilters = searchStore.degreeTypeFilters.map(el2 => {
            if (el.value == el2.title) {
              el2.usercount = el.count
            }
            return el2;
          })
        })
      }
    } catch (e) {
      console.error(e);
    };

    // history.push(pathname + search)
}


/**
 * Load necessary data for verifying email
 */
export async function LoadVerifyEmail() {
  await LoadFeed();
}


/**
 * Load necessary data for reset password
 */
export async function LoadResetPassword() {}


/**
 * Load necessary data for sent meetups
 */
export async function LoadSentMeetups() {}


/**
 * Load necessary data for reported bugs
 */
export async function LoadReportedBugs() {}



/**
 * Load necessary data for loading onboard
 */
export async function LoadOnboarding() {}


/**
 * Load necessary data for loading report bug
 */
export async function LoadReportBug() {}


/**
 * Load necessary data for loading host meetup
 */
export async function LoadHostMeetup() {}


/**
 * Load necessary data for edit profile
 */
export async function LoadEditProfile() {
  await profileStore.getSignals();
}


/**
 * Load necessary data for privacy
 */
export async function LoadPrivacy() {}

/**
 * load necessary data for pitch page
 * 
 * 
 */
export async function LoadPitch() {}


/**
 * Load necessary data for settings
 */
export async function LoadSettings() {}


/**
 * Load ncessary data for subscribe page
 */
export async function LoadSubscribePage() {
  await subscriptionStore.getSubscriptions();
}

/**
 * Load necessary data for broadcast signal page
 */
export async function LoadBroadcastSignal() {
  await profileStore.getSignals();
}