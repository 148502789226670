import * as yup from 'yup';
import { errors } from '../messages';

const objectIdRegex = /^[a-fA-F0-9]{24}$/;
const objectIdSchema = yup.string().matches(objectIdRegex);
const schema = yup.object({
  facebook: yup.string().trim().matches(/^(?:https?:\/\/)?(?:(?:www|web|m)\.)?(?:facebook\.com\/(?:profile\.php\?id=)?|fb\.me\/)([A-Z0-9.]{5,})\/?$/i, errors.INVALID_FACEBOOK_URL),
  headline: yup.string().required(errors.MISSING_HEADLINE),
  selectedSkills: yup
    .array(
      yup.object({
        _id: objectIdSchema,
      }),
    )
    .min(3, errors.MISSING_SKILLS)
    .required(errors.MISSING_SKILLS),
});

export default schema;
