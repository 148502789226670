import React, { Component } from 'react';
import LocationWithBubble from '../../components/Forms/LocationWithBubble';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Button, Card, Form, Field } from 'semantic-ui-react';
import * as constants from '../../constants';
import { hermes } from '../../utilities';
import { withFormik, ErrorMessage } from 'formik';
import page2Schema from '../../schemas/page2Schema';
import { goTo, loadCurrentRoute } from '../../routing';
import { errors } from '../../messages';
import authStore from '../../stores/authStore';

export class Page2 extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.state = { currentLocation: '', previousLocation: '' };
  }

  handleChange(option) {
      return value => {
        // this.setState({ [option]: value });
        this.props.setFieldValue(option, value);
      };
  }

  handleSelect(option) {
    const { onboardingStore, loadingStore } = this.props;
    return async value => {
      try {
        const stringPath = `locObj.${option}`;
        this.props.setFieldValue(option, value);
        loadingStore.setLoading();
        const data = await onboardingStore.lookupLocation(value);
        this.props.setFieldValue(stringPath, data);
        this.props.setFieldValue(option, value);
        loadingStore.setLoaded();
      } catch (error) {
        loadingStore.setLoading();
        hermes.error(error);
      }
    };
  }

  handleRemove = (option) => () => {
    const stringPath = `locObj.${option}`;
    this.props.setFieldValue(option, '');
    this.props.setFieldValue(stringPath, null);
  }


  async saveAndContinue() {
    // const { onboardingStore, history } = this.props;
    // console.log(onboardingStore.currentLocation, onboardingStore.previousLocation);
    // try {
    //   const response = await onboardingStore.saveLocation();
    //   onboardingStore.syncOnboardingPage(4);
    //   history.push('/onboarding/4');
    // } catch (error) {
    //   hermes.error(error);
    // }
  }

  render() {
    const { values, handleChange, handleBlur, handleSubmit, onboardingStore,handleReset } = this.props;
    const { currentLocation, previousLocation, locObj } = values;
    return (
      <div className="onboarding-5-container" data-testid="onboarding-div">
        {/* <h2 className="text-center">Welcome to IntCrzyPpl, {authStore.currentUser.name || 'User'}!</h2> */}
        <div className="onboarding-heading">Create your profile</div>
        <div className="onboarding-sub-heading ">Let’s help you connect with members around the world</div>

        <Form onSubmit={handleSubmit} className="onboarding-column" autoComplete="off" data-testid='form'>
          <div>
            <Card className="onboarding-form-wrapper">
              <Form.Field required>
                <label>Where you're a resident
                <LocationWithBubble
                  name={constants.currentLocation}
                  placeholder="Ex: Palo Alto"
                  value={currentLocation}
                  selectedEntity={locObj.currentLocation && locObj.currentLocation.name}
                  handleRemove={this.handleRemove(constants.currentLocation)}
                  handleSelect={this.handleSelect(constants.currentLocation)}
                  handleChange={this.handleChange(constants.currentLocation)}
                  name="currentLocation"
                />
                </label>
              </Form.Field>

              <Form.Field>
                <label>Where you were born or grew up</label>
                <LocationWithBubble
                  name={constants.previousLocation}
                  placeholder="Ex: New York"
                  value={previousLocation}
                  selectedEntity={locObj.previousLocation && locObj.previousLocation.name}
                  handleRemove={this.handleRemove(constants.previousLocation)}
                  handleSelect={this.handleSelect(constants.previousLocation)}
                  handleChange={this.handleChange(constants.previousLocation)}
                  name="previousLocation"
                />
              </Form.Field>
            </Card>
            <div className="button-wrapper">
              <Button type="submit"
                  primary
              >
                Save & Continue
                </Button>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export const EnhancedForm = withFormik({
  mapPropsToValues: props => {
    return {
      currentLocation: '',
      previousLocation: '',
      locObj: {
        currentLocation: { name: '' },
        previousLocation: { name: '' },
      },
    };
  },

  validate: async (values) => {

    const error = await page2Schema.validate(values).then(() => false).catch(err => {
      hermes.error(err.errors[0]);
      return err
    })
    if (error) {
      throw error
    }

    const { locObj } = values;

    if (!locObj.currentLocation || !locObj.currentLocation._id) {
      hermes.error(errors.LOCATION_NOT_SELECTED);
      throw errors.LOCATION_NOT_SELECTED
    }

    return {}
  },

  handleSubmit: async (values, bag) => {
    const { onboardingStore, history, profileStore, usertimelineStore, authStore } = bag.props;
    try {

      const { locObj } = values;

      const location = {
        currentLocation: locObj.currentLocation && locObj.currentLocation._id,
        previousLocation: locObj.previousLocation && locObj.previousLocation._id
      }

      await onboardingStore.saveLocation(location);

      //Check if its an applicant, if the scrape data from linked in is turned on, send them to sms confirmation
      let claimingProfile = profileStore.userData.bio ? profileStore.userData.bio.headline ? true : false : false
      if (profileStore.userData.fetchDataFromLinkedIn || claimingProfile) {
        onboardingStore.updatePage("6");
        goTo('/onboarding/6', history);

      } else {
        onboardingStore.syncOnboardingPage(5);
        onboardingStore.updatePage("5");
        goTo('/onboarding/5', history);
      }
    } catch (error) {
      hermes.error(error);
    }
  },

  validateOnBlur: false,
  validateOnChange: false,

  displayName: 'Page2',
})(observer(Page2));

export default withRouter(inject('authStore', 'onboardingStore', 'loadingStore', 'profileStore', 'usertimelineStore')(observer(EnhancedForm)));
