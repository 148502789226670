import React, { Component, Fragment } from 'react';
import { PersonalInfo, Skills, EducationList, ExperienceList } from '../../components/Profile';
import { Container } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import '../../css/profile.css';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addEducation: false,
      editEducation: false,
      addExperience: false,
      editExperience: false,
    };
    this.removeExperience = this.removeExperience.bind(this);
    this.removeEducation = this.removeEducation.bind(this);
  }

  addEducation = () => {
    this.setState({ addEducation: !this.state.addEducation });
  };

  editEducation = () => {
    this.setState({ editEducation: !this.state.editEducation });
  };

  addExperience = () => {
    this.setState({ addExperience: !this.state.addExperience });
  };

  editExperience = () => {
    this.setState({ editExperience: !this.state.editExperience });
  };

  removeEducation(id) {
    const { profileStore } = this.props;
    profileStore.deleteEducation(id);
  }

  removeExperience(id) {
    const { profileStore } = this.props;
    profileStore.deleteExperience(id);
  }

  render() {
    const { profileStore, otherUser } = this.props;
    const { profileData } = profileStore;
    // console.log(profileData, 'profff');
    if (otherUser) {
      return (
        <div className="profile">
          <PersonalInfo userData={profileData} otherUser={this.props.otherUser} />
          {profileData.bio && profileData.bio.skills && profileData.bio.skills.length ? <Skills 
            otherUser={otherUser} 
            userData={profileData} 
          /> : null}
          {profileData.profile.education && profileData.profile.education.length ? <EducationList
            otherUser={otherUser}
            userData={profileData}
            removeEducation={this.removeEducation}
            editEducation={this.editEducation}
            addEducation={this.addEducation}
            editing={this.state.editEducation}
            adding={this.state.addEducation}
          /> : null}
          {profileData.profile.experiences && profileData.profile.experiences.length ? <ExperienceList
            userData={profileData}
            otherUser={otherUser}
            removeExperience={this.removeExperience}
            editExperience={this.editExperience}
            addExperience={this.addExperience}
            editing={this.state.editExperience}
            adding={this.state.addExperience}
          /> : null}
        </div>
      );
    } else if (profileData) {
      return (
        <div className="profile">
          <PersonalInfo otherUser={otherUser} />
          <Skills otherUser={otherUser} />
          <EducationList
            otherUser={otherUser}
            removeEducation={this.removeEducation}
            editEducation={this.editEducation}
            addEducation={this.addEducation}
            editing={this.state.editEducation}
            adding={this.state.addEducation}
          />
          <ExperienceList
            otherUser={otherUser}
            removeExperience={this.removeExperience}
            editExperience={this.editExperience}
            addExperience={this.addExperience}
            editing={this.state.editExperience}
            adding={this.state.addExperience}
          />
        </div>
      );
    }
  }
}
export default inject('profileStore')(observer(UserProfile));
