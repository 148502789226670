import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import meetup from '../../api/meetup';

const MeetupChat = observer(props => {
  const { continent, cities } = props;
  return (
      <div className="meetup-chat">
        <div className="country">{continent}</div>
        <div className="city">{cities.sort((a,b) => {
          if (a[0] > b[0]) {
            return 1;
          } else if(a[0] < b[0]) {
            return -1
          }
          return 0;
        }
        ).map((item, i) => <Fragment key={i}><a href={item[1]} target="_blank">{item[0]}</a>{i != cities.length -1 ? ', ': ''}</Fragment> )}</div>
      </div>
  );
});

export default MeetupChat;
