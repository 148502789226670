import * as yup from 'yup';
import { errors } from '../messages';

const schema = yup.object({
  email: yup
    .string()
    .email()
    .required(),
  password: yup
    .string()
    .required(errors.PASSWORD_TOO_SHORT)
    .min(8),
});

export default schema;
