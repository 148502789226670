import { Button } from 'semantic-ui-react';
import React, { Fragment, Component } from 'react';
import { inject, observer } from 'mobx-react';
import NewExperienceEntry from './NewExperienceEntry';
import Experience from './Experience';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';

import '../../css/profile.css';
import { hermes } from '../../utilities';
import { success } from '../../messages';


const sortExperiences = (a, b) => {
  if (a.dates.currentlyWorkHere && b.dates.currentlyWorkHere) {
    return new Date(b.dates.start.year, b.dates.start.month || 0) - new Date(a.dates.start.year, a.dates.start.month || 0)
  }
  if (a.dates.currentlyWorkHere) {
    return -1
  }
  if (b.dates.currentlyWorkHere) {
    return 1
  }
  if (!a.dates.end) {
    return +1
  }
  if (!b.dates.end) {
    return -1
  }
  return new Date(b.dates.end.year, b.dates.end.month || 0) - new Date(a.dates.end.year, a.dates.end.month || 0);
}


class ExperienceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addExperience: false,
      reordering: false,
      experiences: null
    }
  }

  addExperience = () => {
    this.setState({
      addExperience: true,
    });
  };
  cancelAddExperience = () => {
    this.setState({
      addExperience: false,
    });
  };

  startReordering = () => {
    const { profileStore } = this.props;
    const { profile } = profileStore.userData;

    const experiences = profile ? 
                          profile.reorderedExperiences ? 
                            profile.experiences
                            :
                            Array.from(profile.experiences).sort(sortExperiences)
                          : 
                          null

    this.setState({ experiences, reordering: true })
  }

  saveNewOrder = async () => {
    const { profileStore } = this.props;
    try {
      await profileStore.reorderExperiences(this.state.experiences)
      this.setState({ reordering: false, experiences: null })
      hermes.success(success.REORDERED_EXPERIENCES)
    } catch (e) {
      hermes.error(e);
    }
  }

  cancelReordering = () => {
    this.setState({ reordering: false, experiences: null })
  }

  onDragEnd = ({ source, destination }) => {
    if (destination === null) {
      return
    }

    if (source.index === destination.index) {
      return 
    }
    const experiences = Array.from(this.state.experiences)
    const movedExperience = experiences[source.index]
    experiences.splice(source.index, 1)
    experiences.splice(destination.index, 0, movedExperience)
    console.log(experiences)
    this.setState({ experiences })
  }

  render() {
    const { otherUser, profileStore } = this.props;
    const { addExperience, reordering } = this.state;
    const { inProgress } = profileStore;

    let userData = null;

    if (!otherUser) {
      userData = profileStore.userData;
    } else {
      userData = this.props.userData;
    }

    let experienceStats;
    if (!userData) return null;

    const experiences = reordering ? this.state.experiences : userData.profile && userData.profile.experiences

    if (experiences) {
      experienceStats = experiences
        .map((experience, index) => {
          if (!experience._id) {
            return { ...experience, _id: index }
          }
          return experience
        })
        if (!userData.profile.reorderedExperiences && !reordering) {
          experienceStats.sort(sortExperiences)
        }
        experienceStats = experienceStats.map((experience) => ({
          experience, component: <Experience otherUser={otherUser} details={experience} inProgress={inProgress} />
        }))
    } else {
      experienceStats = null;
    }

    return (
      <Fragment>
        <div className="section">
          <div className="heading">
            <div className="title">Experience</div>
            {otherUser ? (
              ''
            ) : (
                <Fragment>
                  {addExperience ? (
                    <Button onClick={this.cancelAddExperience} primary>
                      Cancel
                    </Button>
                  ) : (
                    <Button onClick={this.addExperience} primary>
                      Add
                    </Button>
                  )}
                  {reordering ? (
                    <Fragment>
                      <Button onClick={this.saveNewOrder} primary>
                        Save
                      </Button>
                      <Button onClick={this.cancelReordering} primary>
                        Cancel
                      </Button>
                    </Fragment>
                  ) : (
                    <Button onClick={this.startReordering} primary>
                      Reorder
                    </Button>
                  )}
                </Fragment>
              )}
          </div>
          <div className="section-padding">
            {addExperience ? (
              <NewExperienceEntry
                toggleState={() => {
                  this.setState({ addExperience: false });
                }}
              />
            ) : null}
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="experiences">
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {experienceStats.map((experience, key) => (
                      <Draggable isDragDisabled={!reordering} draggableId={experience.experience.company + experience.experience.title} index={key} key={key}>
                        {provided => (
                          <div className="draggable-card"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {experience.component}
                            
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}                    
                  </div>      
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default inject('profileStore', 'onboardingStore')(observer(ExperienceList));
