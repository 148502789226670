import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { Image, Button, Input, Form, Segment, Header } from 'semantic-ui-react';
import { createBlob, hermes, fbToMsg, expired } from '../../utilities';
import InputWithCounter from '../Forms/InputWithCounter';
import TextAreaWithCounter from '../Forms/TextAreaWithCounter';
import LocationWithBubble from '../../components/Forms/LocationWithBubble';
import editProfileSchema from '../../schemas/editProfileSchema';
import { withFormik } from 'formik';
import { goTo } from '../../routing';
import { observer, inject } from 'mobx-react';

const socialMediaItems = [
  { key: 1, value: 'facebook', text: 'Facebook link', url: 'facebook.com/' },
  { key: 2, value: 'fbMessenger', text: 'Facebook Messenger link', url: 'm.me/' },
  { key: 3, value: 'dribbble', text: 'Dribbble', url: 'dribbble.com/' },
  { key: 4, value: 'twitter', text: 'Twitter', url: 'twitter.com/' },
  { key: 5, value: 'linkedin', text: 'LinkedIn', url: 'linkedin.com/in/' },
  { key: 6, value: 'github', text: 'Github', url: 'github.com/' },
  { key: 7, value: 'instagram', text: 'Instagram', url: 'instagram.com/' },
];

const DecoratedInput = ({ component: Component, error, ...rest }) => {
  return (
    <Fragment>
      <Component {...rest} />

      {
        // <span className="check">
        //   <Icon name={error ? 'close' : 'check'} />
        // </span>
      }

    </Fragment>
  );
};

class MyForm extends Component {
  constructor(props) {
    super(props);
    this.state = { uploadingImage: false };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.goToProfile = this.goToProfile.bind(this);
    this.handleFBChange = this.handleFBChange.bind(this);
  }

  goToProfile() {
    const { history, slug } = this.props;
    goTo(`/${slug}`, history);
  }

  handleSelect(option) {
    return async value => {
      let response;
      if (value) {
        response = await this.props.handleLocationSelect(option)(value);
        if (response) {
          this.props.setFieldValue('selectedLocation.' + option.split('.')[1], response.name);
        }
      }
      this.props.handleChange(
        {
          target: {
            type: 'text',
            name: option,
            id: option,
            value: response ? response.name : value,
          },
        },
        { value: response ? response.name : value },
      );
    };
  }

  handleRemove = (option) => async () => {
    this.props.setFieldValue('selectedLocation.' + option.split('.')[1], '');
    // console.log('selectedLocation.' + option.split('.')[1])

    this.props.handleLocationSelect(option)(null);

    this.props.handleChange(
      {
        target: {
          type: 'text',
          name: option,
          id: option,
          value: '',
        },
      },
      { value: '' },
    );
  }

  handleChange(option) {
    return value => {
      this.props.handleChange(
        {
          target: {
            type: 'text',
            name: option,
            id: option,
            value: value,
          },
        },
        { value },
      );
    };
  }

  async handleImageChange(e) {
    await e.persist();
    this.props.handleImageChange(e);
    const filename = this.props.selectedFile;
    this.props.setFieldValue('image', filename);
  }

  async uploadImage() {
    try {
      this.setState({ uploadingImage: true });
    } catch (e) { }
    this.setState({ uploadingImage: false });
  }

  handleFBChange(e, data) {
    const { handleChange, setFieldValue } = this.props;
    handleChange(e, data);
    setFieldValue('bio.fbMessenger', fbToMsg(data.value));
  }

  render() {
    let {
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      savingProfileData,
      uploadingImage,
      selectedImage,
      lookingUp,
      profileStore
    } = this.props;
        // const { } = this.state;
    let { bio, location, image, name, selectedLocation } = values;
    image = selectedImage ? createBlob(selectedImage) : image;

    return (
      <Fragment>
        <Header inverted>
          Edit Profile
      </Header>
        <Segment>

          <Form onSubmit={handleSubmit} autoComplete="off">
            <Header>
              <Image src={image || 'https://t4.ftcdn.net/jpg/02/15/84/43/240_F_215844325_ttX9YiIIyeaR7Ne6EaLLjMAmy4GvPC69.jpg'} />
              <Header.Content>
                {name}
                <Header.Subheader>
                  <a role="button" onClick={() => { document.querySelector('.sub.header input').click() }}>
                    Change profile photo
                  </a>
                  <Input
                    type="file"
                    name="image"
                    placeholder=" Change Profile Picture"
                    onChange={this.handleImageChange}
                    onBlur={handleBlur}
                    accept="image/*"
                  />
                </Header.Subheader>
              </Header.Content>
            </Header>

            {/* <div className="email">{email}</div> */}



            <DecoratedInput
              name="location.previousLocation"
              placeholder="Enter your previous location"

              component={Form.Field}
              content={
                <Fragment>
                  <label>Where you were born or grew up</label>
                  <LocationWithBubble
                    name="previousLocation"
                    value={location.previousLocation}
                    selectedEntity={selectedLocation.previousLocation}
                    placeholder="Ex: New York"
                    handleSelect={this.handleSelect('location.previousLocation')}
                    handleChange={this.handleChange('location.previousLocation')}
                    handleRemove={this.handleRemove('location.previousLocation')}
                  />
                </Fragment>
              }
              error={errors.location && errors.location.previousLocation}
            />
            {/* <ErrorMessage name="location.previousLocation" /> */}

            <DecoratedInput
              name="location.currentLocation"
              placeholder="Enter your current location"
              component={Form.Field}
              content={
                <Fragment>
                  <label>Where you're a resident</label>
                  <LocationWithBubble
                    name="currentLocation"
                    value={location.currentLocation}
                    placeholder="Ex: Palo Alto"
                    selectedEntity={selectedLocation.currentLocation}
                    handleSelect={this.handleSelect('location.currentLocation')}
                    handleChange={this.handleChange('location.currentLocation')}
                    handleRemove={this.handleRemove('location.currentLocation')}
                  />
                </Fragment>
              }
              error={errors.location && errors.location.currentLocation}
            />
            {/* <ErrorMessage name="location.currentLocation" /> */}


            <DecoratedInput
              name="location.visitingLocation"
              placeholder="Enter your visiting location"
              component={Form.Field}
              content={
                <Fragment>
                  <label>Where you're visiting</label>
                  <LocationWithBubble
                    name="visitingLocation"
                    value={location.visitingLocation}
                    selectedEntity={selectedLocation.visitingLocation}
                    placeholder="Ex: Brussels"
                    handleSelect={this.handleSelect('location.visitingLocation')}
                    handleChange={this.handleChange('location.visitingLocation')}
                    handleRemove={this.handleRemove('location.visitingLocation')}
                  />
                </Fragment>
              }
              error={errors.location && errors.location.visitingLocation}
            />
            {/* <ErrorMessage name="location.visitingLocation" /> */}
            <Form.Field>
              <label>Number of days visiting</label>
              <InputWithCounter
                name="location.daysToExpire"
                value={location.daysToExpire}
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                maxCount={80}
              />
            </Form.Field>

            <Form.Field>
              <label>Website</label>
              <InputWithCounter
                name="bio.website"
                value={bio.website}
                placeholder="Ex: aaroneisenberg.com"
                onChange={handleChange}
                onBlur={handleBlur}
                maxCount={80}
              />
            </Form.Field>

            <Form.Field>
              <label>Headline</label>
              <InputWithCounter
                name="bio.headline"
                placeholder="Ex: Software Engineer at Facebook"
                value={bio.headline}
                onChange={handleChange}
                onBlur={handleBlur}
                maxCount={80}
              />
            </Form.Field>

            {
              //
              // <Form.Field>
              //   <label>miniResume</label>
              //
              //   <TextAreaWithCounter
              //     name="bio.miniResume"
              //     value={bio.miniResume}
              //     placeholder="Enter a description about yourself (mini resume)"
              //     onChange={handleChange}
              //     onBlur={handleBlur}
              //     maxCount={120}
              //   />
              // </Form.Field>

            }

            {socialMediaItems.map((item, i) => {
              return (
                <Form.Field key={i}>
                  <label>{item.text}</label>
                  <Input
                    name={`bio.${item.value}`}
                    id={item.value}
                    placeholder={`${item.url}username`}
                    value={bio[item.value]}
                    onChange={item.value == 'facebook' ? this.handleFBChange : handleChange}
                  />
                </Form.Field>
              );
            })}
            <Button
              disabled={savingProfileData}
              primary
            > Save
            </Button>
            <Button type="button" basic onClick={this.goToProfile}>Cancel</Button>
          </Form>
        </Segment >
      </Fragment>
    );
  }
}

function createNeatObject({ name, image, bio, location, email, daysToExpire }) {
  console.log(location)
  return {
    name: name || '',
    email: email || '',
    bio: {
      website: (bio && bio.website) || '',
      twitter: (bio && bio.twitter) || '',
      facebook: (bio && bio.facebook) || '',
      fbMessenger: (bio && bio.fbMessenger) || '',
      linkedin: (bio && bio.linkedin) || '',
      dribbble: (bio && bio.dribbble) || '',
      headline: (bio && bio.headline) || '',
      miniResume: (bio && bio.miniResume) || '',
      website: (bio && bio.website) || '',
      instagram: (bio && bio.instagram) || '',
      github: (bio && bio.github) || '',
    },
    image: image,
    location: {
      currentLocation: location && location.currentLocation ? location.currentLocation.name : '',
      previousLocation: location && location.previousLocation ? location.previousLocation.name : '',
      visitingLocation: location && location.visitingLocation && !expired(location.visitingLocationExpireAt) ? location.visitingLocation.name : '',
      daysToExpire: location && location.visitingLocation && !expired(location.visitingLocationExpireAt) ? Math.ceil((new Date(location.visitingLocationExpireAt) - new Date()) / (1000 * 60 * 60 * 24)) : ''
    },
    selectedLocation: {
      currentLocation: location && location.currentLocation ? location.currentLocation.name : '',
      previousLocation: location && location.previousLocation ? location.previousLocation.name : '',
      visitingLocation: location && location.visitingLocation && !expired(location.visitingLocationExpireAt) ? location.visitingLocation.name : '', 
    }
  };
}

const MyEnhancedForm = withFormik({
  mapPropsToValues: props => {
    return createNeatObject({ ...props, location: props.userLocation });
  },
  validate: async (values) => {
    const error = await editProfileSchema.validate(values).then(() => false).catch(err => {
      hermes.error(err.errors[0]);
      return err
    })
    if (error) {
      throw error
    }

    return {}
  },

  handleSubmit: (values, bag) => {
    bag.props.submitData(values);
  },

  displayName: 'BasicForm',
  validateOnBlur: false,
  validateOnChange: false,
})(MyForm);

export default withRouter(inject('profileStore')(observer(MyEnhancedForm)));
