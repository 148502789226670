import React, { Component, Fragment } from 'react';
import { Search, Button, Label } from 'semantic-ui-react';
import _ from 'lodash';

const SelectedEntity = ({ name, remove, labelName }) => {
  return (
    <Label className="bubbleInput entity-label" basic>
      {labelName ? labelName + ": " : null}{name}
      <div
        onClick={remove}
        className="remove"
      >
        <span>x</span>
      </div>
    </Label>
  );
};


const customResultRenderer = ({ id, name, usercount, isAddition, onClick }) => {
  if (isAddition) {
    return (
      <div key={name} className="swa-addition-wrapper">
        <Button
          primary
          type="button"
          onClick={() => _.debounce(onClick)}
        >
          Add {name}
        </Button>
      </div>
    )
  }
  return (
    <div key={id} className="menu-item-swa-wrapper">
      <div className="name">{name}</div>
      {usercount <= 0 ? (
        ''
      ) : (
          <div className="count">
            {usercount} {usercount == 1 ? 'member' : 'members'}
          </div>
        )}
    </div>
  );
};


class SearchWithBubble extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    // this.resetComponent();
  }

  render() {
    const {
      name,
      labelName,
      selectedEntity,
      results,
      value,
      onChange,
      onSelect,
      onFocus,
      onBlur,
      open,
      placeholder,
      noResultsMessage,
      resultRenderer,
      icon,
      className,
      input,
      onRemove
    } = this.props;


    return selectedEntity ? <SelectedEntity name={selectedEntity} labelName={labelName} remove={onRemove}/> : (
      <Search
        name={name}
        icon={icon}
        className={className ? className + ' swa' : 'swa'}
        onResultSelect={onSelect}
        onSearchChange={onChange}
        open={open}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        results={results}
        value={value}
        input={input || undefined}
        noResultsMessage={
          noResultsMessage
        }
        resultRenderer={resultRenderer || customResultRenderer}
      />
    );
  }
}

export default SearchWithBubble;