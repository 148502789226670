import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Card, Header, Button } from 'semantic-ui-react';

import api from '../../api';
import { hermes } from '../../utilities';
import { errors } from '../../messages';
import { observer, inject } from 'mobx-react';
import LinkWithLoader from '../../components/LinkWithLoader';
import { goTo } from '../../routing';


function Meetup({_id, name, user, url, day, month, start, end, address, description, createdAt, history, setMeetups, loadingStore }) {

  const handleClick = (action) => async () => {
    loadingStore.setLoading();
    const data = {
      _id
    };

    try {
      const { allowed, meetups } = await api.meetup[action](data);
      if (allowed) {
        setMeetups(meetups)
      } else {
        goTo('/', history)
      }
    } catch (e) {
      console.log(e)
      hermes.error(errors.DEFAULT);
    }
    loadingStore.setLoaded();
  } 

  return (
    <Card>
      <Header inverted>
        <LinkWithLoader to={`/${user.slug}`}>
        {user.name}
        </LinkWithLoader> at {new Date(createdAt).toString()}:
      </Header>
      <pre>{JSON.stringify({
        event_name: name,
        event_url: url,
        event_location: address,
        day,
        month,
        start_time: start,
        end_time: end,
        description: description

      }, null, 2)}</pre>
      <div style={{textAlign: 'center'}}>
        <Button onClick={handleClick('deleteMeetup')} secondary>Delete</Button>
      </div>
    </Card>
  )
}

export default withRouter(inject('loadingStore')(observer(Meetup)));
