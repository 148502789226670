import { action, observable, decorate, computed } from 'mobx';
import api from '../api';
import { handleError, storage } from '../utilities';
import profileStore from './profileStore';
import loadingStore from './loadingStore';

class UsertimelineStore {
  constructor() { }

  /* user feed */
  userFeed = [];
  meetups = [];
  meetup_chats = [];
  firstSearchMade = false;
  endOfResults = false;
  /* user feed */

  async loadMoreResults(view) {
    let response;
    if (!this.endOfResults) {
      try {
        loadingStore.setLoading()
        if (view.toLowerCase() == 'feed') {
          response = await api.user.getSubscriptionFeed({
            offset: this.userFeed.length,
          });
        } else if (view.toLowerCase() == 'all') {
          response = await api.user.getApplicationWideFeed({
            offset: this.userFeed.length,
          });
        }
        if (response.data.length < 10) {
          this.endOfResults = true;
        }

        this.userFeed = [...this.userFeed, ...response.data];
        loadingStore.setLoaded();
      } catch (error) {
        loadingStore.setLoaded()
        throw handleError(error);
      }
    }
  }

  async retrieveFeed(view) {
    console.log(view)
    let response;
    let user = storage.loadState('currentUser');

    loadingStore.setLoading()

    if (!user) {
      await profileStore.getUserData();
      user = profileStore.userData;
    }

    this.endOfResults = false;

    try {
      if (view.toLowerCase() == 'feed') {
        response = await api.user.getSubscriptionFeed({});
      } else if (view.toLowerCase() == 'all') {
        response = await api.user.getApplicationWideFeed({});
      }
      if (response.data.length < 10) {
        this.endOfResults = true;
      }
      this.userFeed = response.data;
      loadingStore.setLoaded()
      this.firstSearchMade = true;
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async getMeetups() {
    try {
      const response = await api.user.getMeetups();
      this.meetups = response.meetups;
      this.meetup_chats = response.meetup_chats;
    } catch (error) {
      throw handleError(error);
    }
  }

  async deleteSignal(_id) {
    try {
      loadingStore.setLoading();
      await api.user.deleteSignal(_id );
      this.userFeed = this.userFeed.filter(e => e._id !== _id);
      loadingStore.setLoaded()
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }
}

decorate(UsertimelineStore, {
  meetups: observable,
  meetup_chats: observable,
  getMeetups: action,
  endOfResults: observable,
  loadMoreResults: action,
  latestServerResponse: observable,
  firstSearchMade: observable,
  // retrieveFeed:action,
  retrieveFeed: observable,
  userFeed: observable,
  subscriptions: observable,
  gettingUserFeed: observable,
  userFeed: observable,
  deleteSignal: action,
});

export default new UsertimelineStore();
