import React from 'react';

const ApplyPage2 = () => (
  <div className="onboarding-wrapper">
    <div className="onboarding-heading ">Applied successfully</div>
    <div className="onboarding-sub-heading">
      Your application is being considered, wait until Sunday at 14:00 GMT
      <br/>
      You will receive an email once accepted.
    </div>
  </div>
)

export default ApplyPage2