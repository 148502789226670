import React, { Component, Fragment } from 'react';
import { EntityFilter, SearchInput, ResultsContainer } from '../../components/Search';
import { inject, observer } from 'mobx-react';
import { SEARCH_DELAY, mapIndexToReadableTitle, mapReadableTitleToIndex } from '../../constants';
import { customDebounce, hermes, isOnBottom } from '../../utilities';
import { withRouter } from 'react-router-dom';
import '../../css/search.css';
import { Container } from 'semantic-ui-react';
import _ from 'lodash';
import { errors, success } from '../../messages';

class SearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.addNewFavourite = this.addNewFavourite.bind(this);
    this.searchForEntity = this.searchForEntity.bind(this);
    this.search = this.search.bind(this);
  }

  async addNewFavourite(id) {
    const { favouriteStore } = this.props;
    try {
      const response = await favouriteStore.addNewFavourite({ member: id });
      hermes.success(success.ADDED_TO_FAVOURITES);
    } catch (error) {
      hermes.error(error);
    }
  }

  search(value) {
    const { searchStore, history } = this.props;
    searchStore.updateSearchValue(value, history);

    customDebounce({
      state: this.state,
      fn: searchStore.search,
      args: [],
      that: this,
    });

  }

  searchForEntity() {
    const { searchStore } = this.props;
    const { searchValue, activeView } = searchStore;
    searchStore.searchForEntity(mapReadableTitleToIndex[activeView], { query: searchValue });
  }

  componentDidMount() {
    window.ga('set', {
      page: '/search',
      title: 'Search'
    })

    window.ga('send', 'pageview');
    window.addEventListener('scroll', this.onScroll, true);
  }


  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  onScroll = () => {
    const loadingMore = this.props.searchStore.loadingMore;
    const endOfResults = this.props.searchStore.endOfResults;
    const activeView = this.props.searchStore.activeView;
    const firstFilteredQueryMade = this.props.searchStore.firstFilteredQueryMade;

    if (
      isOnBottom() &&
      !loadingMore &&
      !endOfResults[activeView] &&
      activeView === mapIndexToReadableTitle.dev_users.toLowerCase()
    ) {
      this.props.searchStore.paginatedFilteredSearch(mapReadableTitleToIndex.people);
    }

    else if (
      isOnBottom() &&
      !loadingMore &&
      !endOfResults[activeView]
      // once the first filtered query is made, we know
      // that the user is trying to filter.
      // it's turned of when we change views
      // the due the method we use to paginate,
      // paginatedFilteredSearch is able to handle retreiving remaining
      // documents when the search filters are emptied. so it neatly does 2 jobs.
      // this is because when the filters are empty, filter[0] == undefined.
      // therefore the resulting object become
      /* {query, page, filters: undefined, hitsPerPage}
      => {query, page, hitsPerPage} (because undefined is undefined)
      }
      */
      // !firstFilteredQueryMade &&
      // activeView !== mapIndexToReadableTitle.all
    ) {
      this.props.searchStore.makePaginatedQuery();
    }

  };

  render() {
    const { searchStore } = this.props;
    let { allSearchResults, searchValue } = searchStore;
    let sumOfAllHits =
      allSearchResults.length > 0 ? allSearchResults.map(item => item.nbHits).reduce((prev, next) => prev + next) : 0;
    let entitiesWithCount = [{ index: 'all', nbHits: sumOfAllHits }, ...allSearchResults].map(result => {
      return {
        count: result.nbHits,
        title: mapIndexToReadableTitle[result.index],
      };
    });

    return (
      <div>
        <div className="search-wrapper">
          <SearchInput placeholder="Type here to search ..." handleChange={this.search} value={searchValue} />
          <Container fluid>
            <div className="filterbox-wrapper">
              {allSearchResults.length > 0 ? <EntityFilter entities={entitiesWithCount} className="filterbox" /> : null}
              {/* {true ? <EntityFilter entities={entitiesWithCount} className="filterbox" /> : null} */}
            </div>
            <ResultsContainer addToFavourites={this.addNewFavourite} />
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(inject('searchStore', 'favouriteStore', 'loadingStore')(observer(SearchPage)));
