import { action, observable, decorate } from 'mobx';
import api from '../api';
import { hermes } from '../utilities';
import { errors } from '../messages';

class faqStore {
  questions = [];

  async getQuestions () {
    try {
      this.questions = await api.faq.getFAQ();
    } catch (e) {
      hermes.error(errors.FAQ_FAILED);
    }
  }
}

decorate(faqStore, {
  questions: observable,
  getQuestions: action
})

export default new faqStore()