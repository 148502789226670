import { action, observable, decorate } from 'mobx';

class LoadingStore {

  loading = false
  pb_loading = false;
  pb_loaded = false;
  page_loading = true;

  setLoading() {
    this.loading = true;
  }

  setLoaded() {
    this.loading = false;
  }

  setPBLoading() {
    this.loading = true;
    this.pb_loading = true;
    this.pb_loaded = false;
  }

  setPBLoaded() {
    this.pb_loaded = true;
    this.pb_loading = false;

    
    setTimeout(() => {
      console.trace("setLoaded");
      this.pb_loaded = false;
      this.loading = false;
      this.page_loading = false;
    }, 700)
  }
}

decorate(LoadingStore, {
  loading: observable,
  pb_loading: observable,
  pb_loaded: observable,
  page_loading: observable,
  setLoading: action,
  setLoaded: action,
  setPBLoading: action,
  setPBLoaded: action,
  setPageLoading: action,
})

export default new LoadingStore();