import axios from 'axios';
/**
 *
 *@returns the data
 * @param {*} res
 */
const parseResponse = res => res.data;

/**
 *
 *@returns the error response
 * @param {*} err
 */
const handleError = err => {
  throw err;
};


/**
 *
 *@returns the axois instaces for all CRUD operations
 *
 */
const requests = {
  get: url =>
    axios
      .get(url)
      .then(parseResponse)
      // .then(response => (response.status ? response : handleError(response.data)))
      .catch(handleError),
  post: (url, data) =>
    axios
      .post(url, data)
      .then(parseResponse)
      // .then(response => (response.status ? response : handleError(response.data)))
      .catch(handleError),
  put: (url, data) =>
    axios
      .put(url, data)
      .then(parseResponse)
      // .then(response => (response.status ? response : handleError(response.data)))
      .catch(handleError),
  patch: (url, data) =>
    axios
      .patch(url, data)
      .then(parseResponse)
      // .then(response => (response.status ? response : handleError(response.data)))
      .catch(handleError),
  delete: url =>
    axios
      .delete(url)
      .then(parseResponse)
      // .then(response => (response.status ? response : handleError(response.data)))
      .catch(handleError),
};
export { axios, parseResponse, handleError };
export default requests;
