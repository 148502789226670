import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import WorkFormItem from './WorkFormItem';
import { Button } from 'semantic-ui-react';

class WorkerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      experienceEntries: [],
      respectiveStates: [],
    };
    this.addNewWorkPlace = this.addNewWorkPlace.bind(this);
    this.removeWorkPlace = this.removeWorkPlace.bind(this);
    this.updateRespectiveState = this.updateRespectiveState.bind(this);
  }

  componentDidMount() {
    this.setState({
      respectiveStates: [{ id: 1 }],
      experienceEntries: [{ key: 1, id: 1, updateParent: this.updateRespectiveState, addAnother: this.addNewWorkPlace }],
    });
  }

  removeWorkPlace(index) {
    const copyOfEntriesState = this.state.experienceEntries.slice();
    const filteredCopyOfentriesState = copyOfEntriesState.filter((_) => _.id !== index);

    const indexOfLastEntry = filteredCopyOfentriesState[filteredCopyOfentriesState.length - 1];
    if (filteredCopyOfentriesState.length === 1) {
      this.setState({
        experienceEntries: [{ key: 1, id: 1, entriesCount: 1, updateParent: this.updateRespectiveState, addAnother: this.addNewWorkPlace }],
      })
    } else {
      Object.assign(indexOfLastEntry, { id: indexOfLastEntry.id, key: indexOfLastEntry.key, entriesCount: indexOfLastEntry.entriesCount, onRemove: this.removeWorkPlace, addAnother: this.addNewWorkPlace })
      this.setState({
        experienceEntries: filteredCopyOfentriesState
      });
    }
  }

  addNewWorkPlace() {
    const experienceEntries = this.state.experienceEntries.map(el => {
      return { ...el, addAnother: undefined }
    });
    const index = experienceEntries[experienceEntries.length - 1].id + 1;
    this.setState({
      experienceEntries: [
        ...experienceEntries,
        { id: index, key: index, updateParent: this.updateRespectiveState, onRemove: this.removeWorkPlace, addAnother: this.addNewWorkPlace },
      ],
    });
  }

  async updateRespectiveState(data) {
    const filtered = this.state.respectiveStates.filter(state => state.id != data.id);
    const respectiveStates = [...filtered, data];
    await this.setState({ respectiveStates });
    this.props.updateParent(this.state.respectiveStates);
  }

  render() {
    return (
      <Fragment>
        {this.state.experienceEntries.map(props => {
          return <WorkFormItem 
                    resetError={this.props.resetError} 
                    resetErrorCheckedButton={this.props.resetErrorCheckedButton}
                    {...props} />
        })}
      </Fragment>
    );
  }
}

export default inject('authStore', 'onboardingStore')(observer(WorkerDetails));
