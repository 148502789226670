import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import * as constants from '../constants';
import runValidation from './validation';
import storage from './storage';
import history from './history';
import hermes from './hermes';
import { errors } from '../messages'
import React from 'react';
import _slugify from 'slugify';
import authStore from '../stores/authStore';
import Bowser from 'bowser'
import { switchCase } from '@babel/types';

const isDesktop = Bowser.parse(window.navigator.userAgent).platform.type === 'desktop';


function capitalize(s) {
  if (s) {
    return s.split(' ').map(s => (s[0] ? s[0].toUpperCase() : '') + s.slice(1)).join(' ');
  }
  return s
}

function getFirstName(string) {
  return string.split(' ')[0];
}

function customDebounce({ state, that, args, fn }) {
  if (state.timeout) {
    window.clearTimeout(state.timeout);
  }

  const id = setTimeout(() => {
    fn(...args);
  }, constants.SEARCH_DELAY);

  that.setState({
    timeout: id,
  });
}

function generateEmtpyEntityMessage(entity) {
  return <p>This user hasn't added any {entity}</p>;
}

function generateConsiderAdding(entity) {
  return <p>You should consider adding {entity}</p>;
}

async function geoCodeAndParse(location) {
  try {
    const results = await geocodeByAddress(location);
    const latLng = await getLatLng(results[0]);
    let sp = location.split(',');
    let country = sp[sp.length - 1];
    country = country.trim();
    let city = sp[0];
    city = city.trim();

    let latitude = latLng.lat;
    let longitude = latLng.lng;

    return {
      country: country,
      name: location,
      lat: latitude,
      log: longitude,
    };
  } catch (e) { }
}

function parseError(err) {
  // a couple of if and elses
  // and return a string
  // console.log(err, 'Error Occured');
  if (err) {
    if (err.request && err.request.response) {
      return errors[JSON.parse(err.request.response).data.type] || errors.DEFAULT
    }
    if (err == "UNAUTHENTICATED") {
      authStore.logout();
      return errors.UNAUTHENTICATED
    }
    return errors[err.type] || errors.DEFAULT
  }
  return errors.CONNECTION_ERROR;
}

function handleError(err) {
  return parseError(err);
}

function moveToPage(pageNum) {
  //   store.onboardingStore.syncOnboardingPage(parseFloat(pageNum));
  //   history.push(`/onboarding/${parseFloat(pageNum)}`);
}

function createBlob(file) {
  return URL.createObjectURL(file);
}

// const oldDegreeTypes = [
//   { text: 'Associate of Arts', value: 'A.A', key: 0 },
//   { text: 'Associate of Science', value: 'A.S', key: 1 },
//   { text: 'Associate of Applied Science', value: 'AAS', key: 2 },
//   { text: 'Bachelor of Arts ', value: 'B.A', key: 3 },
//   { text: 'Bachelor of Science ', value: 'B.S', key: 4 },
//   { text: 'Bachelor of Fine Arts ', value: 'B.F.A', key: 5 },
//   { text: 'Bachelor of Applied Science ', value: 'B.A.S', key: 6 },
//   { text: 'Master of Arts ', value: 'M.A', key: 7 },
//   { text: 'Master of Science ', value: 'M.S', key: 8 },
//   { text: 'Master of Business Administration ', value: 'M.B.A', key: 9 },
//   { text: 'Master of Fine Arts ', value: 'M.F.A', key: 10 },

//   { text: 'Doctor of Philosophy ', value: 'Ph.D', key: 11 },
//   { text: 'Juris Doctor ', value: 'J.D', key: 12 },
//   { text: 'Doctor of Medicine ', value: 'M.D', key: 13 },
//   { text: 'Doctor of Dental Surgery ', value: 'DDS', key: 14 },
// ];

let degreeTypes = [];
const types = Object.keys(constants.mapDegreeWithSubTypes).map(item => constants.mapDegreeWithSubTypes[item]);
for (const array of types) {
  degreeTypes = degreeTypes.concat(
    array.map((item, index) => {
      return { text: item, value: item, key: degreeTypes.length + index };
    }),
  );
}

function slugify(name) {
  return _slugify(name).toLowerCase() || name.toLowerCase().replace(/\s+/g, '-');
}

function getOrdinalNum(n) {
  return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
}

function domain_from_url(url) {
  var result;
  var match;
  if ((match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im))) {
    result = match[1];
    if ((match = result.match(/^[^\.]+\.(.+\..+)$/))) {
      result = match[1];
    }
  }
  return result;
}

function mergeSortedArrays(arr1, arr2) {
  let result = []
  let index1 = 0
  let index2 = 0

  while (index1 < arr1.length && index2 < arr2.length) {
    if (arr1[index1].name < arr2[index2].name) {
      result.push(arr1[index1])
      index1++
    } else {
      result.push(arr2[index2])
      index2++
    }
  }

  return result.concat(arr1.slice(index1)).concat(arr2.slice(index2))
}

function isOnBottom() {
  const offset = isDesktop ? 0 : 60

  return (document.documentElement.scrollTop || document.body.scrollTop) +
    document.documentElement.clientHeight + offset >= document.documentElement.scrollHeight
}

function fbToMsg(fb){
  const re = /^(?:https?:\/\/)?(?:(?:www|m)\.)?(?:facebook\.com\/(?:profile\.php\?id=)?|fb\.me\/)([A-Z0-9.]{5,})$/i;
  const match = re.exec(fb.trim());
  if (match && match[1]) {
    return 'm.me/' + match[1]
  }
  return '';
}

function trimChar(string, charToRemove) {
  while(string.charAt(0)==charToRemove) {
      string = string.substring(1);
  }

  while(string.charAt(string.length-1)==charToRemove) {
      string = string.substring(0,string.length-1);
  }

  return string;
}

function expired(date) {
  if (!date) {
    return false
  }
  return new Date() - new Date(date) >= 0;
}

export {
  hermes,
  createBlob,
  domain_from_url,
  generateConsiderAdding,
  generateEmtpyEntityMessage,
  geoCodeAndParse,
  degreeTypes,
  storage,
  history,
  runValidation,
  getFirstName,
  customDebounce,
  handleError,
  moveToPage,
  slugify,
  capitalize,
  getOrdinalNum,
  mergeSortedArrays,
  isOnBottom,
  fbToMsg,
  trimChar,
  expired,
};
