import * as yup from 'yup';
import { errors } from '../messages';

const schema = yup.object({
  university: yup
    .string()
    .min(1)
    .required(),
  degreeType: yup
    .string()
    .min(1)
    .required(),
  programme: yup
    .string()
    .min(1)
    .required(),
  description: yup.string().max(120),
  selectedUniversity: yup.object().required(),
});

export default schema;
