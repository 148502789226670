import React from 'react';
import Slider from './Slider'
import Slide1 from './Slide1';
import Slide2 from './Slide2';
import Slide4 from './Slide4';

function LandingPage () {
  return (
    <Slider>
      <Slide1 />
      <Slide2 />
      <Slide4 />
    </Slider>
  )
}

export default LandingPage;
