import React from 'react';
import { inject, observer } from 'mobx-react';

function PageMask({ loadingStore: { page_loading } }) {
  
  if (page_loading) {
    return <div className="page-mask"></div> 
  }

  return null;
}

export default inject('loadingStore')(observer(PageMask));