import React, { Component } from 'react';
import UserProfile from './UserProfile';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Container } from 'semantic-ui-react';
import { hermes } from '../../utilities';

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const { profileStore, authStore, match } = this.props;
    const isCurrentUserProfile = match.params.id == authStore.currentUser.slug;

    if (isCurrentUserProfile) {
      window.ga('set', {
        page: '/profile',
        title: 'User Profile'
      })
    } else {
      window.ga('set', {
        page: '/:userid',
        title: "Other User's Profile"
      })
    }

    window.ga('send', 'pageview')
  }

  async componentDidUpdate(prevProps) {
    const { profileStore,  match } = this.props;
    if (prevProps.match.params.id !== match.params.id) {
      try {
        const response = await profileStore.updateProfile(match.params.id);
      } catch (error) {
        hermes.error(error);
      }
    }
  }

  render() {
    const { profileStore, authStore, match, loadingStore } = this.props;

    if (!profileStore.profileData && profileStore.hasSearched) {
      return (
        <Container style={{ paddingTop: "50px" }}>
          <h2>Page Not Found</h2>
        </Container>
      );
    } else if (match.params.id == authStore.currentUser.slug && profileStore.profileData) {
      return (
        <Container>
          <UserProfile />
        </Container>
      );
    } else if (profileStore.profileData) {
      return (
        <Container>
          <UserProfile otherUser={true} />
        </Container>
      );
    } else {
      return (
        <Container style={{ paddingTop: "50px" }}>
          <h2>Page Not Found</h2>
        </Container>
      );
    }
  }
}

export default withRouter(inject('profileStore', 'authStore', 'loadingStore')(observer(ProfilePage)));
