import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import SearchWithBubble from './SearchWithBubble';
import { Form, Dropdown, Input, Card, Button } from 'semantic-ui-react';
import { customDebounce, degreeTypes, capitalize } from '../../utilities';
import { errors } from '../../messages';
import { hermes } from '../../utilities';

const Field = Form.Field;

class SchoolFormItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      university: '',
      startYear: null,
      endYear: null,
      programName: '',
      degreeTypes: degreeTypes,
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleResultSelect = this.handleResultSelect.bind(this);
    this.addUniversity = this.addUniversity.bind(this);
    this.handleChange = this.handleChange.bind(this);

    let currentYear = new Date().getFullYear();

    this.startYearRange = [{ key: 'default', text: 'Start Year', value: null }];
    this.endYearRange = [{ key: 'default', text: 'End Year', value: null }];

    for (let i = currentYear + 7; i >= currentYear + 1; i--) {
      this.endYearRange.push({ key: i, text: i, value: i });
    }

    for (let i = currentYear; i >= 1900; i--) {
      this.startYearRange.push({ key: i, text: i, value: i });
      this.endYearRange.push({ key: i, text: i, value: i });
    }
  }

  async handleSearchChange(e, { value }) {
    const { onboardingStore } = this.props;
    await this.setState({ university: value });
    this.props.updateParent(this.state);

    customDebounce({
      state: this.state,
      that: this,
      args: [value],
      fn: onboardingStore.searchForUniverity,
    });
  }

  async handleResultSelect(e, { result }) {
    await this.setState({
      university: result.name,
      selectedUniversity: result,
    });
    this.props.updateParent(this.state);
  }

  handleResultRemove = async () => {
    await this.setState({
      university: '',
      selectedUniversity: null,
    });
    this.props.updateParent(this.state);
  }

  handleChange(option) {
    return async value => {
      await this.setState({ [option]: value });
      this.props.updateParent(this.state);
    };
  }

  async addUniversity(name) {
    const data = await this.props.onboardingStore.addUniversity({ name });
    await this.setState({ university: data.name, selectedUniversity: data });
    this.props.updateParent(this.state);
  }

  render() {
    const { onboardingStore, onRemove, addAnother } = this.props;
    const { university, startYear, endYear, degreeTypes, programName, selectedUniversity, id } = this.state;
    let results = onboardingStore.computedUniversities;
    let initialUniversitySearch = onboardingStore.initialUniversitySearch;

    const exists = results[0] && results[0].name.toLowerCase().trim() === university.toLowerCase().trim();

    if (!initialUniversitySearch && !exists) {
      results.push({
        name: capitalize(university.trim()),
        onClick: () => this.addUniversity(capitalize(university.trim())),
        isAddition: true
      })
    }

    return (
      <Card className="form-wrapper">
        <div className="school-form-item">
          <Form autoComplete="off">
            <Field className="field" required>
              <label> {this.props.entriesCount == 1 ? 'Last college' : 'College'} or university </label>
              <SearchWithBubble
                icon=""
                results={results}
                value={university}
                selectedEntity={selectedUniversity && selectedUniversity.name}
                placeholder="Ex: Harvard University"
                onChange={this.handleSearchChange}
                onSelect={this.handleResultSelect}
                onRemove={this.handleResultRemove}
                addNewValue={this.addUniversity}
                noResultsMessage={null}
              />
            </Field>

            <div className="school-years">
              <Field className="field">
                <label>Start year</label>
                <Dropdown
                  name="startYear"
                  options={this.startYearRange}
                  search
                  selection
                  placeholder="Ex: 2012"
                  value={startYear}
                  onChange={(e, { value }) => this.handleChange('startYear')(value)}
                />
              </Field>

              <Field className="field">
                <label>End year</label>
                <Dropdown
                  name="endYear"
                  options={this.endYearRange}
                  search
                  selection
                  placeholder="Ex: 2016"
                  value={endYear}
                  onChange={(e, { value }) => this.handleChange('endYear')(value)}
                />
              </Field>

            </div>

            <Field className="field" required>
              <label>Degree type</label>
              <Dropdown
                placeholder="Ex: Bachelor's"
                search
                fluid
                selection
                options={degreeTypes}
                onChange={(e, { value }) => {
                  this.handleChange('degreeType')(value);
                }}
              />
            </Field>
            <Field className="field" required>
              <label>Programme name</label>
              <Input
                placeholder="Ex: Computer Science"
                value={programName}
                type="text"
                onChange={(e, { value }) => {
                  this.handleChange('programName')(value);
                }}
              />
            </Field>
            {onRemove ? <Button primary onClick={()=>onRemove(id)}>Remove</Button> : null}
            {addAnother ? <Button primary onClick={addAnother}>Add</Button> : null}
          </Form>
        </div>
      </Card>
    );
  }
}

export default inject('authStore', 'onboardingStore')(observer(SchoolFormItem));
