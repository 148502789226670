import React from 'react';
import { Input, Container } from 'semantic-ui-react';

const SearchInput = ({ value = '', handleChange, placeholder }) => {
  return (
    <div className="searchbox">
      <Container fluid>
        <Input
          placeholder={placeholder}
          onChange={(e, { value }) => {
            handleChange(value);
          }}
          value={value}
          className="wrapper"
        />
      </Container>
    </div>
  );
};

export default SearchInput;
