import config from './config';
import requests from './http';
const parseResponse = res => res.data;

const endpoint = `${config.getApiEndpoint()}/location`
const user_endpoint = `${config.getApiEndpoint()}/user`;

export default {
  updateLocation(data) {
    return requests.put(`${user_endpoint}/location`, data).then(parseResponse);
  },
  lookupLocation(data) {
    return requests.put(`${user_endpoint}/lookupLocation`, data).then(parseResponse);
  },
  updateCurrentLocation(data) {
    return requests.put(`${user_endpoint}/currentLocation`, data).then(parseResponse);
  },
  searchForLocation(name) {
    return requests.get(`${endpoint}/search?q=${name}`).then(parseResponse);
  },
};
