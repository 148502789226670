import React, { Fragment, Component } from 'react';
import { Form, Label, Header, Checkbox } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { hermes, capitalize } from '../../utilities';
import SearchWithAddition from '../../components/Forms/SearchWithAddition';
import { mapIndexToReadableTitle } from '../../constants';
import LocationForm from '../../components/Forms/Location';
import * as constants from '../../constants';
import { errors } from '../../messages';
import HiddableForm from '../..'

const SelectedEntity = observer(({ id, name, remove }) => {
  return (
    <Label className="entity-label" basic>
      {name}
      <div
        onClick={() => { remove(id) }}
        className="remove"
      >
        <span>x</span>
      </div>
    </Label>
  );
});


class EntitySubscriptionItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entity: '',
      entities: [],
      selectedEntities: [],
      options: [],
      location: '',
      addingEntity: false,
      initialSearch: false,
      specifyEntity: false
    };
    this.handleResultSelect = this.handleResultSelect.bind(this);
    this.removeEntity = this.removeEntity.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchForEntity = this.searchForEntity.bind(this);
    this.addNewEntity = this.addNewEntity.bind(this);
    this.handleLocationSelect = this.handleLocationSelect.bind(this);
  }

  componentDidMount() {
    const { entityType } = this.props;
    this.setState({ specifyEntity: !!entityType.subscriptions.length })
  }

  handleSpecifyChange = () =>  {
    const { subscriptionStore, entityType } = this.props;
    this.setState({ specifyEntity: !this.state.specifyEntity })
    subscriptionStore.cleanSubscriptions(entityType.subtype);
  }

  handleChange(option) {
    return value => {
      this.setState({ [option]: value });
    };
  }

  handleCheckBoxChange = () => {
    const { entityType, subscriptionStore } = this.props;
    subscriptionStore.toggleHidden(entityType.subtype);
  }

  async addNewEntity(type, name) {
    const { subscriptionStore, title } = this.props;
    await this.setState({ entity: name, addingEntity: true });

    try {
      const data = await subscriptionStore.addNewEntity(type, { name });
      await this.setState({
        entity: '',
        entities: [...this.state.entities, data.name],
        selectedEntities: [...this.state.selectedEntities, data],
      });
      subscriptionStore.updateSelectedEntities(title, this.state.selectedEntities);
    } catch (error) {
      console.log(error);
      hermes.error(errors.DEFAULT);
    }
  }

  async searchForEntity(d, { value }) {
    const { subscriptionStore, title, loadingStore } = this.props;
    loadingStore.setLoading()
    this.setState({ entity: value });

    if (this.state.timeout) {
      window.clearTimeout(this.state.timeout);
    }

    const id = setTimeout(async () => {
      const response = await subscriptionStore.searchThroughEntity(title, value);
      await this.setState({ options: response, initialSearch: true });
      loadingStore.setLoaded()
    }, 600);

    this.setState({
      timeout: id,
    });
  }

  async removeEntity(id) {
    const { subscriptionStore, entityType } = this.props;
    subscriptionStore.updateSelectedEntities(entityType.subtype, id, "REMOVE");
  }

  async handleResultSelect(e, { result }) {
    const { subscriptionStore, title } = this.props;

    if (title == "Skill") {
      for (const skill of this.state.selectedEntities) {
        if (result.name.toLowerCase() == skill.name.toLowerCase()) {
          return;
        }
      }
    }

    await this.setState({
      entity: '',
      entities: [...this.state.entities, result.name],
      selectedEntities: [...this.state.selectedEntities, result],
    });

    subscriptionStore.updateSelectedEntities(title, this.state.selectedEntities);
  }

  handleLocationSelect(option) {
    const { subscriptionStore, loadingStore, entityType } = this.props;
    return async value => {
      try {
        loadingStore.setLoading();
        const result = await subscriptionStore.lookupLocation(value);
        // console.log(this.state.selectedEntities, 'selelel');
        await this.setState({ entity: '' });
        loadingStore.setLoaded();
        subscriptionStore.updateSelectedEntities(entityType.subtype, result);
      } catch (error) {
        hermes.error(error);
      }
    };
  }

  render() {

    const { entity, options, specifyEntity } = this.state;
    const { entityType } = this.props;
    const searchOptions = options.map((entity, index) => {
      return {
        key: index,
        _id: entity._id,
        title: entity.name,
        name: entity.name,
        text: entity.name,
        value: entity.value,
        usercount: entity.userCount,
      };
    });

    const exists = searchOptions[0] && searchOptions[0].name.toLowerCase().trim() === entity.toLowerCase().trim();

    if(!exists) {
      searchOptions.push({
        name: capitalize(entity.trim()),
        isAddition: true,
        onClick: () => this.addNewEntity(entityType.type, capitalize(entity.trim()))
      })
    }

    let element = null;

    if (entityType.type == 'location') {
      element = (
        <LocationForm
          placeholder={entityType.placeholder}
          value={entity}
          handleSelect={this.handleLocationSelect('entity')}
          handleChange={this.handleChange('entity')}
          label={entityType.title}
          name={entityType.title}
        />
      );
    } else {
      element = (
        <SearchWithAddition
          icon=""
          results={searchOptions}
          value={entity}
          onChange={this.searchForEntity}
          onSelect={this.handleResultSelect}
          addNewValue={this.addNewEntity}
          placeholder={this.props.placeholder}
          noResultsMessage={null}
        />
      );
    }

    return (
      <div className="form-section">
        <div><Checkbox toggle checked={!entityType.hidden} onChange={this.handleCheckBoxChange} label={entityType.title}/></div>
        {entityType.hidden ? null : (
          <Fragment>
            <Checkbox label="Specify locations" checked={specifyEntity} onChange={this.handleSpecifyChange}/>
            {specifyEntity ? (
              <Fragment>
                <Form.Field>
                  {element}
                </Form.Field>

                <div className="entities-wrapper">
                  {entityType.subscriptions // mitigate error joshua experienced
                    .map((entity) => {
                      return (
                        <SelectedEntity id={entity._id} name={entity.name} key={entity._id} remove={this.removeEntity} />
                      );
                    })
                  }
                </div>
              </Fragment>
            ) : null}
          </Fragment>
        )}

      </div>
    );
  }
}

export default inject('subscriptionStore', 'loadingStore')(observer(EntitySubscriptionItem));
