import config from './config';
import requests, { parseResponse } from './http';

const endpoint = `${config.getApiEndpoint()}/university`;

export default {
  searchForUniversity(name) {
    return requests.get(`${endpoint}/search?q=${name}`).then(parseResponse);
  },
  addUniversity(data) {
    return requests.post(endpoint, data).then(parseResponse);
  },
};
