import React, { Component, Fragment } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import UniversityReference from './University';
import SkillReference from './Skill';
import LocationReference from './Location';
import CompanyReference from './Company';
import favouriteStore from '../../stores/favouriteStore';
import '../../css/reference.css';
import { hermes, isOnBottom } from '../../utilities';
import { Container } from 'semantic-ui-react';
import { success } from '../../messages';

class ReferencePage extends Component {
  constructor(props) {
    super(props);
    this.state = { currentPage: 0 };
    this.addNewFavourite = this.addNewFavourite.bind(this);
  }

  async addNewFavourite(id) {
    try {
      const response = await favouriteStore.addNewFavourite({ member: id });
      hermes.success(success.ADDED_TO_FAVOURITES);
    } catch (error) {
      hermes.error(error);
    }
  }

  componentDidMount() {
    const { referenceStore } = this.props;

    referenceStore.selectedLocation = 'All';
    referenceStore.selectedLocationType = 'All';
    referenceStore.resultType = 'All';

    window.addEventListener('scroll', this.onScroll, true);
    window.store = this.props.referenceStore;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  onScroll = () => {
    const { loadingStore, referenceStore } = this.props;
    const { firstSearchMade, loadMoreResults, endOfResults } = referenceStore;

    if (
      isOnBottom() &&
      !loadingStore.loading &&
      !endOfResults &&
      firstSearchMade
    ) {
      loadMoreResults();
    }
  };

  render() {
    const { endOfResults, loadingMoreResults } = this.props.referenceStore;
    return (
      <Container fluid>
        <Switch>
          <Route
            path="/university/:id"
            exact
            render={props => <UniversityReference {...props} addNewFavourite={this.addNewFavourite} />}
          />
          <Route
            path="/skill/:id"
            exact
            render={props => <SkillReference {...props} addNewFavourite={this.addNewFavourite} />}
          />
          <Route
            path="/company/:id"
            exact
            render={props => <CompanyReference {...props} addNewFavourite={this.addNewFavourite} />}
          />
          <Route
            path="/location/:id"
            exact
            render={props => <LocationReference {...props} addNewFavourite={this.addNewFavourite} />}
          />
          {/* <Route component={SubRouteNotFound} /> */}
        </Switch>
      </Container>
    );
  }
}

export default withRouter(inject('referenceStore', 'favouriteStore', 'loadingStore')(observer(ReferencePage)));
