import React from 'react';
import map3 from '../../images/map2.svg'

const Slide2 = ({ className }) => (
  <div className={className + " s2"}>
    <div className="s2-wrapper">
    <img src={map3} />
    <h1>We're a global community. Online and offline. </h1>
    <p>Our members started hubs in San Francisco, Palo Alto, Seattle, New York, Boston, London, Oxford, Cambridge, Edinburgh, Leuven, Berlin, Singapore, Hong Kong. They host meet-ups in restaurants, their offices and their homes. Our community was built and maintained by our members. We merely gave them the tools to do so.</p>
    </div>
  </div>
)

export default Slide2;
