import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import SchoolFormItem from './SchoolFormItem';
import { Button } from 'semantic-ui-react';

class StudentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      educationEntries: [],
      respectiveStates: [],
    };
    this.updateRespectiveState = this.updateRespectiveState.bind(this);
    this.removeSchool = this.removeSchool.bind(this)
    this.addNewSchool = this.addNewSchool.bind(this);
  }

  componentDidMount() {
    this.setState({
      respectiveStates: [{ id: 1 }],
      educationEntries: [{ key: 1, id: 1, entriesCount: 1, updateParent: this.updateRespectiveState, addAnother: this.addNewSchool }],
    });
  }

  removeSchool(index) {
    const copyOfEntriesState = this.state.educationEntries.slice();
    const filteredCopyOfentriesState = copyOfEntriesState.filter((_) => _.id !== index);

    const indexOfLastEntry = filteredCopyOfentriesState[filteredCopyOfentriesState.length - 1];
    if (filteredCopyOfentriesState.length === 1) {
      this.setState({
        educationEntries: [{ key: 1, id: 1, entriesCount: 1, updateParent: this.updateRespectiveState, addAnother: this.addNewSchool }],
      })
    } else {
      Object.assign(indexOfLastEntry, { id: indexOfLastEntry.id, key: indexOfLastEntry.key, entriesCount: indexOfLastEntry.entriesCount, onRemove: this.removeSchool, addAnother: this.addNewSchool })
      this.setState({
        educationEntries: filteredCopyOfentriesState
      });
    }
  }

  addNewSchool() {
    const educationEntries = this.state.educationEntries.map(el => {
      return { ...el, addAnother: undefined }
    });
    const index = educationEntries[educationEntries.length - 1].id + 1;
    this.setState({
      educationEntries: [
        ...educationEntries,
        { id: index, key: index, entriesCount: index, updateParent: this.updateRespectiveState, onRemove: this.removeSchool, addAnother: this.addNewSchool },
      ],
    });
  }

  async updateRespectiveState(data) {
    const filtered = this.state.respectiveStates.filter(state => state.id != data.id);
    const respectiveStates = [...filtered, data];
    await this.setState({ respectiveStates });
    this.props.updateParent(this.state.respectiveStates);
  }

  render() {
    return (
      <Fragment>
        {this.state.educationEntries.map(props => {
          return <SchoolFormItem {...props} />
        })}
      </Fragment>
    );
  }
}

export default inject('authStore', 'onboardingStore')(observer(StudentDetails));
