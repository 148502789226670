import React from 'react';
import IcpLogo from '../../images/icp-logo.svg';
import Cover from '../../images/cover.jpg';

export default () => (
  <div>
    <div className="landing-page-container fixed-viewport" style={{ backgroundImage: `url("${Cover}"` }} >
      <div className="landing-page">
        <div className="landing-page-wrapper" style={{ maxWidth: "750px" }}>
          {/* logo */}
          <div className="logo-container">
            <img alt="icp logo" src={IcpLogo} className="icp-logo" />
          </div>
          {/* company tagline */}
          <div className="tagline-container">
            <h2>We only support <b>Google Chrome</b>, <b>Firefox</b>, <b>Opera</b> and <b>Safari</b> for now.</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
)
