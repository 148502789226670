export const errors = {
  // When a token has expired or is invalid.
  UNAUTHENTICATED: 'Please login',

  // When validation fails.
  VALIDATION_ERROR: 'You have not inputted all information correctly',

  // When a user is already subscribed to something and try to subscribe again.
  ALREADY_SUBSCRIBED: 'You already subscribed to that',

  // When a user has already saved other user on favourites.
  ALREADY_FAVOURITED: 'You already saved that user',

  // When a user types an incorrect password.
  WRONG_PASSWORD: 'Incorrect password',

  // When a user tries to use an change email to an email that is already used
  // by another user.
  EMAIL_ALREADY_EXISTS: 'That email is already registered',

  // When a user tries to sign up with an already registered email.
  EMAIL_TAKEN: 'That email is already registered',

  // When a user tries to sign up with an name already registered.
  USER_ALREADY_REGISTERED: 'That member has already registered',

  // When there's an internal server error.
  INTERNAL_SERVER_ERROR: 'We are experiencing an internal server error',

  // When there's a connection problem.
  CONNECTION_ERROR: 'There is a problem with your internet connection',

  // When a bio fails to update.
  BIO_UPDATE_ERROR: 'Your profile was not updated',

  // When fails at adding or updating an education.
  EDUCATION_UPDATE_ERROR: 'Your education has not been updated successfully',

  // When fails at adding or updating an experience.
  EXPERIENCE_UPDATE_ERROR: 'Your experience has not been updated successfully',

  // When a company is missing.
  MISSING_COMPANY: 'A suggested or added company is required',

  // When end year is missing and not working in the company.
  MISSING_END_YEAR: "An end date is required if you are not currently working somewhere",

  // When start year is missing.
  MISSING_START_YEAR: 'A start date is required',

  // When job title is missing.
  MISSING_JOB_TITLE: 'A job title is required',

  // When a company name is missing.
  MISSING_COMPANY_NAME: 'A suggested or added company name is required',

  // When a headline is missing
  MISSING_HEADLINE: 'You must enter the headline',

  // When less then 3 skills are inputed
  MISSING_SKILLS: 'You must enter at least 3 skills',

  //Unknown university name
  UNKNOWN_UNIVERSITY_NAME: 'Please add a university or select a suggestion',

  //Unknown company name
  UNKNOWN_COMPANY_NAME: 'Please add a company or select a suggestion',

  //Unknown company name
  UNKNOWN_LOCATION: 'Please select a suggested location',

  // When login fails.
  LOGIN_FAILED: 'Your login failed',

  // When email is not registred and tried to use it on login.
  EMAIL_NOT_REGISTERED: "That email is not registered",

  // When verification email fails to send.
  FAILED_SEND_PASSWORD_EMAIL: 'Your verification email was not successfully sent',

  // When facebook login fails.
  FACEBOOK_LOGIN_FAILED: 'Your Facebook login failed',

  // When the facebook account was already registered.
  FACEBOOK_ACCOUNT_REGISTERED: "An existing member already has that Facebook account",

  // When facebook name doesn't match signup name
  DIFERENT_NAMES_SIGNUP: "The name you entered does not match your Facebook name, re-input it",

  // When an image is too large
  IMAGE_TOO_LARGE: 'That image you added is too large',

  // When an image is invalid
  INVALID_IMAGE: 'That image you added will not work, try another one',

  // When password reset token is invalid
  INVALID_PASSWORD_TOKEN: 'That password reset request has expired',

  // When passwords don't match
  PASSWORDS_DONT_MATCH: "Your passwords do not match",

  // When user don't specify current password
  CURRENT_PASSWORD_REQUIRED: 'Enter your password below to make save changes',

  // When user don't specify a valid website url
  INVALID_USER_WEBSITE_URL: "The website you inputted is invalid",

  // When user doesn't provide current location
  MISSING_CURRENT_LOCATION: 'Please input where you are',

  // When user doesn't provide location of origin
  MISSING_PREVIOUS_LOCATION: "Please input where you're from",

  // When a location is not selected
  LOCATION_NOT_SELECTED: 'Select a suggested location',

  // When a db entity is not selected
  ENTITY_NOT_SELECTED: 'Please input or add a suggestion to our forms',

  // When a user enters a password too short
  PASSWORD_TOO_SHORT: 'Your password is too short',

  // When search fails
  SEARCH_FAILED: 'Our search has failed',

  // When missing meetup name
  MISSING_MEETUP_NAME: 'The name of your meetup is required',

  // When missing meetup url
  MISSING_MEETUP_URL: 'A url is required',

  // When missing meetup day
  MISSING_MEETUP_DAY: 'A day is required',

  // When missing meetup month
  MISSING_MEETUP_MONTH: 'A month is required',

  // When missing meetup start time
  MISSING_MEETUP_START: 'A start time is required',

  // When missing meetup end time
  MISSING_MEETUP_END: 'An end time is required',

  // When missing meetup address
  MISSING_MEETUP_ADDRESS: 'An address is required',

  // When missing meetup description
  MISSING_MEETUP_DESCRIPTION: 'A description is required',

  // When missing report bug browser and version
  MISSING_REPORT_BUG_BROWSER: 'A browser name and version is required',

  // When missing report bug
  MISSING_REPORT_BUG_DESCRIPTION: 'A description is required',

  // When the informed date is not a valid date;
  INVALID_MEETUP_DATE: 'The date you inputted is not valid',

  // When the user entered an invalid facebook profile URL
  INVALID_FACEBOOK_URL: 'The Facebook url you inputted is invalid',

  // When the user entered an invalid messenger URL
  INVALID_MESSENGER_URL: 'The Facebook Messenger url you inputted is invalid',

  // When the user entered an invalid twitter URL
  INVALID_TWITTER_URL: 'The Twitter url you inputted is invalid',

  // When the user entered an invalid linkedin URL
  INVALID_LINKEDIN_URL: 'The Linkedin url you inputted is invalid',

  // When the user entered an invalid dribbble URL
  INVALID_DRIBBBLE_URL: 'The Dribbble url you inputted is invalid',

  // When the user entered an invalid instagram URL
  INVALID_INSTAGRAM_URL: 'The Instagram url you inputted is invalid',

  // When the user entered an invalid github URL
  INVALID_GITHUB_URL: 'The Github url you inputted is invalid',

  // When FAQ fails to load
  FAQ_FAILED: "The FAQ page has failed to load",

  // When failed to reorder experiences
  REORDER_EXPERIENCES_FAILED: "We were not able to rearrange your job history",

  // When failed to reorder educations
  REORDER_EDUCATIONS_FAILED: "We were not able to rearrange your education history",

  // When the user don't specify number of visiting days
  NUMBER_OF_VISITING_DAYS_REQUIRED: "Specify how many days you'll be visiting that location",

  // When the user specify an invalid number of visiting days
  NUMBER_OF_VISITING_DAYS_INVALID: "Specify a valid amount of days you'll be visiting that location",

  MINUTES_TO_CALL_REQUIRED: "Specify within how many minutes you'd like to call",

  // When the user doesn't change any signal data and tries to submit
  NO_SIGNAL_CHANGED: "You have not signalled anything new, please go back",

  // When the provided phone number is invalid
  INVALID_PHONE_NUMBER: "That phone number is not valid",

  // When the user has not verified the phone
  PHONE_VERIFICATION_REQUIRED: "Please verify your phone number",

  // When the verification code doesn't have 6 digits
  INVALID_VERIFICATION_CODE_LENGTH: "Your verification code must be 6 digits",

  // When the code is not the right one
  INVALID_VERIFICATION_CODE: "That verification code is wrong",

  // When someone doesn't mark requested to join facebook group
  REQUEST_TO_JOIN_GROUP_REQUIRED: "Request to join the Facebook group",

  // When someone doesn't mark messaged joshua box on apply page 1
  MESSAGED_JOSHUA_REQUIRED: "Message Joshua Vantard on Messenger",

  // When someone doesn't input facebook url
  FACEBOOK_URL_REQUIRED: "Submit your Facebook profile",

  // When someone doesn't input linkedin url
  LINKEDIN_URL_REQUIRED: "Submit your Linkedin profile",

  // When error was not handled or it's unknown
  DEFAULT: "An unknown error has occurred, please submit a bug report under 'more'",

  // when date values are invalid
  VALIDATE_DATES: "Invalid date inputs"
}

export const success = {

  // When profile is updated
  PROFILE_UPDATED: 'Your profile is successfully updated',

  // When a user adds a new education
  EDUCATION_ADDED: 'Your education history is successfully updated',

  // When a user updates education
  EDUCATION_UPDATED: 'Your education history is successfully updated',

  // When a adds a new experience
  EXPERIENCE_ADDED: 'Your job history is successfully updated',

  // When a user updates experience
  EXPERIENCE_UPDATED: 'Your job history is successfully updated',

  // When an user saves to favourites
  ADDED_TO_FAVOURITES: 'Successfully saved to favourites',

  // When subscription page saved
  SUBSCRIPTIONS_SAVED: 'Your listens are successfully updated',

  // When subscribed
  SUBSCRIBED: 'You successfully listened',

  // When settings updated sucessfully
  SETTINGS_UPDATED: 'Your settings are successfully updated',

  // When email is verified
  EMAIL_VERIFIED: (name) => `Thank you ${name} for verifying your email`,

  // When user adds a note
  NOTE_ADDED: 'You successfully saved a note',

  // When user is removed from favourites
  REMOVED_FROM_FAVOURITES: 'Member successfully unsaved',

  // When enforcing user to complete signup
  COMPLETE_SIGNUP: 'Complete your signup',

  // When password reset email is sent
  SENT_PASSWORD_RESET_EMAIL: "We sent you an email to reset your password",

  // When facebook login is successfull
  FACEBOOK_LOGIN_SUCCESS: 'Your Facebook login is successful',

  // When password is updated
  PASSWORD_DID_RESET: "New password successfully saved",

  // When a meetup is submited,
  MEETUP_SENT: "You successfully submitted a meetup suggestion",

  // When a bug is reported
  BUG_REPORTED: "You successfully reported a bug",

  // When experiences are reordered
  REORDERED_EXPERIENCES: "Your job history was successfully reordered",

  // When educations are reordered
  REORDERED_EDUCATIONS: "Your education history was successfully reordered",

  // When signal was sent
  SIGNAL_SENT: "Signal successfully broadcasted",

  // When the user verifies the phone number
  PHONE_VERIFIED: "You successfully verified your phone number",

  // Verification code was successfully sent to a number
  VERIFICATION_CODE_SENT: "The verification code has been sent",
  // When the user deletes a signal
  DELETED_SIGNAL: "Signal successfully deleted",
}
