import React from 'react';
import { Input, Icon } from 'semantic-ui-react';

const InputWithCounter = ({ label, name, id, value, className, onChange, placeholder, maxCount }) => {

  return (
    <div className="input-with-counter">
      <Input
        id={id}
        label={label}
        name={name}
        className={className}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxCount}
      />
      {
        // <span>
        //   {value.length}/ {maxCount}
        // </span>
        //
        // {value.length >= maxCount ? (
        //   <span className="check">
        //     <Icon name="close" />
        //   </span>
        // ) : (
        //   ''
        // )}
      }
    </div>
  );
};

export default InputWithCounter;
