import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { goTo } from '../../routing';

class Unauthorized extends Component {
  constructor(props) {
    super(props);
    this.state = { timeout: null };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    // const timeout = setTimeout(() => {
    //   this.props.push('/');
    // }, 3000);
    // this.setState({ timeout });
  }

  componentWillUnmount() {
    // window.clearTimeout(this.state.timeout);
  }

  handleClick() {
    const { history } = this.props;
    goTo('/', history);
  }

  render() {
    return (
      <div className="app-card app-card-border">
        <h1>403</h1>
        <h3>Unauthorized</h3>
        <p>The page you're trying to access requires authorization</p>
        <Button onClick={this.handleClick}>Go To Login</Button>
        <p>Or we'll send you there after 3 seconds :) </p>
      </div>
    );
  }
}

export default Unauthorized;
