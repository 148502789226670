import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

const SignalFormToggleButton = ( { profileStore, name, label }) => {
  const handleChange = () => {
    profileStore.toggleFormInputsVisibility(name)
  }
  
  return (
    <Checkbox toggle checked={profileStore.formInputsVisibility[name]} onChange={ handleChange} label={label} />
  )
}

export default inject('profileStore')(observer(SignalFormToggleButton));