import React, { Component, Fragment } from 'react';
import { mapIndexToReadableTitle, mapReadableTitleToIndex } from '../../constants';
import { SearchItem, PeopleSearchView } from '../../components/Search';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { mergeSortedArrays } from '../../utilities';

class ResultsContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let children,
      extractedRecords = [];
    const { searchStore, addToFavourites } = this.props;
    const { searching, activeView, searchResults, allSearchResults } = searchStore;

    if (activeView == mapIndexToReadableTitle.dev_users.toLowerCase()) {
      let results;


      if (searchResults.hits) {
        results = searchResults;
      } else {
        results = allSearchResults.filter(e => e.index == mapReadableTitleToIndex[activeView])[0] || {};
      }
      children = <PeopleSearchView addToFavourites={addToFavourites} results={results} />
    } else if (activeView.toLowerCase() == mapIndexToReadableTitle.all.toLowerCase()) {
      for (const result of allSearchResults) {
        let hits = [];
        for (const hit of result.hits) {
          if (mapIndexToReadableTitle[result.index].toLowerCase() == 'people') {
            hits.push({ ...hit, type: mapIndexToReadableTitle[result.index] });
          } else {
            hits.push({ ...hit, type: mapIndexToReadableTitle[result.index], reference: true });
          }
        }
        extractedRecords = mergeSortedArrays(extractedRecords, hits);
      }
      children = extractedRecords.map((item, i) => <SearchItem {...item} key={i} addToFavourites={addToFavourites} />);
    } else {

      const viewResults = allSearchResults.filter(e => e.index == mapReadableTitleToIndex[activeView])[0] || {};

      children = viewResults.hits ? viewResults.hits.map((item, i) => {
        const newItem = { ...item, reference: true, type: activeView };
        return <SearchItem {...newItem} key={i} addToFavourites={addToFavourites} />;
      }) : ''
    }

    return (
      <div className="search-results">
        {children}
      </div>
    );
  }
}

export default inject('searchStore', 'favouriteStore')(observer(ResultsContainer));
