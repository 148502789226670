import config from './config';
import requests from './http';
const parseResponse = res => res.data;

const endpoint = `${config.getApiEndpoint()}/reference`

export default {
  getReferenceData(data) {
    return requests.put(`${endpoint}/`, data).then(parseResponse);
  },
  getFilteredReferenceData(data) {
    return requests.put(`${endpoint}/filter`, data).then(parseResponse);
  }
};
