import React, { Component } from 'react';
import Applicant from './Applicant'
import api from '../../api'
import { Container, Tab } from 'semantic-ui-react';
import '../../css/applications.css'
import { observer, inject } from 'mobx-react'
import { goTo } from '../../routing'

class Applications extends Component {
    constructor(props){
        super(props)
        this.state = {
            applications: [],
            acceptedApplications: []
        }
        this.admin = false;
    }

    componentWillMount(){
        const adminEmail = "joshua.vantard@gmail.com";
        if(this.props.authStore.currentUser.email !== adminEmail){
            return goTo('/', this.props.history)
        }
        this.admin = true
    }

    async componentDidMount(){
        if (this.admin){
            let result = await api.applications.getApplications()
            let applications = result.filter(item => item.isApplying === true)
            let acceptedApplications = result.filter(application => application.isApplying === false)
            this.setState(prevState => {
                return {
                    applications,
                    acceptedApplications
                }
            })
        }
    }

    render() {
        let { applications, acceptedApplications } = this.state
        let applicants = applications.map(application => (
            <Applicant key={application._id} {...application} />
        ))
        let acceptedApplicants = acceptedApplications.map(application => (
            <Applicant key={application._id} {...application} />
        ))
        const panes = [
            { menuItem: 'Current Applicants', render: () => <Tab.Pane>{applicants.length > 0 ? applicants : "There are no current applicants"}</Tab.Pane> },
            { menuItem: 'Approved applicants', render: () => <Tab.Pane>{acceptedApplicants.length > 0 ? acceptedApplicants : "There are no accepted applicants"}</Tab.Pane> },
          ]
          
        const ApplicationTab = () => <Tab menu={{ attached: false,  borderless: true }} panes={panes} />
        
        return (
            <Container className="applications-page" style={{marginTop: "50px"}}>
                <ApplicationTab />
            </Container>
        );
    }
}

export default inject('authStore')(observer(Applications))