import './semantic/dist/semantic.min.css';
import Notifications from 'react-notify-toast';
import { BrowserRouter } from 'react-router-dom';
import MainPage from './pages/MainPage';
import React, { Component } from 'react';
import { Provider } from 'mobx-react';
import stores from './stores';
import { ErrorBoundary } from './components/Utils';
import './App.css';
import './css/formTemplate.css'
import './css/fonts.css';
import './css/legal.css';
import 'react-phone-number-input/style.css'
import loadingStore from './stores/loadingStore';
import { loadCurrentRoute } from './routing';

// import './responsive.css';

class App extends Component {
  componentDidMount() {
    if (stores.authStore.loggedIn) {
      stores.profileStore.getUserData();
      stores.usertimelineStore.getMeetups();
    }
    
    window.addEventListener('popstate', function (e) {
       loadCurrentRoute();
    });

    loadCurrentRoute();
  }

  render() {
    return (
      <Provider {...stores}>
        <BrowserRouter>
          <ErrorBoundary>
            <Notifications />
            <MainPage />
          </ErrorBoundary>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
