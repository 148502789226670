import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Radio, Button } from 'semantic-ui-react';
import StudentDetails from '../../components/Forms/StudentDetails';
import WorkerDetails from '../../components/Forms/WorkerDetails';
import { hermes, runValidation } from '../../utilities';
import page4Schema from '../../schemas/page4Schema';
import { errors } from '../../messages';
import { goTo } from '../../routing';

function getSchema(type) {
  return type == 'student' ? page4Schema.studentDetails : page4Schema.workerDetails;
}

function restructureStudentData(data) {

  const payload = {
    university: data.selectedUniversity ? data.selectedUniversity._id : undefined,
    degreeType: data.degreeType,
    programme: data.programName,
    dates: {
      start: data.startYear,
      end: data.endYear
    }
  };
  return payload;
}

function restructureWorkerData(data) {

  const payload = {
    title: data.role,
    company: data.selectedCompany ? data.selectedCompany._id : undefined,
    startMonth: data.startMonth,
    startYear: data.startYear,
    endMonth: data.endMonth,
    endYear: data.endYear || 0, // hack
    currentlyWorkHere: data.currentlyWorkHere,
  };
  return payload;
}

function restructureData(option, data) {
  const returnValue =
    option == 'student'
      ? { universities: data.map(restructureStudentData) }
      : { companies: data.map(restructureWorkerData) };

  return returnValue;
}

class Page4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentOrRecentGrad: true,
      studentDetails: [],
      workerDetails: [],
      error: ''
    };

    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.handleViewChange = this.handleViewChange.bind(this);
    this.updateDetails = this.updateDetails.bind(this);
  }

  updateDetails(option) {
    return data => {
      if (option == 'student') {
        this.setState({ studentDetails: data });
      } else if (option == 'worker') {
        this.setState({ workerDetails: data });
      }
    };
  }

  handleViewChange() {
    const newValue = !this.state.studentOrRecentGrad;

    this.setState({
      studentOrRecentGrad: newValue,
    });
  }

  // this function is created to update the error state which would be passed down to the <WorkFormItem> component
  resetErrorState = () => {
    this.setState({error: ''})
  }

  resetErrorCheckedButton = () =>{
    const data = this.state.studentOrRecentGrad ? this.state.studentDetails : this.state.workerDetails;
    data.forEach(item => {
      if(item.endYear) {
        item.endYear = null
      }
    })
    return this.resetErrorState()
  }

  async saveAndContinue() {
    this.setState({error: ''})
    const { onboardingStore, history } = this.props;
    const option = this.state.studentOrRecentGrad ? 'student' : 'worker';
    const data = this.state.studentOrRecentGrad ? this.state.studentDetails : this.state.workerDetails;

    data.forEach(item => {
      if(item.endYear && item.endYear < item.startYear) {
        this.setState({error: errors.VALIDATE_DATES})
      }
    })

    if (option === 'student') {

      for (const education of data) {
        if (
          education.selectedUniversity &&
          education.selectedUniversity.name &&
          education.university.toLowerCase() !== education.selectedUniversity.name.toLowerCase()
        ) {
          return hermes.error(errors.ENTITY_NOT_SELECTED)
        }
      }
    } else {
      for (const experience of data) {
        if (
          experience.selectedCompany &&
          experience.selectedCompany.name &&
          experience.company.toLowerCase() !== experience.selectedCompany.name.toLowerCase()
        ) {
          return hermes.error(errors.ENTITY_NOT_SELECTED)
        }
      }
    }

    const payload = restructureData(option, data);
    const status = await runValidation(getSchema(option), payload);
    if (status.error) {
      return hermes.error(errors.VALIDATION_ERROR);
    }

    if (payload.companies) {
      payload.companies = payload.companies.map((data) => {
        data.dates = {
          start: {
            month: data.startMonth,
            year: data.startYear,
          },
          end: {
            month: data.endMonth,
            year: data.endYear
          },
          currentlyWorkHere: data.currentlyWorkHere,
        }

        delete data.startMonth;
        delete data.startYear;
        delete data.endMonth;
        delete data.endYear;
        delete data.currentlyWorkHere

        return data;
      })

    }
    try {
      if (this.state.error) {
         hermes.error(this.state.error)
      } else {
        await onboardingStore.savePage4Details(option, payload);
        onboardingStore.syncOnboardingPage(5);
        onboardingStore.updatePage("5")
        goTo('/onboarding/5', history);
      }
    } catch (e) {
      hermes.error(errors.DEFAULT);
    }
  }

  render() {
    const { studentOrRecentGrad } = this.state;
    let view;

    if (studentOrRecentGrad) {
      view = <StudentDetails updateParent={this.updateDetails('student')} />;
    } else {
      view = <WorkerDetails 
        resetError={this.resetErrorState}
        resetErrorCheckedButton={this.resetErrorCheckedButton}
         updateParent={this.updateDetails('worker')} />;
    }

    return (
      <div className="onboarding-4-container">
        <header>
          <div className="onboarding-heading">Create your profile</div>
          <p className="onboarding-sub-heading">Are you a student or recent graduate? </p>
          <div className="view-change">
            <Radio
              label="Yes"
              name="sorg"
              value="yes"
              checked={studentOrRecentGrad === true}
              onChange={this.handleViewChange}
            />
            <Radio
              label="No"
              name="sorg"
              value="no"
              checked={studentOrRecentGrad === false}
              onChange={this.handleViewChange}
            />
          </div>
        </header>

        <div className="view">{view}</div>

        <Button onClick={this.saveAndContinue} style={{marginTop: 15}} primary className="save-continue">
          Save & Continue
          </Button>
      </div>
    );
  }
}

export default withRouter(inject('authStore', 'onboardingStore')(observer(Page4)));
