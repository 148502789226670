import React, { Component, Fragment } from 'react';
import NewEducationEntry from './NewEducationEntry';
import { Button, Image, Card } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { DEFAULT_IMAGES } from '../../constants'
import LinkWithLoader from '../LinkWithLoader';

// const startYearComponent = startYear => {
//   if (startYear) {
//     return (
//       <Fragment>
//         <span className="start">{startYear}</span>
//         <span>-</span>
//       </Fragment>
//     );
//   } else {
//     return null;
//   }
// };

const YearComponent = (startYear, endYear) => {
  if (startYear == endYear) {
    return (
      <Fragment>
        <span className="start">{startYear}</span>
      </Fragment>
    );
  } else if (startYear && endYear) {
    return (
      <Fragment>
        <span className="start">{startYear}</span>
        <span className="date-divider">-</span>
        <span className="end">{endYear}</span>
      </Fragment>
    );
  } else if (endYear) {
    return <span>{endYear}</span>;
  } else if (startYear) {
    return <span>{startYear}</span>;
  }
};

const EducationView = ({ otherUser, details, removeEducation, toggleEdit }) => {
  let startYear, endYear;

  startYear = details.dates ? details.dates.start : null;
  endYear = details.dates ? details.dates.end : null;

  return (
    <Card>
      <div className="education-wrapper">

        <div className="header">
          <Image
            width="80"
            height="80"
            src={DEFAULT_IMAGES.University}
          />
          <div className="wrapper">
            <div className="details ">
              <div className="name">
                <LinkWithLoader to={`/university/${details.university.slug}`}>
                  {details.university.name}
                </LinkWithLoader>
              </div>
              <div className="info">
                <div className="programme">
                  {details.degreeType !== 'false' ? details.degreeType == 'Other' && details.programme && details.programme != 'false' ? 'Other |' : details.degreeType : ''} {details.programme !== 'false' ? details.programme : ''}
                </div>
                <div className="dates">{YearComponent(startYear, endYear)}</div>
              </div>
              <div className="description">{details.description}</div>
            </div>
            {otherUser ? null : (
              <div className="actions">
                <Button basic className="first" onClick={toggleEdit}>
                  Edit
                </Button>
                <Button
                  onClick={() => {
                    removeEducation(details._id);
                  }}
                  basic
                >
                  Remove
              </Button>
              </div>
            )}
          </div>
        </div>

      </div>
    </Card>
  );
};

class Education extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };

    this.toggleEdit = this.toggleEdit.bind(this);
    this.removeEducation = this.removeEducation.bind(this);
  }

  toggleEdit() {
    this.setState({ editing: !this.state.editing });
  }

  removeEducation(id) {
    const { profileStore } = this.props;
    profileStore.deleteEducation(id);
  }

  render() {
    const { editing } = this.state;
    const { details, profileStore } = this.props;

    if (profileStore.inProgress == false) {
    }

    if (editing) {
      // we pass existing data to the editing component
      return <NewEducationEntry isSaving={this.props.inProgress} previousData={details} toggleState={this.toggleEdit} />;
    } else {
      // just render
      return <EducationView {...this.props} toggleEdit={this.toggleEdit} removeEducation={this.removeEducation} />;
    }
  }
}

export default inject('profileStore')(observer(Education));
