import config from './config';
import requests from './http';
const parseResponse = res => res.data;

const endpoint = `${config.getApiEndpoint()}/user`;

export default {
  login(data) {
    return requests.post(`${endpoint}/login`, data).then(parseResponse);
  },
  apply(data) {
    return requests.post(`${endpoint}/apply`, data).then(parseResponse);
  },
  signUp(data) {
    return requests.post(endpoint, data).then(parseResponse);
  },
  updateName(data) {
    return requests.put(endpoint, data).then(parseResponse);
  },
  updateFacebookData(data) {
    return requests.put(endpoint, data).then(parseResponse);
  },
  saveExperience(data) {
    return requests.put(`${endpoint}/experience`, data).then(parseResponse);
  },
  updateApplicant(data) {
    return requests.put(`${endpoint}/apply`, data).then(parseResponse);
  },
  updateBio(data) {
    return requests.put(`${endpoint}/bio`, data).then(parseResponse);
  },
  uploadImage(data) {
    return requests
      .put(`${endpoint}/image`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(parseResponse);
  },
  uploadFBImage(data) {
    return requests.put(`${endpoint}/fbimage`, data).then(parseResponse);
  },
  changeEmail(data) {
    return requests.put(`${endpoint}/email`, data).then(parseResponse);
  },
  changePassword(data) {
    return requests.put(`${endpoint}/password`, data).then(parseResponse);
  },
  getUserData() {
    return requests.get(`${endpoint}`).then(parseResponse);
  },
  saveEducation(data) {
    return requests.put(`${endpoint}/education`, data).then(parseResponse);
  },
  updateEducation(id, data) {
    return requests.put(`${endpoint}/education/${id}`, data).then(parseResponse);
  },
  deleteEducation(id) {
    return requests.delete(`${endpoint}/education/${id}`).then(parseResponse);
  },
  deleteExperience(id) {
    return requests.delete(`${endpoint}/experience/${id}`).then(parseResponse);
  },
  updateExperience(id, data) {
    return requests.put(`${endpoint}/experience/${id}`, data).then(parseResponse);
  },
  getUserBySlug(slug) {
    return requests.get(`${endpoint}/${slug}`).then(parseResponse);
  },
  searchForUers(type, value) {
    return requests.get(`${endpoint}/search?filterType=${type}&filterValue=${value}`).then(parseResponse);
  },
  verifyPassword(data) {
    return requests.post(`${endpoint}/verifyPassword`, data).then(parseResponse);
  },
  updateSubscriptions(data) {
    return requests.put(`${endpoint}/subscriptions`, data).then(parseResponse);
  },
  getSubscriptionFeed({ offset, limit }) {
    if (!offset && !limit) {
      return requests.get(`${endpoint}/feed?type=subscriptions`);
    } else {
      let offsetString = '',
        limitString = '';
      if (offset) {
        offsetString = `offset=${offset}`;
      }
      if (limit) {
        limitString = `limit=${limit}`;
      }
      return requests.get(`${endpoint}/feed?type=subscriptions&${offsetString}&${limitString}`);
    }
  },
  getApplicationWideFeed({ offset, limit }) {
    if (!offset && !limit) {
      return requests.get(`${endpoint}/feed`);
    } else {
      let offsetString = '',
        limitString = '';
      if (offset) {
        offsetString = `offset=${offset}`;
      }
      if (limit) {
        limitString = `limit=${limit}`;
      }
      return requests.get(`${endpoint}/feed?${offsetString}&${limitString}`);
    }
  },
  sendPasswordResetLink(data) {
    return requests.post(`${endpoint}/forgot`, data).then(parseResponse);
  },
  resendVerificationEmail() {
    return requests.post(`${endpoint}/resend-verification-email`, {}).then(parseResponse);
  },
  verifyEmail(data) {
    return requests.post(`${endpoint}/verify`, data).then(parseResponse);
  },
  verifyMembership(data) {
    return requests.post(`${endpoint}/isMember`, data).then(parseResponse);
  },
  verifyFbID(data) {
    return requests.put(`${endpoint}/verify-fbid`, data).then(parseResponse);
  },
  getMeetups(data) {
    return requests.get(`${endpoint}/meetup`).then(parseResponse);
  },
  checkForClaimableData(name) {
    return requests.put(`${endpoint}/checkData`, { name }).then(parseResponse);
  },
  claimData(name) {
    return requests.post(`${endpoint}/claimData`, { name }).then(parseResponse)
  },
  verifyPasswordToken(data) {
    return requests.post(`${endpoint}/verify-password-token`, data).then(parseResponse);
  },
  resetPassword(data) {
    return requests.post(`${endpoint}/reset-password`, data).then(parseResponse);
  },
  updateSignUpPage(page) {
    return requests.put(`${endpoint}/update-signup-page`, { page }).then(parseResponse);
  },
  reorderExperiences(experiences) {
    return requests.put(`${endpoint}/reorder-experiences`, experiences).then(parseResponse);
  },
  reorderEducations(educations) {
    return requests.put(`${endpoint}/reorder-educations`, educations).then(parseResponse);
  },
  getCallSignal() {
    return requests.get(`${endpoint}/call-signal`).then(parseResponse);
  },
  sendSignals(data) {
    return requests.put(`${endpoint}/signal`, data).then(parseResponse);
  },
  sendVerificationCode(data) {
    return requests.put(`${endpoint}/send-verification-code`, data).then(parseResponse);
  },
  verifyCode(data) {
    return requests.put(`${endpoint}/verify-code`, data).then(parseResponse).catch(e => new Error(e));
  },
  deleteSignal(_id) {
    return requests.delete(`${endpoint}/signal/${_id}`).then(parseResponse);
  }
};
