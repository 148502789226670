import api from './api'

const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_PUBLIC_VAPID_KEY)

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
   
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
   
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

function sendSubscription(subscription) {
    api.notifications.sendNotificationSubscription(subscription)
}

export function subscribeUser() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(function(registration) {
        if (!registration.pushManager) {
            //The browser does not support push notification
          console.log('Push manager unavailable.')
          return
        }
  
        registration.pushManager.getSubscription().then(function(existedSubscription) {
          if (existedSubscription === null) {
              //There has been no subscription, request the user's permission to display notifications
            //Creating a new subscription
            registration.pushManager.subscribe({
              applicationServerKey: convertedVapidKey,
              userVisibleOnly: true,
            }).then(function(newSubscription) {
              sendSubscription(newSubscription)
            }).catch(function(e) {
              if (Notification.permission !== 'granted') {
                  //The user did not grant permission to display notifications
              } else {
                console.error('An error ocurred during the subscription process.', e)
              }
            })
          } else {
            sendSubscription(existedSubscription)
          }
        })
      })
        .catch(function(e) {
          console.error('An error ocurred during Service Worker registration.', e)
        })
    }
  }