import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { goTo } from '../../routing';

class Page3 extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleChange(value) {
    const { onboardingStore } = this.props;
    onboardingStore.syncLocationWithStorage(value);
  }

  handleSelect(value) {
    const { onboardingStore } = this.props;
    onboardingStore.syncLocation(value);
  }

  componentDidMount() {
    const { onboardingStore, history } = this.props;
    onboardingStore.syncOnboardingPage(4);
    onboardingStore.updatePage("4");
    goTo('/onboarding/4', history);
  }

  render() {
    // const {onboardingStore, authStore} = this.props;
    // const {selectedLocation} = onboardingStore;
    return (
      <div>
        Page 3<h1>What Are You Interested In</h1>
      </div>
    );
  }
}

export default withRouter(inject('authStore', 'onboardingStore')(observer(Page3)));
