import React from 'react';
import '../../css/progressBar.css';
import { observer, inject } from 'mobx-react'


function ProgressBar({ loadingStore }) {
  const {pb_loading, pb_loaded} = loadingStore;
  let className;

  if (pb_loading) {
    className = 'loading';
    console.log('hi');
  } else if (pb_loaded) {
    className = 'loaded';
    console.log('bye');
  }

  return <div className={'progress-bar' + ' ' + (className || '')}></div>;
}


export default inject('loadingStore')(observer(ProgressBar));
