import React, { Component, Fragment } from 'react';
import { Input, Label } from 'semantic-ui-react';
import PlacesAutocomplete from 'react-places-autocomplete';
import api from '../../api';
import { handleError } from '../../utilities';
import { inject, observer } from 'mobx-react';

const SelectedEntity = ({ name, remove, labelName, className }) => {
  return (
    <Label className={(className ? className + ' ' : '') + "bubbleInput entity-label"} basic>
      {labelName ? labelName + ": " : null}{name}
      <div
        onClick={remove}
        className="remove"
      >
        <span>x</span>
      </div>
    </Label>
  );
};

export class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      address: '',
      errorDisplay: 'none',
      value: '',
      valueSet: false,
      additionalSuggestions: []
    };
    this.track = this.track.bind(this);
    this.update = this.update.bind(this);
  }

  track(value) {
    this.update(value)
    return value;
  }

  async update(value) {
    const { loadingStore } = this.props;
    if (loadingStore.loading) {
      return
    }

    if (!this.state.valueSet) {
      this.setState({ value, valueSet: true });
      return
    }

    if (value && value != this.state.value) {
      this.setState({ value });
      loadingStore.setLoading();
      try {
        const data = await api.location.searchForLocation(value);
        // loadingStore.setLoaded()
        this.setState({
          additionalSuggestions: data
        })
      } catch (error) {
        throw handleError(error);
      } finally {
        loadingStore.setLoaded()
      }
      // this.setState({
      //   additionalSuggestions: results.facetHits
      // })
    }

  }

  getAdditionals() {
    return this.state.additionalSuggestions.map((result, index) => ({
      ...result,
      id: result._id,
      count: result.userCount,
      description: result.name,
      index
    }))

  }

  render() {
    const { name, value, handleChange, handleSelect, handleRemove, placeholder, className, selectedEntity, labelName } = this.props;
    return selectedEntity ?  
    <SelectedEntity 
      className={className} 
      name={selectedEntity} 
      labelName={labelName} 
      remove={handleRemove}/>
       : (
      <div className="location-form" data-testid="location-form">
        {window.google && (
          <PlacesAutocomplete 
            value={this.track(value)} 
            onChange={handleChange}
             onSelect={handleSelect}>
            {({ getInputProps, suggestions, getSuggestionItemProps }) => {
              if (suggestions.length) {
                const additionals = this.getAdditionals();
                suggestions = additionals.concat(suggestions).slice(0, 4);
              }

              return (
                <Fragment>
                  <Input
                    // icon="search"
                    {...getInputProps({
                      name: name,
                      type: 'text',
                      placeholder: placeholder || 'Enter a city',
                      className: (className ? className + ' ' : '') + 'location-search-input',
                    })}
                  />
                  <div
                    className={'autocomplete-dropdown-container results'}
                  >
                    {suggestions.map(suggestion => {
                      // const className = suggestion.active
                      //   ? 'suggestion-item label-dropdown-wrapper'
                      //   : 'suggestion-item label-dropdown-wrapper';

                      const className = 'suggestion-item result';


                      return (
                        <div {...getSuggestionItemProps(suggestion, {
                          className,

                        })}>
                          <div className="menu-item-swa-wrapper">
                            <div className="name">
                              {suggestion.description}
                            </div>
                            {!suggestion.count ? (
                              ''
                            ) : (
                                <div className="count">
                                  {suggestion.count} {suggestion.count == 1 ? 'member' : 'members'}
                                </div>
                              )}
                          </div>
                        </div>

                      );
                    })}
                  </div>
                </Fragment>
              )
            }}

          </PlacesAutocomplete>
        )}
      </div>
    );
  } 
}

export default inject('loadingStore')(observer(Location));
