import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import SearchWithAddition from '../Forms/SearchWithAddition';
import { Checkbox, Button, Label } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

class DegreeTypePeopleFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: '',
      searchValue: '',
      filters: [],
      results: [],
      isOpen: false
    };
    this.applyFilter = this.applyFilter.bind(this);
    this.addOrRemoveFilter = this.addOrRemoveFilter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  async addOrRemoveFilter(e, { label, checked }) {
    const { updateSelected, index } = this.props;

    updateSelected(index, label, checked)

    let filters = this.state.filters;
    if (checked) {
      await this.setState({
        filters: [...filters, label],
      });
    } else {
      filters = filters.filter(item => item != label);
      await this.setState({ filters });
    }
  }

  componentDidMount() {
    const { selectedValues, index } = this.props;
    this.setState({ filters: selectedValues[index].filter(item => item.checked).map(item => item.label) });
  }

  async handleSelect(e, { result }) {
    const { searchStore, index } = this.props;

    // console.log(result.name);
    await this.setState({
      searchValue: '',
      selectedValue: result.name,
      isOpen: false
    });

    this.props.addToSelected(index, result.name)

    this.setState({
      filters: [...this.state.filters, result.name],
    });

    searchStore.selectFilterEntityValue(index, result.name);
  }

  async handleChange(e, { value }) {
    await this.setState({
      searchValue: value,
      isOpen: true
    });
  }

  async onFocus() {
    await this.setState({
      isOpen: true
    })
  }

  async onBlur() {
    setTimeout(
      () => this.setState({
        isOpen: false
      }), 500)
  }

  applyFilter() {
    const { searchStore, index, history } = this.props;
    const { filters } = this.state;
    searchStore.updateActiveIndex(index);
    searchStore.updateFilters(filters, history);
    searchStore.makeFilteredSearch();
    this.props.updateFilterCount(searchStore.selectedFilters[index]);
  }

  render() {
    const { placeholder, searchStore, selectedValues, index } = this.props;
    const { searchValue } = this.state;

    const re = new RegExp(_.escapeRegExp(searchValue), 'i')
    const isMatch = result => re.test(result.title);

    const filteredResults = _.filter(searchStore.degreeTypeFilters, isMatch);

    const results = filteredResults.length ? filteredResults : [];

    return (
      <Fragment>
        <div className="people-filter">
          <SearchWithAddition
            icon="dropdown"
            results={results}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            open={this.state.isOpen}
            placeholder={placeholder}
            value={searchValue}
            addNewValue={e => { }}
            noResultsMessage={<p>No Results Found</p>}
          />

          <div className="mini-menu">
            <div className="checkbox-wrapper">
              {selectedValues[index].length > 0
                ? selectedValues[index].map((item, index) => {
                  // console.log(item);
                  return (
                    <li key={index}>
                      <Checkbox label={item.label} onChange={this.addOrRemoveFilter} checked={item.checked} />
                    </li>
                  );
                })
                : null}
            </div>
            <Button onClick={this.applyFilter} primary>
              Apply
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(inject('searchStore')(observer(DegreeTypePeopleFilter)));
