import config from './config';
import requests from './http';
const constants = require('../constants');
const parseResponse = res => res.data;

const endpoint = `${config.getApiEndpoint()}/search`;

const api = {
  search(data) {
    return requests.post(endpoint, data).then(parseResponse);
  },
  searchForFacetValues(data) {
    return requests.post(`${endpoint}/facets`, data).then(parseResponse);
  },
  searchMultipleIndexes(data) {
    return requests.post(`${endpoint}/multiple`, data).then(parseResponse);
  }
};

export function initIndex(index) {
  return {
    search(...args) {
      return api.search({ index, args })
    },
    searchForFacetValues(...args) {
      return api.searchForFacetValues({ index, args })
    }
  }
}

export function searchMultipleIndexes(...args) {
  return api.searchMultipleIndexes({ args });
}

export const userIndex = initIndex(constants.ALGOLIA_USER_INDEX);
export const locationIndex = initIndex(constants.ALGOLIA_LOCATION_INDEX);
export const universityIndex = initIndex(constants.ALGOLIA_UNIVERSITY_INDEX);
export const companyIndex = initIndex(constants.ALGOLIA_COMPANY_INDEX);
export const skillIndex = initIndex(constants.ALGOLIA_SKILL_INDEX);

export default {
  userIndex,
  locationIndex,
  universityIndex,
  companyIndex,
  skillIndex,
};
