import React, { Component, Fragment } from 'react';
import FacebookVerification from '../../components/Auth/FacebookVerification';
import { Form, Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import authStore from '../../stores/authStore';
import { hermes, getFirstName, storage } from '../../utilities';
import { withFormik, ErrorMessage } from 'formik';
import onePointThreeSchema from '../../schemas/pageOnePointThree';
import { errors } from '../../messages';
import { goTo } from '../../routing';
import ApplyPage1 from './ApplyPage1';
import FacebookButton from '../../components/Auth/Facebook';
import checkForIcognitoOnFirefox from './script';

const { Input, Field } = Form;

const OnePointOne = ({ handleSuccess, handleFailure, pageNum, inProgress, authStore }) => {
  if (pageNum === 1.1 && authStore.currentUser) {
    // auth.currentUser to prevent autologin bug
    return (
      <Fragment>
        <div className="onboarding-wrapper">
          <div className="onboarding-heading ">
            Welcome to Intelligent Crazy People, {getFirstName(authStore.currentUser.name)}
          </div>
          <div className="onboarding-sub-heading">Let’s confirm your identity with Facebook</div>

          <div className="onboarding-column">
            {/* <div className="onboarding-form-wrapper button-wrapper"> */}
            {/* check if user is on firefox icognito mode */}
            {checkForIcognitoOnFirefox() ? <FacebookButton 
              handleFailure={handleFailure}
              handleSuccess={handleSuccess}
            /> : <FacebookVerification 
                      handleSuccess={handleSuccess} 
                      handleFailure={handleFailure} 
              /> }
            {/* </div> */}
          </div>
        </div>
      </Fragment>
    );
  } else {
    return null;
  }
};

const OnePointTwo = ({ pageNum }) => {
  if (pageNum === 1.2) {
    return (
      <Fragment>
        <h1>Page 1.2</h1>
        <h3>Yet to figure out what to put here</h3>
      </Fragment>
    );
  } else {
    return null;
  }
};

const OnePointThree = props => {
  const { values, handleChange, handleBlur, handleSubmit, verifyName, pageNum, inProgress, authStore } = props;
  console.log(handleSubmit);
  if (pageNum === 1.3) {
    return (
      <Fragment>
        <div className="onboarding-wrapper">
          <div className="onboarding-heading">
            Let's confirm your identity {getFirstName(authStore.currentUser.name)}
          </div>
          <div className="onboarding-sub-heading">Your signup name isn’t the same as your Facebook name</div>
          <div className="onboarding-column">
            <Form onSubmit={handleSubmit} className="onboarding-form-wrapper withoutbg" autoComplete="off">
              <Field>
                <Input
                  // required
                  placeholder="Full Name"
                  type="text"
                  name="name"
                  autoComplete="new-password"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {/* <ErrorMessage name="name" /> */}
              </Field>
              <div className="button-wrapper">
                <Button type="submit" primary>
                  Save & Continue
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Fragment>
    );
  } else {
    return null;
  }
};

const OnePointFive = ({ pageNum, handleSubmit }) => {
  console.log(pageNum, 'pageNum');
  if (pageNum === 1.5) {
    return <ApplyPage1 handleSubmit={handleSubmit} />;
  }
  return null;
};

const EnhancedOnePointThree = withFormik({
  mapPropsToValues: props => {
    return {
      name: '',
    };
  },
  validationSchema: onePointThreeSchema,
  handleSubmit: (values, bag) => {
    bag.props.verifyName(values);
  },

  displayName: 'OnePointThree',
})(OnePointThree);

const OnePointFour = ({ pageNum, claimData, inProgress, authStore }) => {
  if (pageNum === 1.4) {
    return (
      <Fragment>
        <div className="onboarding-wrapper ">
          <div className="onboarding-heading">
            We’ve already completed your profile, {getFirstName(authStore.currentUser.name)}
          </div>
          <div className="onboarding-sub-heading">
            When you signed up to our group, you agreed to having your resumé in our directory. We kept your data safe
            for you. Click below to take control of it.
          </div>
          <div className="onboarding-column">
            <div className="button-wrapper" style={{ background: 'none', boxShadow: 'none' }}>
              <Button onClick={claimData} primary>
                Claim Profile
              </Button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  } else {
    return null;
  }
};

class Page1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verificationDetails: {
        lastName: '',
        firstName: '',
      },
    };

    this.verifyName = this.verifyName.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleFailure = this.handleFailure.bind(this);
    this.claimData = this.claimData.bind(this);
  }

  getPageNum = async () => {
    const { profileStore } = this.props;

    while (profileStore.userData == null) {
      await new Promise(resolve => setTimeout(resolve, 1000))
    }
    return parseFloat(profileStore.userData.signUpPage);
  };

  async componentDidMount() {
    try {
      const pageNum = await this.getPageNum();
      this.setState({ pageNum });
    } catch (e) {
      console.error(e);
      hermes.error(errors.DEFAULT);
    }
  }

  handleFailure(data) {
    console.log(data);
  }

  async verifyName({ name }) {
    const {
      authStore: { facebookDetails },
      onboardingStore,
      history,
    } = this.props;
    const facebookName = facebookDetails.name.trim();
    const signupName = name.trim();

    try {
      // const userExists = await authStore.verifyFBIDForUniqueness(facebookDetails.id);
      const userExists = false;
      if (userExists) {
        // show modal or something, can only create one account
        hermes.error(errors.FACEBOOK_ACCOUNT_REGISTERED);
        return;
      }
    } catch (error) {
      hermes.error(errors.DEFAULT);
      return;
    }

    if (facebookName.toLowerCase() == signupName.toLowerCase()) {
      try {
        // bunch of the the first 2 request below into one.
        // they literally go to the same endpoint.
        await onboardingStore.updateFacebookData(facebookDetails);

        await onboardingStore.updateName(facebookName);

        if (authStore.currentUser.isApplying) {
          this.setState({
            pageNum: 1.5,
          });
          onboardingStore.updatePage('1.5');
        } else {
          const response = await onboardingStore.checkForClaimableData(facebookName);

          if (!response) {
            onboardingStore.syncOnboardingPage(2);
            onboardingStore.updatePage('2');
            goTo('/onboarding/2', history);
          } else {
            // we use this to compute progress bar level
            onboardingStore.updatePage('1.4');
            await this.setState({
              pageNum: 1.4,
            });
          }
        }
      } catch (error) {
        hermes.error(error);
      }
    } else {
      hermes.error(errors.DIFERENT_NAMES_SIGNUP);
    }
  }

  async claimData() {
    const { authStore, onboardingStore, profileStore, usertimelineStore, subscriptionStore, history } = this.props;
    const newUserAuthData = await onboardingStore.claimData(authStore.currentUser.name);
    authStore.resetValues();
    storage.clearStorage();
    authStore.syncUser(newUserAuthData);
    authStore.syncToken(newUserAuthData.token);
    profileStore.getUserData();

    //Go to update location form
    onboardingStore.updatePage('2');
    goTo('/onboarding/2', history);
  }

  async handleSuccess(data) {
    const { authStore, onboardingStore, history } = this.props;
    const facebookName = data.name.trim();
    const signupName = authStore.currentUser.name.trim();
    const successToken = history.location.search.code;

    try {
      // const userExists = await authStore.verifyFBIDForUniqueness(data.id);
      const userExists = false;
      if (userExists) {
        // show modal or something, can only create one account
        hermes.error(errors.FACEBOOK_ACCOUNT_REGISTERED);
        return;
      }
    } catch (error) {
      hermes.error(errors.DEFAULT);
      return;
    }

    // else {
    //   await authStore.updateFacebookData(data);
    // }

    if (facebookName.toLowerCase() === signupName.toLowerCase()) {
      authStore.syncFBData({ ...data, successToken });
      try {
        await onboardingStore.updateFacebookData(data);
        if (authStore.currentUser.isApplying) {
          this.setState({
            pageNum: 1.5,
          });
          onboardingStore.updatePage('1.5');
        } else {
          let response = await onboardingStore.checkForClaimableData(facebookName);

          if (!response) {
            goTo('/onboarding/2', history);
            onboardingStore.updatePage('2');
          } else {
            this.setState({
              pageNum: 1.4,
            });
            onboardingStore.updatePage('1.4');
          }
        }
      } catch (error) {
        hermes.error(error);
      }
    } else {
      // if names dont match, show input boxes for user to enter correct name
      await this.setState({ pageNum: 1.3 });
      onboardingStore.updatePage('1.3');
      authStore.syncFBData({ ...data, successToken });
    }
  }

  submitApplication = async data => {
    const { profileStore, onboardingStore, history } = this.props;

    try {
      await profileStore.updateApplicant(data);
      //Go to enter location
      goTo('/onboarding/2', history);
      onboardingStore.updatePage('2');
    } catch (e) {
      hermes.error(e);
    }
  };

  render() {
    // const pageNum = 1;
    const { pageNum } = this.state;
    const {
      authStore: { inProgress },
      onboardingStore: { inProgress: onboardinStoreInprogress },
    } = this.props;
    return (
      <Fragment>
        <OnePointOne
          pageNum={pageNum}
          handleSuccess={this.handleSuccess}
          handleFailure={this.handleFailure}
          inProgress={inProgress}
          authStore={authStore}
        />
        <OnePointTwo pageNum={pageNum} />
        <EnhancedOnePointThree
          pageNum={pageNum}
          verifyName={this.verifyName}
          inProgress={onboardinStoreInprogress}
          authStore={authStore}
        />
        <OnePointFour pageNum={pageNum} claimData={this.claimData} inProgress={inProgress} authStore={authStore} />
        <OnePointFive pageNum={pageNum} handleSubmit={this.submitApplication} />
      </Fragment>
    );
  }
}

export default withRouter(
  inject('authStore', 'onboardingStore', 'profileStore', 'usertimelineStore', 'subscriptionStore')(observer(Page1)),
);
