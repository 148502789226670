import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

const SubscriptionFormToggleItem = ({ entity, subscriptionStore }) => {
  const handleChange = () => {
    subscriptionStore.toggleValue(entity.subtype)
  }

  return (
    <div className="form-section">
      <Checkbox toggle checked={entity.value} onChange={handleChange} label={entity.title} />
    </div>
  )
}

export default inject('subscriptionStore')(observer(SubscriptionFormToggleItem));