import React, { Component } from 'react';
import { withFormik } from 'formik';
import { withRouter } from 'react-router';
import { Container, Header, Form, Input, Segment, Dropdown, Button, TextArea } from 'semantic-ui-react';
import { months } from '../../constants';
import { hermes } from '../../utilities';
import schema from '../../schemas/hostMeetupSchema';
import { errors, success } from '../../messages';
import api from '../../api';
import LinkWithLoader from '../../components/LinkWithLoader';

function validadeDate(month, day) {
  let year, now = new Date();

  if (month > now.getMonth()) {
    year = now.getFullYear();
  } else if (month == now.getMonth() && day >= now.getDate()) {
    year = now.getFullYear();
  } else {
    year = now.getFullYear() + 1;
  }

  return day <= new Date(year, month, 0).getDate();
}


class HostMeetupForm extends Component {
  constructor(props) {
    super(props);

    this.days = []

    for (let i = 1; i <= 31; i++) {
      this.days.push({ text: i, value: i })
    }

    this.months = months.map((month, i) => {
      return {
        text: month,
        value: i + 1
      }
    });
  }

  componentDidMount() {
    window.ga('set', {
      page: '/host-a-meetup',
      title: 'Host a Meetup'
    });
    window.ga('send', 'pageview');
  }


  render() {
    const { values, handleSubmit, handleChange, setFieldValue } = this.props;
    const { name, month, day, url, start, end, address, description, booked } = values;

    return (
      <div className="form-template-wrapper">
        <Container fluid className="form-template host-a-meetup">
          <Header inverted>Host a Meetup</Header>
          <Segment>
            <Form onSubmit={handleSubmit} autoComplete="off">
              <div className="form-section">
                <Form.Field>
                  <label>Event name</label>
                  <Input
                    name="name"
                    value={name}
                    onChange={handleChange}
                    placeholder="Ex: Dinner at Helen and Matt's"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Event link</label>
                  <Input
                    name="url"
                    value={url}
                    onChange={handleChange}
                    placeholder="Ex: facebook.com/events/646954632384488"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Day</label>
                  <Dropdown
                    name="day"
                    search
                    selection
                    value={day}
                    placeholder="Ex: 14"
                    onChange={(e, { name, value }) => setFieldValue(name, value)} options={this.days}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Month</label>
                  <Dropdown
                    name="month"
                    search
                    selection
                    value={month}
                    placeholder="Ex: June"
                    onChange={(e, { name, value }) => setFieldValue(name, value)} options={this.months}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Start time</label>
                  <Input
                    name="start"
                    value={start}
                    onChange={handleChange}
                    placeholder="Ex: 17:00"
                  />
                </Form.Field>
                <Form.Field>
                  <label>End time</label>
                  <Input
                    name="end"
                    value={end}
                    onChange={handleChange}
                    placeholder="Ex: 21:00"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Address</label>
                  <Input
                    name="address"
                    value={address}
                    onChange={handleChange}
                    placeholder="Ex: Apartment 100, 501 5th Avenue, New York"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Meet-up description</label>
                  <TextArea
                    name="description"
                    value={description}
                    onChange={handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label><a style={{ textDecoration: 'underline' }} href="https://calendly.com/joshuavantard/host-meetup?back=1&month=2019-06">Book a Calendly appointment</a>. Have you done so?</label>
                  <Form.Group>
                    <Form.Radio
                      label='Yes'
                      value='y'
                      checked={booked}
                      onChange={() => setFieldValue('booked', true)}
                    />
                    <Form.Radio
                      label='No'
                      checked={!booked}
                      onChange={(e, { checked }) => setFieldValue('booked', false)}
                    />
                  </Form.Group>
                </Form.Field>
              </div>

              <Button primary type="submit">Save</Button>
              <LinkWithLoader to='/' secondary button>Cancel</LinkWithLoader>
            </Form>
          </Segment>
        </Container>
      </div >
    )
  }
}

export default withRouter(withFormik({
  mapPropsToValues: () => ({
    name: '',
    url: '',
    start: '',
    end: '',
    address: '',
    description: '',
    booked: false
  }),

  // Custom sync validation
  validate: async values => {
    const error = await schema.validate(values).then(() => false).catch(err => {
      hermes.error(err.errors[0]);
      return err
    })
    if (error) {
      throw error
    }

    if (!validadeDate(values.month, values.day)) {
      hermes.error(errors.INVALID_MEETUP_DATE);
      throw "Invalid Date"
    }

    return {}
  },

  handleSubmit: async (values, { setSubmitting }) => {
    try {
      await api.meetup.postMeetup(values);
      hermes.success(success.MEETUP_SENT);
      window.mh.push('/');
    } catch (e) {
      console.log(e);
      hermes.error(errors.DEFAULT)
    }
    setSubmitting(false);
  },

  validateOnBlur: false,
  validateOnChange: false

})(HostMeetupForm));
