import React, { Component, Fragment } from 'react';
import { Menu, Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import LinkWithLoader from '../LinkWithLoader';

class UserFeedMenu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      activeView,
      changeActiveView,
      subscriptionsModalOpened,
      openSubscriptionModal,
      closeSubscriptionModal,
    } = this.props;

    return (
      <Menu secondary pointing className="left">
        <Menu.Item name="all" active={activeView === 'all'} onClick={changeActiveView}>
          All Signals
        </Menu.Item>
        <Menu.Item name="feed" active={activeView === 'feed'} onClick={changeActiveView}>
          Listening
        </Menu.Item>
        <div className="menu-buttons">
          <LinkWithLoader primary button className="tablet-and-up" to='/signal'>
            Signal
          </LinkWithLoader>
          <LinkWithLoader primary button className="tablet-and-up" to='/subscribe'>
            Listen
          </LinkWithLoader>
        </div>
      </Menu>

    );
  }
}

export default inject('subscriptionStore')(observer(UserFeedMenu));
