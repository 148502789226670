import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Form, Button, Card, Label, Input } from 'semantic-ui-react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { errors, success } from '../../messages';
import { hermes } from '../../utilities';
import { goTo } from '../../routing';
import { withRouter } from 'react-router-dom';

class Page6 extends Component{
    constructor(props){
        super(props)
        this.state = {
            view: 'ENTER_NUMBER',
            cooode: '',
        }
        this.NUMBER_VIEW = 'ENTER_NUMBER'
        this.CODE_VIEW = 'ENTER_CODE'
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    sendVerificationCode = async (e) => {
        e.preventDefault()
        const { phone } = this.state;

         //Validate phone number
        if (isValidPhoneNumber(phone)) {
            const { profileStore } = this.props;
            try {
                //Send the verification code
                await profileStore.sendVerificationCode(this.state.phone)
                hermes.success(success.VERIFICATION_CODE_SENT)

                //Set the view to the verify code view
                this.setState({
                    view: this.CODE_VIEW
                })
            } catch (e) {
                hermes.error(e);
                return
            }
          } else {
            hermes.error(errors.INVALID_PHONE_NUMBER)
          }
    }

    verifyPhoneNumber = async (e) => {
        e.preventDefault()
        const { phone, cooode } = this.state;
        const { profileStore, authStore, onboardingStore, usertimelineStore, history } = this.props;

        if (cooode.length !== 6) {
            hermes.error(errors.INVALID_VERIFICATION_CODE_LENGTH);
            return
          }
        try {
            await profileStore.verifyCode(phone, cooode)
            hermes.success(success.PHONE_VERIFIED) 
            
            //Send the user to application is being considered or feed screen
            await onboardingStore.updatePage("")
            if (!profileStore.userData.isApplying) {
                profileStore.getUserData();
                usertimelineStore.getMeetups();
                authStore.logUserIntoApplication();
              } else {
                goTo('/onboarding/7', history);
              }
        } catch (e) {
            hermes.error(errors.INVALID_VERIFICATION_CODE)
        }
    }

    render(){
        const state = this.state;
        let view;
        
        if(state.view === this.NUMBER_VIEW){
            view = (
                <div className="onboarding-column">
                  <Form onSubmit={this.sendVerificationCode} autoComplete="off">
                    <Card className="onboarding-form-wrapper">
                      <Form.Field style={{marginBottom: 0}}>
                          <label>Phone number</label>
                          <PhoneInput
                          value={state.phoneNumber}
                          autoComplete="new-password"
                          placeholder="Enter your phone number"
                          onChange={(phone => this.setState({phone}))}
                          />
                      </Form.Field>                    
                    </Card>
                    <Button type="submit" primary>Send</Button>
                  </Form>
                </div>
            )
        } else if (state.view === this.CODE_VIEW) {
            view = (
                <div className="onboarding-column">
                    <Form onSubmit={this.verifyPhoneNumber}>
                      <Card className="onboarding-form-wrapper">
                        <Form.Field style={{marginBottom: 0}}>
                          <label>Enter verification code</label>
                          <input name="cooode" autoComplete="new-password" value={state.cooode} onChange={this.handleChange}/>
                        </Form.Field>
                      </Card>
                      <Button primary type="submit">Verify</Button>
                      <Button primary onClick={() => this.setState({view: this.NUMBER_VIEW})}>Change</Button>
                      <Button primary style={{marginTop: "0.25em"}} onClick={this.sendVerificationCode}>Resend</Button>
                    </Form>     
                </div>
            )
        }
        return (
            <div>
                 <div className="onboarding-5-container">
        {/* <h2 className="text-center">Welcome to IntCrzyPpl, {authStore.currentUser.name || 'User'}!</h2> */}
        <div className="onboarding-heading">Create your profile</div>
        <div className="onboarding-sub-heading ">Let’s keep your account secure</div>
                    {view}
                </div>
            </div>
        )
    }
}

export default withRouter(inject('profileStore', 'onboardingStore', 'authStore', 'usertimelineStore')(observer(Page6)))
