export const currentOnboardingPage = 'currentOnboardingPage';
export const authorization = 'authorization';
export const token = 'authenticationToken';
export const currentUser = 'currentUser';
export const loggedIn = 'loggedIn';
export const pageOne = 'pageOne';
export const signupData = 'signupData';
export const facebookDetails = 'facebookDetails';
export const selectedLocation = 'selectedLocation';
export const previousLocation = 'previousLocation';
export const currentLocation = 'currentLocation';
export const SEARCH_DELAY = 600; // In miliseconds
export const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID || '8JL9WWI7HZ';
export const ALGOLIA_SEARCH_API_KEY = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY || '92db9dd6f431f9a151e4f8cffea72abe';
export const ALGOLIA_USER_INDEX = process.env.ALGOLIA_USER_INDEX || 'dev_users';
export const ALGOLIA_LOCATION_INDEX = process.env.ALGOLIA_LOCATION_INDEX || 'dev_locations';
export const ALGOLIA_SKILL_INDEX = process.env.ALGOLIA_SKILL_INDEX || 'dev_skills';
export const ALGOLIA_COMPANY_INDEX = process.env.ALGOLIA_COMPANY_INDEX || 'dev_companies';
export const ALGOLIA_UNIVERSITY_INDEX = process.env.ALGOLIA_UNIVERSITY_INDEX || 'dev_universities';
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID || "867159170773229";
export const DEFAULT_IMAGES = {
  Skill: 'https://res.cloudinary.com/intcrzyppl/image/upload/v1558895869/Skill_atpvdu.png',
  Location: 'https://res.cloudinary.com/intcrzyppl/image/upload/v1558895847/Location_sokwi5.png',
  Company: 'https://res.cloudinary.com/intcrzyppl/image/upload/v1558895828/Company_ksnpmw.png',
  University: 'https://res.cloudinary.com/intcrzyppl/image/upload/v1558895805/College_zz0e0t.png',
};
export const mapIndexToReadableTitle = {
  dev_users: 'People',
  dev_companies: 'Company',
  dev_locations: 'Location',
  dev_skills: 'Skill',
  dev_universities: 'University',
  all: 'All',
};
export const mapReadableTitleToIndex = {
  people: 'dev_users',
  company: 'dev_companies',
  location: 'dev_locations',
  skill: 'dev_skills',
  university: 'dev_universities',
  all: 'all',
  degreeType: 'degreeType',
  graduationYear: 'graduationYear',
};
export const mapTitleToSingularTitle = {
  People: 'User',
  Skill: 'Skill',
  University: 'University',
  Company: 'Company',
  Location: 'Location',
};
export const mapSingularTitleToPlural = {
  User: 'People',
  Skill: 'Skill',
  University: 'University',
  Company: 'Company',
  Location: 'Location',
};
export const mapDegreeWithSubTypes = {
  bachelors: ["Bachelor's", 'BA', 'BBA', 'BEng', 'BFA', 'BS', 'BSc'],
  masters: ["Master's", 'MA', 'MBA', 'MS', 'MSc', 'MFA', 'MPhil', 'MRes', 'LLM', 'MEng', 'MMath'],
  phd: ['PhD', 'JD', 'MD', 'DPhil'],
};
export const mapDegreeToRanking = {
  bachelors: 0,
  masters: 1,
  phd: 2,
};
export const mapSearchTitleToPlural = {
  All: 'All',
  People: 'People',
  Skill: 'Skills',
  University: 'Universities',
  Company: 'Companies',
  Location: 'Locations',
};
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];


console.log(process.env.NODE_ENV);
