import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import TextAreaWithCounter from '../../components/Forms/TextAreaWithCounter';
import { Button, Card, Image, Dropdown, Form } from 'semantic-ui-react';
import { customDebounce, degreeTypes } from '../../utilities';
import { hermes, capitalize } from '../../utilities';
import InputWithCounter from '../Forms/InputWithCounter';
import { withFormik, ErrorMessage } from 'formik';
import newUniversitySchema from '../../schemas/newUniversitySchema';
import { errors, success } from '../../messages';
import _ from 'lodash';
import SearchWithBubble from '../Forms/SearchWithBubble';

function restructureStudentData(data) {
  let startYear = data.startYear ? data.startYear : null;
  let endYear = data.endYear ? data.endYear : null;

  const payload = {
    university: data.university ? data.selectedUniversity._id : undefined,
    degreeType: data.degreeType,
    programme: data.programme,
    dates: {
      start: startYear,
      end: endYear
    },
    description: data.description,
  };
  return payload;
}

class NewEducationEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUniversity: null,
      searchResults: [],
    };
    this.addUniversity = this.addUniversity.bind(this);
    this.searchForUniverity = this.searchForUniverity.bind(this);
    this.handleResultSelect = this.handleResultSelect.bind(this);
    this.handleTxtChange = this.handleTxtChange.bind(this);
    this.handleDegreeSelection = this.handleDegreeSelection.bind(this);

    let currentYear = new Date().getFullYear();

    this.startYearRange = [{ key: 'default', text: 'Start Year', value: null }];
    this.endYearRange = [{ key: 'default', text: 'End Year', value: null }];

    for (let i = currentYear + 7; i >= currentYear + 1; i--) {
      this.endYearRange.push({ key: i, text: i, value: i });
    }

    for (let i = currentYear; i >= 1900; i--) {
      this.startYearRange.push({ key: i, text: i, value: i });
      this.endYearRange.push({ key: i, text: i, value: i });
    }

  }

  componentDidMount() {
    const { onboardingStore, previousData } = this.props;
    if (previousData) {
      this.setState({
        selectedUniversity: previousData.university || null,
        searchResults: onboardingStore.universitySearchResults,
      });
    } else {
      this.setState({
        selectedUniversity: null,
      });
    }

  }

  handleDegreeSelection(e, { value }) {
    this.setState({
      degreeType: value,
    });
  }

  handleTxtChange(option) {
    return e => {
      this.setState({
        [option]: e.target.value,
      });
    };
  }

  searchForUniverity(e, { value }) {
    const { onboardingStore } = this.props;
    this.setState({
      university: value,
    });

    this.props.handleChange(
      {
        target: {
          type: 'text',
          name: 'university',
          id: 'university',
          value,
        },
      },
      { value },
    );

    if (this.state.timeout) {
      window.clearTimeout(this.state.timeout);
    }

    const id = setTimeout(() => {
      onboardingStore.searchForUniverity(value);
    }, 600);

    this.setState({
      timeout: id,
    });
  }

  handleResultSelect(e, { result }) {
    this.setState({
      selectedUniversity: result,
    });

    this.props.setFieldValue('selectedUniversity', result);
  }

  handleResultRemove = () => {
    this.setState({
      university: "",
      selectedUniversity: null,
    });

    this.props.handleChange(
      {
        target: {
          type: 'text',
          name: 'university',
          id: 'university',
          value: "",
        },
      },
      { value: "" },
    );

    this.props.setFieldValue('university', '');
    this.props.setFieldValue('selectedUniversity', null);
  }

  async addUniversity(name) {
    const { onboardingStore } = this.props;
    try {
      const response = await onboardingStore.addUniversity({ name });
      await this.setState({
        selectedUniversity: response,
      });
      this.props.setFieldValue('selectedUniversity', response);
    } catch (error) {
      hermes.error(error);
    }
  }


  // collectData() {
  //   const { state } = this;
  //   const prePayload = {
  //     university: state.selectedUniversity._id,
  //     description: state.description,
  //     degreeType: state.degreeType,
  //     programme: state.programme,
  //     startYear: state.startYear,
  //     endYear: state.endYear,
  //     currentlyStudyHere: state.currentlyStudyHere,
  //   };
  //
  //   const payload = restructureStudentData(prePayload);
  //   console.log(payload, 'poioioioiio');
  //
  //   return payload;
  // }
  //
  // async saveOrUpdate() {
  //   const { previousData, profileStore } = this.props;
  //   const payload = this.collectData();
  //   if (!previousData) {
  //     await profileStore.saveEducation(payload);
  //     this.toggleEdit();
  //   } else {
  //     const { _id } = previousData;
  //     await profileStore.updateEducation(_id, payload);
  //     this.toggleEdit();
  //   }
  // }

  toggleEdit = () => {
    const { toggleState } = this.props;
    toggleState();
  };


  render() {
    const { values, setFieldValue, touched, errors, handleChange, handleBlur, handleSubmit, isSaving } = this.props;
    const { onboardingStore, toggleState } = this.props;
    const { university, startYear, endYear, description, programme, degreeType, selectedUniversity } = values;
    const { initialUniversitySearch } = onboardingStore;

    const computedUniversities = [...onboardingStore.computedUniversities];

    const exists = computedUniversities[0] && computedUniversities[0].name.toLowerCase().trim() === university.toLowerCase().trim();

    if (!initialUniversitySearch  && !exists) {
      computedUniversities.push({
        name: capitalize(university.trim()),
        isAddition: true,
        onClick: () => this.addUniversity(capitalize(university.trim()))
      })
    }

    return (
      <Card>
        <div className="new-education">
          <Image
            width="80"
            height="80"
            src="https://react.semantic-ui.com/images/wireframe/image.png"
          />

          <Form onSubmit={handleSubmit} autoComplete="off">
            <div className="details">
              <SearchWithBubble
                icon=""
                name="university"
                results={computedUniversities}
                value={university}
                labelName="University"
                onChange={this.searchForUniverity}
                onSelect={this.handleResultSelect}
                onRemove={this.handleResultRemove}
                addNewValue={this.addUniversity}
                selectedEntity={selectedUniversity ? selectedUniversity.name : ""}
                placeholder="College or university"
                noResultsMessage={null}
              />

              <div className="dates">
                <div className="start ">
                  <Dropdown
                    name="startYear"
                    options={this.startYearRange}
                    search
                    selection
                    placeholder="Start year"
                    value={startYear}
                    onChange={(e, { value }) => setFieldValue('startYear', value)}
                  />
                </div>
                <div className="end">
                  <Dropdown
                    name="endYear"
                    options={this.endYearRange}
                    search
                    selection
                    placeholder="End year"
                    value={endYear}
                    onChange={(e, { value }) => setFieldValue('endYear', value)}
                  />
                </div>
              </div>
            </div>
            <div className="programme">
              <div className="type">
                <Dropdown
                  name="degreeType"
                  search
                  selection
                  options={degreeTypes}
                  placeholder="Degree type"
                  value={degreeType}
                  onChange={(e, { value }) => setFieldValue('degreeType', value)}
                />
                {/* <ErrorMessage name="degreeType" /> */}
              </div>
              <div className="name">
                <InputWithCounter
                  name="programme"
                  value={programme}
                  placeholder="Programme name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxCount={80}
                />
                {/* <ErrorMessage name="programme" /> */}
              </div>
            </div>
            <div className="description">
              <TextAreaWithCounter
                name="description"
                value={description}
                placeholder="Enter a decription here ..."
                onChange={handleChange}
                onBlur={handleBlur}
                maxCount={120}
              />
              {/* <ErrorMessage name="description" /> */}
            </div>

            <div className="actions">
              <Button className="save" type="submit" primary>
                Save
              </Button>
              <Button onClick={toggleState} basic>
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    );
  }
}

function createNeatObject({ previousData: pd }) {
  let previousData = pd || { university: {} };
  const { degreeType, dates, programme, university, description } = previousData;
  return {
    university: university.name || '',
    startYear: dates ? dates.start : undefined,
    endYear: dates ? dates.end : undefined,
    programme: programme || '',
    description: description || '',
    degreeType: degreeType || '',
    selectedUniversity: university || undefined,
  };
}

const MyEnhancedForm = withFormik({
  mapPropsToValues: props => {
    return createNeatObject(props);
  },
  validationSchema: newUniversitySchema,

  handleSubmit: async (values, bag) => {
    let { selectedUniversity } = values;
    if(_.isEmpty(selectedUniversity)){
      hermes.error(errors.UNKNOWN_UNIVERSITY_NAME);
      return;
    }

    const { previousData, profileStore, toggleState } = bag.props;

    const payload = restructureStudentData(values);

    
    try {
      if (!previousData) {
        await profileStore.saveEducation(payload);
        toggleState();
        hermes.success(success.EDUCATION_ADDED);
      } else {
        const { _id } = previousData;
        await profileStore.updateEducation(_id, payload);
        toggleState();
        hermes.success(success.EDUCATION_UPDATED);
      }
    } catch (error) {
      hermes.error(errors.EDUCATION_UPDATE_ERROR);
    }
  },

  displayName: 'BasicForm',
})(observer(NewEducationEntry));

export default inject('profileStore', 'onboardingStore')(observer(MyEnhancedForm));
