import React, { Component } from 'react';
import { SkillTable } from '../../components/Reference/index';
import { Dropdown, Header } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

class SkillReference extends Component {
  constructor(props) {
    super(props);
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  componentDidMount() {
    window.ga('set', {
      page: '/skill/:id',
      title: 'Skill Reference Page'
    })

    window.ga('send', 'pageview')

    const { referenceStore } = this.props;
  }

  handleLocationChange(e, { value }) {
    const { referenceStore, loadingStore } = this.props;
    if (!loadingStore.loading) referenceStore.changeLocation(value);
  }

  render() {
    const { match, referenceStore, addNewFavourite } = this.props;
    let children,
      { locations, selectedLocation, results, loadingUsers } = referenceStore;

    if (loadingUsers) {
      children = null;
    } else {
      children = <SkillTable users={results ? results : []} addNewFavourite={addNewFavourite} />;
    }

    return (

      <div className="reference">
        <div className="column">
          <h1 className="ui center aligned">{referenceStore.referenceName}</h1>
          <p className="ui center aligned">Skill</p>
          <div className="filter-dropdown">
            <Dropdown
              placeholder="Filter locations"
              search
              selection
              value={selectedLocation}
              options={locations}
              onChange={this.handleLocationChange}
            />
          </div>
        </div>
        {children}
      </div>
    );
  }
}

export default inject('referenceStore', 'loadingStore')(observer(SkillReference));
