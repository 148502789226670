async function runValidation(schema, data) {
  try {
    // const valid = await schema.isValid(data);
    const clean = await schema.validate(data, {
      abortEarly: false,
    });
    return { error: false, data: clean };
  } catch (error) {
    return { error: true, type: 'VALIDATION_ERROR', data: error };
  }
}

export default runValidation;
