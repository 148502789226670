import React, { Fragment } from 'react';
import { observer } from 'mobx-react';

const SidebarItem = observer(props => {
  const { day, month, year, event_name, event_location, event_url } = props;
  return (
    <Fragment>
      <a className="meetup" href={event_url} rel="nofollow" target="_blank">
        <div className="date">
          <span className="month">
            {new Date(`${month}/${day}/${year}`).toLocaleDateString('en-us', { month: 'short' })}
          </span>
          <span className="day">{day}</span>
        </div>
        <div className="text">
          <span className="name">{event_name}</span>
          <span className="location">{event_location}</span>
        </div>
      </a>
    </Fragment>
  );
});

export default SidebarItem;
