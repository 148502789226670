import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { hermes } from '../../utilities';
import { errors } from '../../messages';
import { observer, inject } from 'mobx-react';
import EditPersonalInfoForm from './EditPersonalInfoForm';
import { success } from '../../messages';
import { goTo } from '../../routing';

class EditPersonalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedImage: null, uploadingImage: false };
    this.handleSelect = this.handleSelect.bind(this);
    this.updateBio = this.updateBio.bind(this);
  }

  handleSelect(option) {
    const { profileStore, loadingStore } = this.props;
    const extractedPropName = option.split('.')[1];
    return async value => {
      if (value) {
        try {
          loadingStore.setLoading()
          const response = await profileStore.updateLocation(value);
          /*
          Because of formik's way of representing nested values, all the location subtypes
          can be found as location.[subtype]
          but we only need the sub type, so we split away the location and extract the sub type
          */
          await this.setState({ [extractedPropName]: response });
          profileStore.updateSelectedLocation(extractedPropName)
          loadingStore.setLoaded();
          return response;
        } catch (error) {
          hermes.error(error);
        }
      } else {
        await this.setState({ [extractedPropName]: undefined });
      }
    };
  }

  handleImageChange = e => {
    const fileblob = e.target.files[0];
    this.setState({ selectedImage: fileblob });
  };

  async updateBio(data) {
    const { profileStore, history } = this.props;

    const { selectedLocation: location } = data;
    const payload = { ...data, selectedFile: this.state.selectedImage };

    payload.bio.website = payload.bio.website.trim();

    if (payload.bio.website && !payload.bio.website.startsWith('http')) {
      payload.bio.website = 'http://' + payload.bio.website
    }

    if (this.state.currentLocation) {
      payload.currentLocation = this.state.currentLocation._id;
    }

    if (this.state.previousLocation) {
      payload.previousLocation = this.state.previousLocation._id;
    } else if (location.previousLocation) {
      payload.previousLocation = profileStore.userData.location.previousLocation._id;
    }

    if (this.state.visitingLocation) {
      payload.visitingLocation = this.state.visitingLocation._id;
    }

    try {
      const response = await profileStore.updateBio(payload);
      goTo(`/${profileStore.userData.slug}`, history);
      hermes.success(success.PROFILE_UPDATED);
    } catch (error) {
      console.log(error)
      hermes.error(errors.BIO_UPDATE_ERROR);
    }
  }

  componentDidMount() {
    const { profileStore } = this.props;
    const visitingLocation = profileStore.userData.location && profileStore.userData.location.visitingLocation;
    this.setState({ visitingLocation })
  }

  render() {
    const { userData, profileStore } = this.props;
    const { uploadingImage, sendingData } = profileStore;

    console.log('userdata', { ...userData });
    return (
      <div className="edit-profile">
        <EditPersonalInfoForm
          {...userData}
          userLocation={userData.location}
          submitData={this.updateBio}
          uploadingImage={uploadingImage}
          selectedImage={this.state.selectedImage}
          savingProfileData={sendingData}
          handleImageChange={this.handleImageChange}
          handleLocationSelect={this.handleSelect}
        />
      </div>
    );
  }
}

export default withRouter(inject('profileStore', 'loadingStore')(observer(EditPersonalInfo)));
