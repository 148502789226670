import LandingPage from './LandingPage';
import UserTimeline from './UserTimeline';
import Onboarding from './Onboarding';
import Settings from './Settings';
import Favourite from './Favourites';
import Reference from './Reference';
import Profile from './Profile';
import Pitch from './Pitch';
import Search from './Search';
import EmailVerification from './EmailVerification';
import SubscribePage from './SubscribePage';
import EditProfile from './EditProfile';
import ResetPassword from './ResetPassword';
import Privacy from './Privacy';
import HostMeetup from './HostMeetup';
import SentMeetups from './SentMeetups';
import ReportBug from './ReportBug';
import ReportedBugs from './ReportedBugs';
import FAQ from './FAQ';
import SignalBroadcast from './SignalBroadcast'
import Applications from './Applications'
import Meetups from './Meetups'


export default {
  EmailVerification,
  Onboarding,
  LandingPage,
  UserTimeline,
  Settings,
  Favourite,
  Reference,
  Profile,
  Search,
  SubscribePage,
  EditProfile,
  ResetPassword,
  ReportBug,
  HostMeetup,
  SentMeetups,
  Privacy,
  ReportedBugs,
  FAQ,
  SignalBroadcast,
  Applications,
  Meetups,
  Pitch
};
