import { Search, Button } from 'semantic-ui-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";

const customResultRenderer = ({ id, name, usercount, isAddition, onClick }) => {
  if (isAddition) {
    return (
      <div key={name} className="swa-addition-wrapper">
        <Button
          primary
          type="button"
          onClick={() => _.debounce(onClick)}
        >
          Add {name}
        </Button>
      </div>
    )
  }
  return (
    <div key={id} className="menu-item-swa-wrapper">
      <div className="name">{name}</div>
      {usercount <= 0 ? (
        ''
      ) : (
          <div className="count">
            {usercount} {usercount == 1 ? 'member' : 'members'}
          </div>
        )}
    </div>
  );
};

customResultRenderer.propTypes = {
  name: PropTypes.string,
  userCount: PropTypes.string,
};

class SearchWithAddition extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    // this.resetComponent();
  }

  render() {
    const {
      name,
      loading,
      results,
      value,
      onChange,
      onSelect,
      onFocus,
      onBlur,
      open,
      addNewValue,
      placeholder,
      noResultsMessage,
      resultRenderer,
      icon,
      className,
      input
    } = this.props;

    return (
      <Search
        name={name}
        icon={icon}
        className={className ? className + ' swa' : 'swa'}
        onResultSelect={onSelect}
        onSearchChange={onChange}
        open={open}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        results={results}
        value={value}
        input={input || undefined}
        noResultsMessage={
          noResultsMessage
        }
        resultRenderer={resultRenderer || customResultRenderer}
      />
    );
  }
}

SearchWithAddition.propTypes = {
  resultRenderer: PropTypes.func,
  noResultsMessage: PropTypes.object,
  results: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string
  // addNewValue: PropTypes.func.isRequired,
};

export default SearchWithAddition;
