import { action, observable, decorate } from 'mobx';
import { storage, handleError, hermes } from '../utilities';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import authStore from './authStore';
import loadingStore from './loadingStore'
import api from '../api';
import { errors, success } from '../messages';
import _ from 'lodash';
import { goTo } from '../routing';

async function geoCodeAndParse(location) {
  try {
    const results = await geocodeByAddress(location);
    const latLng = await getLatLng(results[0]);
    let sp = location.split(',');
    let country = sp[sp.length - 1];
    country = country.trim();
    let city = sp[0];
    city = city.trim();

    let latitude = latLng.lat;
    let longitude = latLng.lng;

    return {
      country: country,
      name: location,
      lat: latitude,
      log: longitude,
    };
  } catch (e) { }
}

class ProfileStore {

  firstLoad = true;
  sendingData = false;
  uploadingImage = false;
  userData = null;
  currentLocation = '';
  previousLocation = '';
  visitingLocation = '';
  selectedLocation = {
    previousLocation: false,
    visitingLocation: false,
    currentLocation: false
  };
  otherUserData = null;
  hasSearched = false;
  profileData = null;
  initialSignals = {
    minutesToCall: 0,
    currentLocation: '',
    visitingLocation: '',
    daysToExpire: 0
  };
  signals = {
    minutesToCall : 0,
    currentLocation: '',
    currentLocationObject: {},
    visitingLocation: '',
    visitingLocationObject: {},
    daysToExpire: 0
  }
  formInputsVisibility = {
    minutesToCall: false,
    visitingALocation: false,
    changingCurrentLocation: false
  }

  async updateApplicant(data) {
    try {
      loadingStore.setLoading();
      const response = await api.user.updateApplicant(data);
      this.userData = response;
      loadingStore.setLoaded();
    } catch (e) {
      throw handleError(e);
    }
  };

  toggleFormInputsVisibility = (name) => {
    this.formInputsVisibility[name] = !this.formInputsVisibility[name]
  }

  async getUserData() {
    try {
      const response = await api.user.getUserData();
      this.userData = response;
      return response;
    } catch (error) {
      throw handleError(error);
    }
  }

  async getProfile(slug) {
    try {
      loadingStore.setLoading()
      this.hasSearched = false;
      const response = await api.user.getUserBySlug(slug);
      this.hasSearched = true;
      this.profileData = response;
      loadingStore.setLoaded()
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async updateProfile(slug){
    try {
      loadingStore.setLoading()
      const response = await api.user.getUserBySlug(slug);
      this.profileData = response;
      loadingStore.setLoaded()
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }

  }

  async saveEducation(data) {
    try {
      loadingStore.setLoading();
      const response = await api.user.saveEducation(data);
      loadingStore.setLoaded()
      this.userData = response;
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async updateEducation(id, data) {
    try {
      loadingStore.setLoading();
      const response = await api.user.updateEducation(id, data);
      loadingStore.setLoaded()
      this.userData = response;
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async deleteExperience(id) {
    try {
      loadingStore.setLoading();
      const response = await api.user.deleteExperience(id);
      loadingStore.setLoaded()

      this.userData = response;
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async saveExperience(data) {
    try {
      loadingStore.setLoading();
      const response = await api.user.saveExperience(data);
      loadingStore.setLoaded()

      this.userData = response;
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async updateExperience(id, data) {
    try {
      loadingStore.setLoading();
      const response = await api.user.updateExperience(id, data);
      loadingStore.setLoaded()

      this.userData = response;
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async deleteEducation(id) {
    try {
      loadingStore.setLoading();
      const response = await api.user.deleteEducation(id);
      loadingStore.setLoaded()

      this.userData = response;
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  syncLocation(option, value) {
    if (value) {
      this[option] = value;
      storage.updateState(option, value);
    }
  }

  async updateLocation(value) {
    try {
      loadingStore.setLoading();
      const payload = await geoCodeAndParse(value);
      const response = await api.location.lookupLocation(payload);
      loadingStore.setLoaded()
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async updateSelectedLocation(locationType){
    this.selectedLocation[locationType] = true
  }

  async uploadingImage() {
    let formData = new FormData();
    formData.append('profile', this.state.selectedFile);
  }

  async uploadFBImage(url) {
    try {
      loadingStore.setLoading();
      const response = await api.user.uploadFBImage({ url });
      loadingStore.setLoaded()
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async updateBio(payload) {
    // console.log(payload);

    try {
      loadingStore.setLoading();
      if (payload.selectedFile) {
        this.uploadingImage = true;
        let formData = new FormData();
        formData.append('profile', payload.selectedFile);
        const response = await api.user.uploadImage(formData);
        this.uploadingImage = false;
      }

      this.sendingData = true;
      const bioData = await api.user.updateBio(payload.bio);
      
      const daysToExpire = (
        payload.location.daysToExpire === this.initialSignals.daysToExpire &&
        payload.visitingLocation === this.initialSignals.visitingLocation ?
          undefined : payload.location.daysToExpire
      );

      const userData = await api.location.updateLocation({
        currentLocation: payload.currentLocation,
        visitingLocation: payload.visitingLocation,
        previousLocation: payload.previousLocation,
        daysToExpire
      });
      this.userData = userData;
      this.sendingData = false;
      loadingStore.setLoaded()
    } catch (error) {
      loadingStore.setLoaded()
      this.sendingData = false;
      throw handleError(error);
    }
  }

  async uploadImage(data) {
    try {
      this.uploadingImage = true;
      let formData = new FormData();
      formData.append('profile', data.selectedFile);
      const response = await api.user.uploadImage(formData);
      authStore.syncUser(response);
      this.userData = response;
      this.uploadingImage = false;
    } catch (error) {
      this.uploadImage = false;
      throw handleError(error);
    }
  }

  updateUserObject(data) {
    if (data) {
      this.userData = data;
    }
  }

  async resendVerificationEmail() {
    try {
      const response = await api.user.resendVerificationEmail();
      return response;
    } catch (error) {
      throw handleError(error);
    }
  }

  async reorderExperiences(experiences) {
    try {
      loadingStore.setLoading();
      const response = await api.user.reorderExperiences(experiences);
      this.userData = response;
      loadingStore.setLoaded();
    } catch (error) {
      loadingStore.setLoaded();
      throw errors.REORDER_EXPERIENCES_FAILED;
    }
  }

  async reorderEducations(educations) {
    try {
      loadingStore.setLoading();
      const response = await api.user.reorderEducations(educations);
      this.userData = response;
      loadingStore.setLoaded();
    } catch (error) {
      loadingStore.setLoaded();
      throw errors.REORDER_EDUCATIONS_FAILED;
    }
  }

  getCallSignal = async () => {
    try {
      const callSignal = await api.user.getCallSignal();
      if (callSignal && new Date(callSignal.expireAt) - new Date() > 0) {
        this.signals.minutesToCall = Math.ceil((new Date(callSignal.expireAt) - new Date()) / (1000 * 60)) 
        this.formInputsVisibility.minutesToCall = true;
      } else {
        this.signals.minutesToCall = '';
      }

      this.initialSignals.minutesToCall = this.signals.minutesToCall;
    } catch (e) {
      console.log(e);
    }
  }

  getSignals = async () => {
    await this.getCallSignal(); 
    while (!this.userData) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    const { currentLocation, visitingLocation, visitingLocationExpireAt } = this.userData.location;
    
    if (visitingLocationExpireAt && new Date(visitingLocationExpireAt) - new Date() > 0) {
      this.signals.daysToExpire = Math.ceil((new Date(visitingLocationExpireAt) - new Date()) / (1000 * 60 * 60 * 24));
      this.signals.visitingLocationObject = visitingLocation || {};
      this.signals.visitingLocation = this.signals.visitingLocationObject.name || '';
      this.formInputsVisibility.visitingALocation = true;
    } else {
      this.signals.daysToExpire = '';
      this.signals.visitingLocationObject = {};
      this.signals.visitingLocation = '';
    }

    this.signals.currentLocationObject = currentLocation || {};
    this.signals.currentLocation = this.signals.currentLocationObject.name || '';

    this.initialSignals.currentLocation = this.signals.currentLocationObject._id || '';
    this.initialSignals.visitingLocation = this.signals.visitingLocationObject._id || '';
    this.initialSignals.daysToExpire = this.signals.daysToExpire; 
  }

  updateMinutesToCall = (minutes) => {
    this.signals.minutesToCall = minutes
  }

  updateDaysVisitingLocation = (value) => {
    this.signals.daysToExpire = value;
  }

  updateLocationSignalObject = (type, location) => {
    if (type == 'current') {
      this.signals.currentLocationObject = location || {};
    } else if (type == 'visiting') {
      this.signals.visitingLocationObject = location || {};
    }
  }

  updateLocationSignalName = (type, location) => {
    this.signals[type] = location;
  }

  submitSignals = async (history) => {
    const changedSignals = {};
    const { 
      minutesToCall,
      currentLocation,
      currentLocationObject,
      visitingLocation,
      visitingLocationObject,
      daysToExpire,
    } = this.signals;


    if(this.formInputsVisibility.minutesToCall){
      if (minutesToCall !== this.initialSignals.minutesToCall) {
        changedSignals.minutesToCall = minutesToCall;
      }
    }else {
      //If the minutes to call button is turned off, cancel the active signal
      changedSignals.minutesToCall = null;
    }

    if(this.formInputsVisibility.changingCurrentLocation){
      if (currentLocationObject._id !== this.initialSignals.currentLocation) {
        if (currentLocationObject._id) {
          changedSignals.currentLocation = currentLocationObject._id;
        } else {
          hermes.error(errors.LOCATION_NOT_SELECTED);
          return;
        }
      }
    }    

    if(this.formInputsVisibility.visitingALocation){
      if (visitingLocationObject._id !== this.initialSignals.visitingLocation) {
        if (visitingLocationObject._id) {
          changedSignals.visitingLocation = visitingLocationObject._id;
        } else {
          hermes.error(errors.LOCATION_NOT_SELECTED);
          return;
        }
        if (daysToExpire) {
          changedSignals.daysToExpire = daysToExpire;
        } else {
          hermes.error(errors.NUMBER_OF_VISITING_DAYS_REQUIRED);
          return;
        }
      } else if (daysToExpire && daysToExpire !== this.initialSignals.daysToExpire) {
        changedSignals.daysToExpire = daysToExpire;
      }
    } else {
      //If the visiting a location toggle button is turned off, cancel the active signal
      changedSignals.visitingLocation = null;
    }

    try {
      loadingStore.setLoading()
      // console.log(changedSignals)

      if (!_.isEmpty(changedSignals)) {
        const response = await api.user.sendSignals(changedSignals);
        this.updateUserObject(response);
        hermes.success(success.SIGNAL_SENT);
        goTo('/', history);
      } else {
        hermes.error(errors.NO_SIGNAL_CHANGED)
      }
      loadingStore.setLoaded()
    } catch (e) {
      hermes.error(handleError(e))
    }
  }

  sendVerificationCode = async (number) => {
    try {
      loadingStore.setLoading();
      await api.user.sendVerificationCode({number});      
      loadingStore.setLoaded();
    } catch(e) {
      loadingStore.setLoaded();
      throw handleError(e);
    }
  }

  verifyCode = async (number, code) => {
    try {
      loadingStore.setLoading();
      const updatedUser = await api.user.verifyCode({ number, code });
      if(!updatedUser) throw new Error(errors.INVALID_VERIFICATION_CODE)
      this.updateUserObject(updatedUser);
      loadingStore.setLoaded();
    } catch(e) {
      loadingStore.setLoaded();
      throw handleError(e);
    }
  }
}

decorate(ProfileStore, {
  resendVerificationEmail: action,
  updateUserObject: action,
  profileData: observable,
  uploadImage: action,
  otherUserData: observable,
  hasSearched: observable,
  uploadingImage: observable,
  userData: observable,
  sendingData: observable,
  signals: observable,
  getProfile: action,
  updateEducation: action,
  saveEducation: action,
  deleteEducation: action,
  syncLocation: action,
  updateLocation: action,
  updateExperience: action,
  updateBio: action,
  updateApplicant: action,
  reorderExperiences: action,
  reorderEducations: action,
  updateMinutesToCall: action,
  getSignals: action,
  getCallSignal: action,
  updateDaysVisitingLocation: action,
  updateLocationSignalName: action,
  formInputsVisibility: observable,
  toggleFormInputsVisibility: action,
  selectedLocation: observable,
  updateSelectedLocation: action
});

export default new ProfileStore();
