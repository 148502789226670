import * as yup from 'yup';
import { errors } from '../messages';

const schema = yup.object().shape({
  bio: yup.object({
    fbMessenger: yup.string().trim().matches(/^(?:https?:\/\/)?(?:www\.)?(?:messenger\.com\/t\/|m\.me\/)([A-Z0-9.]{5,})\/?$/i, errors.INVALID_MESSENGER_URL),
    facebook: yup.string().trim().matches(/^(?:https?:\/\/)?(?:(?:www|web|m)\.)?(?:facebook\.com\/(?:profile\.php\?id=)?|fb\.me\/)([A-Z0-9.]{5,})\/?$/i, errors.INVALID_FACEBOOK_URL),
    website: yup.string().trim().matches(/(^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$)|^$/i, errors.INVALID_USER_WEBSITE_URL),
    twitter: yup.string().trim().matches(/^(?:https?:\/\/)?(?:www\.)?twitter\.com\/([A-Z0-9.]{1,15})\/?$|^$/i, errors.INVALID_TWITTER_URL),
    linkedin: yup.string().trim().matches(/^(?:https?:\/\/)?(?:(www|\w{2})\.)?linkedin\.com\/in\/([A-Z0-9.-]+)\/?$|^$/i, errors.INVALID_LINKEDIN_URL),
    dribbble: yup.string().trim().matches(/^(?:https?:\/\/)?(?:www\.)?dribbble\.com\/([A-Z0-9.]+)\/?$|^$/i, errors.INVALID_DRIBBBLE_URL),
    headline: yup.string(),
    miniResume: yup.string(),
    instagram: yup.string().trim().matches(/^(?:https?:\/\/)?(?:www\.)?instagram\.com\/([A-Z0-9.]+)\/?$|^$/i, errors.INVALID_INSTAGRAM_URL),
    github: yup.string().trim().matches(/^(?:https?:\/\/)?(?:www\.)?github\.com\/([A-Z0-9.]+)\/?$|^$/i, errors.INVALID_GITHUB_URL),
  }),
  image: yup.string(),
  location: yup.object({
    currentLocation: yup.string().required(errors.MISSING_CURRENT_LOCATION),
    previousLocation: yup.string(),
    visitingLocation: yup.string(),
    daysToExpire: yup.string().when('visitingLocation', (location) => {
      return location ? yup.string().required(errors.NUMBER_OF_VISITING_DAYS_REQUIRED).test('daysToExpire', errors.NUMBER_OF_VISITING_DAYS_INVALID, value => parseInt(value)) : yup.string() 
    }),
  }),
  selectedLocation: yup.object({
    currentLocation: yup.string().required(errors.MISSING_CURRENT_LOCATION),
    previousLocation: yup.string(),
    visitingLocation: yup.string(),
  }),
});

export default schema;
