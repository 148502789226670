import { action, observable, decorate, computed, autorun } from 'mobx';
import loadingStore from './loadingStore';
import { handleError } from '../utilities';
import api from '../api';

class FavouriteStore {
  constructor() {
    this.changeLocation = this.changeLocation.bind(this);
    this.getFavourites = this.getFavourites.bind(this);
    this.loadMoreResults = this.loadMoreResults.bind(this);
    this.filter = this.filter.bind(this);

  }

  latestServerResponse = null;
  _favourites = {};
  endOfResults = false;
  firstSearchMade = false;
  selectedLocation = 'All';
  selectedName = 'All';
  favouritesFilter = ''

  get favourites() {
    return this._favourites[this.favouritesFilter] || [];
  }

  async filter() {
    if (loadingStore.loading) return;
    
    this.endOfResults = false;

    if (this._favourites[`${this.selectedName}\t${this.selectedLocation}`]) {
      this.favouritesFilter = `${this.selectedName}\t${this.selectedLocation}`;
      if (!this.favourites.length || this.favourites.length % 20 !== 0) {
        this.endOfResults = true;
      }
      return;
    }

    loadingStore.setLoading();
    try {
      const params = {};
      if (this.selectedName !== 'All') {
        params.name = this.selectedName
      }
      if (this.selectedLocation !== 'All') {
        params.location = this.selectedLocation
      }

      const response = await api.favourite.getFilteredFavourites(params);
      this.favouritesFilter = `${this.selectedName}\t${this.selectedLocation}`;
      this._favourites[this.favouritesFilter] = response;
      
      if (response.length !== 20) {
        this.endOfResults = true;
      }

      loadingStore.setLoaded();
      return response;
    } catch (error) {
      loadingStore.setLoaded();
      throw handleError(error);
    }
    
  }


  changeLocation(value) {
    this.selectedLocation = value;
  }

  changeName(value) {
    this.selectedName = value;
  }

  async loadMoreResults() {
    if (loadingStore.loading) return;
    
    loadingStore.setLoading();
    try {
      const params = {};
      if (this.selectedName !== 'All') {
        params.name = this.selectedName;
      }
      if (this.selectedLocation !== 'All') {
        params.location = this.selectedLocation;
      }

      params.page = Math.ceil(this.favourites.length / 20)

      const response = await api.favourite.getFilteredFavourites(params);
      this._favourites[this.favouritesFilter] = [...this._favourites[this.favouritesFilter], ...response];

      if (response.length !== 20) {
        this.endOfResults = true;
      }

      loadingStore.setLoaded();
      return response;
    } catch (error) {
      loadingStore.setLoaded();
      throw handleError(error);
    }
  }

  async addNewFavourite(data) {
    try {
      loadingStore.setLoading();
      await api.favourite.addNewFavourite(data);
      loadingStore.setLoaded()
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async getFavourites() {
    try {
      const response = await api.favourite.getFavourites();
      this.firstSearchMade = true;
      this._favourites = {};
      this.selectedName = 'All';
      this.selectedLocation = 'All';
      this.endOfResults = false;
      this.favouritesFilter = `${this.selectedName}\t${this.selectedLocation}`;
      this._favourites[this.favouritesFilter] = response.users;

      this.names = [{ text: 'All', value: 'All', key: 0 }, ...response.names.map((name, index) => {
        return { key: index + 1, value: name, text: name };
      })];

      this.locations = [{ text: 'All', value: 'All', key: 0 }, ...response.locations.map((location, index) => {
        return { key: index + 1, value: location, text: location };
      })];

      return response;
    } catch (error) {
      throw handleError(error);
    }
  }

  async updateFavourite(data) {
    try {
      loadingStore.setLoading();
      const response = await api.favourite.updateFavourite(data);
      loadingStore.setLoaded()
      const index = this._favourites[this.favouritesFilter].findIndex(favourite => favourite._id == response._id);
      const updatedArray = [...this._favourites[this.favouritesFilter].slice(0, index), response, ...this._favourites[this.favouritesFilter].slice(index + 1)];
      this._favourites[this.favouritesFilter] = updatedArray;
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async removeFromFavourite(data) {
    try {
      loadingStore.setLoading();
      const id = data._id;
      await api.favourite.deleteFavourite(id);
      Object.keys(this._favourites).forEach(key => {
        this._favourites[key] = this._favourites[key].filter(favourite => favourite._id !== id);
      })
      loadingStore.setLoaded();
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }
}

decorate(FavouriteStore, {
  filterByLocationAndName: action,
  selectedLocation: observable,
  selectedName: observable,
  names: observable,
  locations: observable,
  changeLocation: action,
  endOfResults: observable,
  loadingMoreResults: observable,
  firstSearchMade: observable,
  changeName: action,
  _favourites: observable,
  favourites: computed,
  favouritesFilter: observable,
  getFavourites: action,
  updateFavourite: action,
  removeFromFavourite: action,
  addNewFavourite: action,
  filter: action
});

export default new FavouriteStore();
