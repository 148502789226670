import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Card, Header, Button } from 'semantic-ui-react';

import api from '../../api';
import { hermes } from '../../utilities';
import { errors } from '../../messages';
import { inject, observer } from 'mobx-react';
import LinkWithLoader from '../../components/LinkWithLoader';
import { goTo } from '../../routing';

function Bug({_id, description, user, browser, fixed, createdAt, history, setBugs, loadingStore}) {

  const handleClick = (action) => async () => {
    loadingStore.setLoading();
    const data = {
      bugId: _id
    };

    if (action === 'toggleFixed') {
      data.value = !fixed;
    }

    try {
      const { allowed, bugs } = await api.bug[action](data);
      if (allowed) {
        setBugs(bugs)
      } else {
        goTo('/', history)
      }
    } catch (e) {
      console.log(e)
      hermes.error(errors.DEFAULT);
    }
    loadingStore.setLoaded();
  } 


  return (
    <Card>
      <Header inverted>
        {fixed ? '[FIXED] ' : null}
        <LinkWithLoader to={`/${user.slug}`}>
        {user.name}
        </LinkWithLoader> at {new Date(createdAt).toString()} using {browser} :
      </Header>
      <pre style={{whiteSpace: 'pre-wrap'}}>{description}</pre>
      <div style={{textAlign: 'center'}}>
        <Button onClick={handleClick('toggleFixed')} secondary>{fixed ? 'Mark as not fixed' : 'Mark as fixed'}</Button>
        <Button onClick={handleClick('deleteBug')} secondary>Delete</Button>
      </div>
    </Card>
  )
}

export default withRouter(inject('loadingStore')(observer(Bug)));
