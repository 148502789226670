import PrivateRoute from '../../components/Auth/PrivateRoute';
import { protectedRoutes, openRoutes } from '../../routes';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import NotFound from '../../components/Routes/NotFound';
import Unauthorized from '../../components/Routes/Unauthorized';
import NotCompatiblePage from '../NotCompatiblePage';
import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { hermes } from '../../utilities';
import { Navbar, EmailVerificationBar } from '../../components/Shared';
import authStore from '../../stores/authStore';
import Loader from '../../components/Loader';
import ProgressBar from '../../components/ProgressBar';
import PageMask from '../../components/PageMask';
import Bowser from 'bowser';

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = { sendingEmail: false, emailSent: false };
    this.resendVerificationEmail = this.resendVerificationEmail.bind(this);

    const supportedBrowsers = ["Chrome", "Chromium", "Opera", "Safari", "Firefox"];
    const browserName = Bowser.getParser(window.navigator.userAgent).getBrowserName();
    this.isSupported = supportedBrowsers.includes(browserName);
  }

  static propTypes = {
    history: PropTypes.object.isRequired,
    authStore: PropTypes.object.isRequired,
  };

  componentDidMount() {
    // const user = this.props.authStore.currentUser;
    // this.props.profileStore.getProfile(user.slug);

    if (!this.isSupported) {
      window.ga('set', {
        page: '/not-supported',
        title: 'Not Supported'
      })

      window.ga('send', 'pageview')
    }

    window.hermes = hermes;
    window.authStore = authStore;
    window.mh = this.props.history;
    window.loc = this.props.location;
  }

  async resendVerificationEmail() {
    // some api skermishes here
    const { profileStore } = this.props;
    await this.setState({ sendingEmail: true, emailSent: false });
    try {
      const res = await profileStore.resendVerificationEmail();
      await this.setState({ sendingEmail: false, emailSent: true });
      return res;
    } catch (error) {
      await this.setState({ sendingEmail: false });
      hermes.error(error);
    }
  }

  render() {
    const { authStore } = this.props;
    const { sendingEmail } = this.state;
    let view = null;
    // console.log(this.props.location, 'locations');

    if (authStore.loggedIn) {
      view = (
        <Fragment>
          <Switch>
            {protectedRoutes.map((route, index) => {
              return <PrivateRoute {...route} key={index} />;
            })}
            <Redirect to="/" />
            <Route component={NotFound} />
          </Switch>
        </Fragment>
      );
    } else {
      view = (
        <Fragment>
          <Switch>
            {openRoutes.map((route, index) => {
              return <Route {...route} key={index} />;
            })}
            <Redirect to="/" />
          </Switch>
        </Fragment>
      );
    }

    if (this.isSupported) {
      return (
        <Fragment>
          <PageMask />
          {/*  When refactoring, move all the logic to the component,
          convert it to a class component. It can and should survive
          on its own. */}
          <EmailVerificationBar
            emailSent={this.state.emailSent}
            send={this.resendVerificationEmail}
            sending={sendingEmail}
            loggedIn={authStore.loggedIn}
          />
          <Navbar />
          <ProgressBar />
          {view}
          <Loader />
        </Fragment>
      );
    } else {
      return <NotCompatiblePage />
    }
  }
}

export default withRouter(inject('authStore', 'profileStore')(observer(MainPage)));
