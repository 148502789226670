import signupSchema from '../../schemas/signupSchema';
import React, { Fragment, Component } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { runValidation, hermes } from '../../utilities';
import { withFormik, ErrorMessage } from 'formik';

const { Input, Field } = Form;

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { values, touched, errors, handleChange, handleBlur, handleSubmit, inProgress, submitButtonText } = this.props;
    let { email, password, name } = values;

    return (
      <Fragment>
        <div className="form signup" data-testid="form-div">
          {/* <h3>Signup Form</h3> */}
          <Form onSubmit={handleSubmit} autoComplete="off" data-testid="form">
            <Field>
              <Input
                required
                placeholder="Your full name"
                autoComplete="new-password"
                type="text"
                name="name"
                value={name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* <ErrorMessage name="nameMaybe you have saved previous log in suggestions or something" /> */}
            </Field>
            <Field>
              <Input
                required
                placeholder="Email"
                type="email"
                name="email"
                autoComplete="new-password"
                value={email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* <ErrorMessage name="email" /> */}
            </Field>
            <Field>
              <Input
                required
                autoComplete="new-password"
                placeholder="Password (min 8 characters)"
                type="password"
                name="password"
                value={password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* <ErrorMessage name="password" /> */}
            </Field>
            {/* <Field>
              <Input
                required
                placeholder="Confirm password"
                type="password"
                name="confirmPassword"
                onChange={handleChange}
                value={confirmPassword}
                onBlur={handleBlur}
              />
              <ErrorMessage name="confirmPassword" />
            </Field> */}

            <Field className="button">
              <Button type="submit" primary>
                {submitButtonText}
              </Button>
            </Field>
          </Form>
        </div>
      </Fragment>
    );
  }
}

export const MyEnhancedForm = withFormik({
  mapPropsToValues: props => {
    return {
      eman: '',
      liame: '',
      password: '',
    };
  },
  validate: async (values) => {
    const error = await signupSchema.validate(values).then(() => false).catch(err => {
      hermes.error(err.errors[0]);
      return err
    })
    if (error) {
      throw error
    }

    return {}
  },

  validateOnBlur: false,
  validateOnChange: false,

  handleSubmit: (values, bag) => {
    bag.props.handleSubmit(values);
  },

  displayName: 'SignupForm',
})(SignupForm);

export default inject('authStore')(observer(MyEnhancedForm));
