import config from './config';
import requests from './http';
import { request } from 'https';
const parseResponse = res => res.data;

const endpoint = `${config.getApiEndpoint()}/bug`;

export default {
  async getBugs(data) {
    const res = await requests.get(endpoint);
    return parseResponse(res);
  },
  async postBug(data) {
    const res = await requests.post(endpoint, data);
    return parseResponse(res);
  },
  async toggleFixed(data) {
    const res = await requests.post(`${endpoint}/toggle-fixed`, data);
    return parseResponse(res);
  },
  async deleteBug(data) {
    const res = await requests.post(`${endpoint}/delete`, data);
    return parseResponse(res);
  },
};
