import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import SearchWithBubble from '../../components/Forms/SearchWithBubble';
import { customDebounce, capitalize } from '../../utilities';
import { Form, Input, Checkbox, Button, Dropdown, Card } from 'semantic-ui-react';
import { months } from '../../constants';

const Field = Form.Field;

class WorkFormItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      company: '',
      currentlyWorkHere: false,
      role: '',
      id: props.id,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.searchForCompany = this.searchForCompany.bind(this);
    this.handleResultSelect = this.handleResultSelect.bind(this);
    this.handleAddCompany = this.handleAddCompany.bind(this);

    let currentYear = new Date().getFullYear();

    this.startYearRange = [{ key: 'default', text: 'Start Year', value: null }];
    this.endYearRange = [{ key: 'default', text: 'End Year', value: null }];


    for (let i = currentYear; i >= 1900; i--) {
      this.startYearRange.push({ key: i, text: i, value: i });
      this.endYearRange.push({ key: i, text: i, value: i });
    }

    this.startMonthOptions = [{ key: 'default', text: 'Start Month', value: null }];
    this.endMonthOptions = [{ key: 'default', text: 'End Month', value: null }];

    for (let i = 0; i < 12; i++) {
      this.startMonthOptions.push({ key: i, text: months[i], value: i });
      this.endMonthOptions.push({ key: i, text: months[i], value: i });
    }
  }

  handleChange(option) {
    return async value => {
      await this.setState({ [option]: value });
      this.props.updateParent(this.state);
    };
  }

  handleDateChange(option) {
    return async (e, { value }) => {
      await this.setState({ [option]: value });
      this.props.updateParent(this.state);
    }
  }

  handleChecked(e, { checked: currentlyWorkHere }) {
    this.setState({ currentlyWorkHere });
    // this.props.resetError()
    this.props.resetErrorCheckedButton();
    this.props.updateParent(this.state);
  }

  async searchForCompany(e, { value }) {
    const { onboardingStore } = this.props;
    await this.setState({ company: value });
    this.props.updateParent(this.state);

    customDebounce({
      state: this.state,
      that: this,
      args: [value],
      fn: onboardingStore.searchForCompany,
    });
  }

  async handleResultSelect(e, { result }) {

    await this.setState({
      company: result.name,
      selectedCompany: result,
    });

    this.props.updateParent(this.state);
  }

  handleResultRemove = async () => {
    await this.setState({
      company: '',
      selectedCompany: null,
    });

    this.props.updateParent(this.state);
  }

  async handleAddCompany(name) {
    const data = await this.props.onboardingStore.addCompany({ name });
    await this.setState({ company: data.name, selectedCompany: data });
    this.props.updateParent(this.state);
  }

  render() {
    const { startMonth, startYear, endMonth, endYear, company, role, currentlyWorkHere, selectedCompany, id } = this.state;
    const { onboardingStore, onRemove, addAnother } = this.props;
    const results = [...onboardingStore.computedCompanies];
    const initialCompanySearch = onboardingStore.initialCompanySearch;

    const exists = results[0] && results[0].name.toLowerCase().trim() === company.toLowerCase().trim();

    if (!initialCompanySearch && !exists) {
      results.push({
        key: company,
        name: capitalize(company.trim()),
        isAddition: true,
        onClick: () => this.handleAddCompany(capitalize(company.trim()))
      })
    }


    return (

      <Card className="form-wrapper">
        <div className="worker-form-item">
          <Form autoComplete="off">
            <Field className="field" required>
              <label>{this.props.entriesCount == 1 ? 'Last or current company' : 'Company'}</label>
              <SearchWithBubble
                results={results}
                icon=""
                value={company}
                selectedEntity={selectedCompany && selectedCompany.name}
                onChange={this.searchForCompany}
                onSelect={this.handleResultSelect}
                onRemove={this.handleResultRemove}
                addNewValue={this.handleAddCompany}
                placeholder="Ex: Facebook"
                noResultsMessage={(
                  <p className="searching">Searching for company</p>
                )}
              />
            </Field>

            <div className="work-years">
              <Field className="field" required>
                <label>Start date</label>
                <Dropdown
                  name="startMonth"
                  options={this.startMonthOptions}
                  search
                  selection
                  placeholder="Ex: August"
                  value={startMonth}
                  onChange={(e, { value }) => this.handleChange('startMonth')(value)}
                />
                <Dropdown
                  name="startYear"
                  options={this.startYearRange}
                  search
                  selection
                  placeholder="Ex: 2016"
                  value={startYear}
                  onChange={(e, { value }) => this.handleChange('startYear')(value)}
                />
              </Field>

              {currentlyWorkHere ?
                <span className="present">- Present</span>
                :
                <Field className="field" required>
                  <label>End date</label>
                  <Dropdown
                    name="endMonth"
                    options={this.endMonthOptions}
                    search
                    selection
                    placeholder="Ex: September"
                    value={endMonth}
                    onChange={(e, { value }) => this.handleChange('endMonth')(value)}
                  />
                  <Dropdown
                    name="endYear"
                    options={this.endYearRange}
                    search
                    selection
                    placeholder="Ex: 2018"
                    value={endYear}
                    onChange={(e, { value }) => this.handleChange('endYear')(value)}
                  />
                </Field>
              }
            </div>

            <Field className="field">
              <Checkbox
                label="Tick this box if you work here"
                checked={currentlyWorkHere}
                onChange={this.handleChecked}
              />
            </Field>

            <Field className="field" required>
              <label>Title</label>

              <Input
                type="text"
                id="Role"
                value={role}
                placeholder="Ex: Software Engineer"
                onChange={(e, { value }) => {
                  this.handleChange('role')(value);
                }}
              />
            </Field>

          {onRemove ? <Button primary onClick={()=>onRemove(id)}>Remove</Button> : null}
          {addAnother ? <Button primary onClick={addAnother}>Add</Button> : null}
          </Form>
        </div>
      </Card>
    );
  }
}

export default inject('authStore', 'onboardingStore')(observer(WorkFormItem));
