import React, { Fragment } from 'react';
import Favourite from './Favourite';
import { observer } from 'mobx-react';
const FavouritesTables = observer(({ favourites, inProgress, ...rest }) => {
  const existingFavourites = favourites.filter(user => user.member);

  console.log('hi', {favourites})

  if (favourites.length == 0 && inProgress) {
    return null;
  } else if (favourites.length == 0) {
    return <p />;
  } else {
    return (
      <div className="favourites">
        <header className={existingFavourites.length == 0 ? 'hidden' : 'tablet-and-up'}>
          <div className="user">Person</div>
          <div className="note">Note</div>
        </header>

        {existingFavourites.map(favourite => {
          return <Favourite key={favourite._id} favourite={favourite} {...rest} />;
        })}
      </div>
    );
  }
});

export default FavouritesTables;
