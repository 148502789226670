import React from 'react';
import { Card, Image, Button } from 'semantic-ui-react';
import referenceStore from '../../stores/referenceStore';
import LinkWithLoader from '../LinkWithLoader';

const Person = ({ _id, name, location, bio, image, addNewFavourite, slug }) => {
  let assocation;
  if (
    location &&
    location.currentLocation &&
    location.currentLocation.name &&
    location.currentLocation.name.toLowerCase() == referenceStore.referenceName.toLowerCase()
  ) {
    assocation = 'Residence';
  } else if (
    location &&
    location.visitingLocation &&
    location.visitingLocation.name &&
    location.visitingLocation.name.toLowerCase() == referenceStore.referenceName.toLowerCase()
  ) {
    assocation = 'Visiting';
  } else if (
    location &&
    location.previousLocation &&
    location.previousLocation.name &&
    location.previousLocation.name.toLowerCase() == referenceStore.referenceName.toLowerCase()
  ) {
    assocation = 'Origin';
  }

  return (
    <Card>
      <div className="reference-item">
        <div className="user">
          <Image circular width="60" height="60" src={image || 'https://avatars2.githubusercontent.com/u/44?v=4'} />
          <div className="user-details">
            <div className="name">
              <LinkWithLoader to={`/${slug}`}>{name}</LinkWithLoader>
            </div>
            <div className="location">
              <LinkWithLoader to={`/location/${location.currentLocation.slug}`}>
                {location && location.currentLocation ? location.currentLocation.name : null}
              </LinkWithLoader>
            </div>
            <div className="headline">{bio ? bio.headline : null}</div>
          </div>
        </div>
        <div className="association">
          <span className="mobile-only">Association</span>
          <span>{assocation}</span>
        </div>
        <div className="save">
          <Button
            onClick={() => {
              addNewFavourite(_id);
            }}
            basic
          >
            Save
          </Button>
        </div>
      </div>
    </Card>
  );
};

const LocationTable = ({ users, addNewFavourite }) => {
  return (
    <div className="references">
      <header className={users.length == 0 ? 'hidden' : 'tablet-and-up'}>
        <div className="user">Person</div>
        <div className="association">Association</div>
      </header>

      {users.map((user, index) => {
        // console.log(user);
        return <Person key={index} {...user} addNewFavourite={addNewFavourite} />;
      })}

    </div>
  )
};

export default LocationTable;
