import user from './user.js';
import location from './location.js';
import university from './university.js';
import skill from './skill.js';
import favourite from './favourite.js';
import company from './company.js';
import algolia from './algolia.js';
import reference from './reference';
import meetup from './meetup'
import bug from './bug'
import faq from './faq'
import applications from './applications'
import notifications from './notifications'

export default {
  university,
  favourite,
  reference,
  location,
  company,
  algolia,
  meetup,
  skill,
  user,
  bug,
  faq,
  applications,
  notifications,
};
