import React, { Component, Fragment } from 'react';
import { Container } from 'semantic-ui-react';
import { UserFeedItem, UserFeedMenu, SidebarItem, MeetupChat } from '../../components/UserTimeline';
import { observer, inject } from 'mobx-react';
import { hermes, isOnBottom } from '../../utilities';
import '../../css/userTimeline.css';
import { success } from '../../messages';
import LinkWithLoader from '../../components/LinkWithLoader';

class UserTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socialIconSize: 60,
      activeView: 'all',
      subscriptionsModalOpened: false,
    };
    this.addNewFavourite = this.addNewFavourite.bind(this);
    this.queryForView = this.queryForView.bind(this);
    this.changeActiveView = this.changeActiveView.bind(this);
    this.deleteSignal = this.deleteSignal.bind(this);
  }

  async addNewFavourite(id) {
    const { favouriteStore } = this.props;
    try {
      const response = await favouriteStore.addNewFavourite({ member: id });
      hermes.success(success.ADDED_TO_FAVOURITES);
    } catch (error) {
      hermes.error(error);
    }
  }

  async deleteSignal(_id) {
    const { usertimelineStore } = this.props;
    try {
      const response = await usertimelineStore.deleteSignal(_id);
      hermes.success(success.DELETED_SIGNAL);
    } catch (error) {
      hermes.error(error);
    }
  }

  queryForView(view) {
    const { usertimelineStore } = this.props;
    usertimelineStore.retrieveFeed(view);
  }

  changeActiveView(e, { name: activeView }) {
    const { usertimelineStore } = this.props;
    this.setState({ activeView });
    usertimelineStore.activeView = activeView;
    this.queryForView(activeView);
  }

  componentDidMount() {
    // todo here so the hot reload will be fixed
    window.ga('set', {
      page: '/',
      title: 'User Timeline'
    })
    window.ga('send', 'pageview')
    window.addEventListener('scroll', this.onScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true)
  }

  onScroll = () => {
    const { usertimelineStore } = this.props;

    if (isOnBottom() && !usertimelineStore.endOfResults) {
      usertimelineStore.loadMoreResults(this.state.activeView)
    }
  }

  render() {
    const { usertimelineStore } = this.props;
    let { inProgress, userFeed, meetups, meetup_chats } = usertimelineStore;
    const { activeView, subscriptionsModalOpened } = this.state;
    let children;

    userFeed = userFeed || [];
    if (inProgress) {
      children = null;
    } else if (userFeed.length < 1) {
      children = <div />;
    } else {
      children = userFeed.map((feed, i) => <UserFeedItem {...feed} key={i} addNewFavourite={this.addNewFavourite} deleteSignal={this.deleteSignal} />);
    }

    return (
      <Fragment>
        {/* <Navbar /> */}
        <Container fluid>
          <div className="feed">
            <div className="main">
              <UserFeedMenu
                activeView={activeView}
                changeActiveView={this.changeActiveView}
                subscriptionsModalOpened={subscriptionsModalOpened}
                openSubscriptionModal={this.openSubscriptionModal}
                closeSubscriptionModal={this.closeSubscriptionModal}
              />
              {children}
            </div>


            <div className="sidebar tablet-and-up">

              <section>
                <span className="title">
                  <span>Meetup Chats</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="icon"
                  >
                    <path d="M23.961 8.429c-.831.982-1.614 1.918-1.961 3.775v6.683l-4 2.479v-9.161c-.347-1.857-1.13-2.793-1.961-3.775-.908-1.075-2.039-2.411-2.039-4.629l.019-.345-2.019-1.456-5.545 4-6.455-4v18l6.455 4 5.545-4 5.545 4 6.455-4v-11.618l-.039.047zm-12.961 9.826l-4 2.885v-13.067l4-2.886v13.068zm9-18.255c-2.1 0-4 1.702-4 3.801 0 3.121 3.188 3.451 4 8.199.812-4.748 4-5.078 4-8.199 0-2.099-1.9-3.801-4-3.801zm0 5.5c-.828 0-1.5-.671-1.5-1.5s.672-1.5 1.5-1.5 1.5.671 1.5 1.5-.672 1.5-1.5 1.5z" />
                  </svg>
                </span>

                {meetup_chats.map((item, i) => <MeetupChat key={i} {...item} />)}
              </section>

              
              <section>
                <span className="title">
                  <span>Meetups</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="icon"
                  >
                    <path d="M23.961 8.429c-.831.982-1.614 1.918-1.961 3.775v6.683l-4 2.479v-9.161c-.347-1.857-1.13-2.793-1.961-3.775-.908-1.075-2.039-2.411-2.039-4.629l.019-.345-2.019-1.456-5.545 4-6.455-4v18l6.455 4 5.545-4 5.545 4 6.455-4v-11.618l-.039.047zm-12.961 9.826l-4 2.885v-13.067l4-2.886v13.068zm9-18.255c-2.1 0-4 1.702-4 3.801 0 3.121 3.188 3.451 4 8.199.812-4.748 4-5.078 4-8.199 0-2.099-1.9-3.801-4-3.801zm0 5.5c-.828 0-1.5-.671-1.5-1.5s.672-1.5 1.5-1.5 1.5.671 1.5 1.5-.672 1.5-1.5 1.5z" />
                  </svg>
                </span>

                <div className="links-list">
                  {meetups.map((meetup, i) => {
                    return <SidebarItem key={i /*meetup.id*/} {...meetup} />;
                  })}
                  <div className="end-links">
                    <LinkWithLoader to="/host-meetup">
                      <label>Host Your Own</label>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
                      </svg>
                    </LinkWithLoader>
                  </div>
                </div>
              </section>

              
              <div className="site-info-row">
                <a className="site-info" href="https://instagram.com/icp.community" target="_blank">
                  <i className="fa fa-instagram" />
                  <span>icp.community</span>
                </a>
              </div>
              <div className="site-info-row">
                <a className="site-info" href="https://medium.com/icp-community" target="_blank">
                  <i className="fa fa-medium" />
                  <span>icp.community</span>
                </a>
              </div>
              <div className="site-info-row">
                <a className="site-info" href="https://facebook.com/groups/joinicp" target="_blank">
                  <i className="fa fa-facebook" />
                  <span>groups/joinicp</span>
                </a>
              </div>

              <div className="site-info-row">
                <a className="site-info" href="https://discord.gg/3Mkeue5" target="_blank">
                  <i className="fab fa-discord" />
                  <span>discord.gg/3Mkeue5</span>
                </a>
              </div>


              <div className="site-info-row">
                <LinkWithLoader to="/privacy" className="site-info">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z" />
                  </svg>
                  <span>Privacy</span>
                </LinkWithLoader>
                <span className="divider">·</span>
                <a href="//m.me/Joshua.Vantard" target="_blank" className="site-info">
                  <span>Contact</span>
                </a>
              </div>

            </div>
          </div>
        </Container>
      </Fragment >
    );
  }
}

export default inject('usertimelineStore', 'authStore', 'profileStore', 'subscriptionStore', 'favouriteStore')(
  observer(UserTimeline),
);
