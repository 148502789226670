import config from './config';
import requests from './http';

const parseResponse = res => res.data;

const endpoint = `${config.getApiEndpoint()}/notifications`;

export default {
  async sendNotificationSubscription(data) {
    const res = await requests.post(`${endpoint}/register`, data);
    return parseResponse(res);
  },

};
