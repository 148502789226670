import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Container } from 'semantic-ui-react';
import { hermes } from '../../utilities';
import { errors } from '../../messages';
import api from '../../api';
import Meetup from './Meetup';
import { goTo } from '../../routing';


function SentMeetups({ history, loadingStore }) {
  const { loading } = loadingStore;
  const [meetups, setMeetups] = useMeetups(history, loadingStore)
  

  return (
    <Container style={{marginTop: "50px"}}>
      {meetups.length ? meetups.map((meetup) => <Meetup key={meetup._id} {...meetup} loading={loading} setMeetups={setMeetups} />
      ) : <h1>No Meetups found!</h1>}
    </Container>
  )
}


function useMeetups(history, loadingStore) {
  const [meetups, setMeetups] = useState([])

  useEffect(() =>  {
    loadingStore.setLoading();
    (async () => {

      try {
        const { allowed, meetups } = await api.meetup.getMeetups();
        if (allowed) {
          setMeetups(meetups);
        } else {
          goTo('/', history);
        }
      } catch (e) {
        console.log(e);
        hermes.error(errors.DEFAULT);
      };

      loadingStore.setLoaded();

    })();
  }, [])

  return [meetups, setMeetups];
}

export default withRouter(inject('loadingStore')(SentMeetups));