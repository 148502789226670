import React, {Component} from 'react';
import { Button } from 'semantic-ui-react';
import {inject} from 'mobx-react';
import { withRouter } from 'react-router';
import jwt from 'jsonwebtoken';
import  api from '../../api/config';


class FacebookButton extends Component{
  constructor(props) {
    super(props)
    this.handleResponse = this.handleResponse.bind(this);
    this.facebookMount = this.facebookMount.bind(this);
  }
  async componentDidMount() {
    await this.facebookMount()
  }
 handleResponse() {
      window.location.href = `${api.getBaseUrl()}/api/v1.0/auth/facebook`;
  };

  facebookMount() {
    const search = this.props.history.location.search.split('=');
    const userObject = jwt.decode(search[1])
    if(userObject) {
        this.props.profileStore.uploadFBImage(userObject.picture)
        this.props.handleSuccess(userObject);
    }
  }
  render() {
    // fix to safari mobile bug
    setTimeout(() => {
      this.facebookMount()
    }, 5000);
    return (
      <div>
        <Button 
          style={{ 
            padding: '10px 32px', 
            fontSize: '18px' 
            }} 
          onClick={this.handleResponse}
          color="facebook" 
          size="large">
          Login with Facebook
        </Button>
      </div>
    );

  }
}


export default inject('profileStore')(withRouter( FacebookButton));
