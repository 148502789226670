import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Container } from 'semantic-ui-react';
import EditPersonalInfo from '../../components/Profile/EditPersonalInfo.js';

class EditProfile extends Component {

  componentDidMount() {
    window.ga('set', {
      page: '/profile/edit',
      title: 'Edit Profile'
    })

    window.ga('send', 'pageview')
  }

  render() {
    const { profileStore } = this.props;

    let userData = profileStore.userData;

    if (!userData) return null;

    return (
      <div className="form-template-wrapper">
        <Container fluid id="form-template-container" className="form-template edit-profile">
          <EditPersonalInfo
            userData={userData}
            profileStore={profileStore}
          />
        </Container >
      </div >
    );
  }
}

export default withRouter(inject('profileStore', 'authStore')(observer(EditProfile)));
