import config from './config';
import requests from './http';
const parseResponse = res => res.data;

const endpoint = `${config.getApiEndpoint()}/favourite`;
// if (!offset && !limit) {
//   return requests.get(endpoint);
// } else {
//   let queryArray = [];
//   if (offset) {
//     queryArray.push(`offset=${offset}`);
//   }
//   if (limit) {
//     queryArray.push(`limit=${limit}`);
//   }
//   const urlParams = queryArray.join('&');
//   return requests.get(`${endpoint}?${urlParams}`);
// }
export default {
  getFavourites() {
    return requests.get(endpoint).then(parseResponse);
  },
  getFilteredFavourites(data) {
    return requests.put(`${endpoint}/filter`, data).then(parseResponse);
  },
  addNewFavourite(data) {
    return requests.post(endpoint, data).then(parseResponse);
  },
  deleteFavourite(favouriteId) {
    return requests.delete(`${endpoint}/${favouriteId}`).then(parseResponse);
  },
  updateFavourite(data) {
    return requests.put(endpoint, data).then(parseResponse);
  },
};
