import { Button } from 'semantic-ui-react';
import React, { Fragment, Component } from 'react';
import { inject, observer } from 'mobx-react';
import NewEducationEntry from './NewEducationEntry';
import Education from './Education';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';

import '../../css/profile.css';
import { hermes } from '../../utilities';
import { success } from '../../messages';

const sortEducations = (a, b) => {
  const thisYear = new Date().getFullYear();
  const endA = a.dates && a.dates.end || thisYear;
  const endB = a.dates && a.dates.end || thisYear;
  return endB - endA;
}

class EducationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addEducation: false,
      reordering: false,
      educations: null
    };
  }

  addEducation = () => {
    this.setState({
      addEducation: true,
    });
  };
  cancelAddEducation = () => {
    this.setState({
      addEducation: false,
    });
  };

  startReordering = () => {
    const { profileStore } = this.props;
    const { profile } = profileStore.userData;

    const educations = profile ? 
                          profile.reorderedEducations ? 
                            profile.education
                            :
                            Array.from(profile.education).sort(sortEducations)
                          : 
                          null

    this.setState({ educations, reordering: true })
  }

  saveNewOrder = async () => {
    const { profileStore } = this.props;
    try {
      await profileStore.reorderEducations(this.state.educations)
      this.setState({ reordering: false, educations: null })
      hermes.success(success.REORDERED_EDUCATIONS)
    } catch (e) {
      hermes.error(e);
    }
  }

  cancelReordering = () => {
    this.setState({ reordering: false, educations: null })
  }

  onDragEnd = ({ source, destination }) => {
    if (destination === null) {
      return
    }

    if (source.index === destination.index) {
      return 
    }
    const educations = Array.from(this.state.educations)
    const movedExperience = educations[source.index]
    educations.splice(source.index, 1)
    educations.splice(destination.index, 0, movedExperience)
    console.log(educations)
    this.setState({ educations })
  }

  render() {
    const { otherUser, profileStore } = this.props;
    const { addEducation, reordering } = this.state;
    const { inProgress } = profileStore;

    let userData = null;

    if (!otherUser) {
      userData = profileStore.userData;
    } else {
      userData = this.props.userData;
    }

    let educationStats;
    if (!userData) return null;
    const educations = reordering ? this.state.educations : userData.profile && userData.profile.education

    if (educations) {
      educationStats = educations
        .map((education, i) => {
          console.log(education)
          if (!education._id) {
            return { ...education, _id: i };
          }
          return education;
        })
      if (!userData.profile.reorderedEducations && !reordering) {
        educationStats.sort(sortEducations)
      }
      educationStats = educationStats.map((education, key) => ({
          education, component: <Education key={key} otherUser={otherUser} details={education} inProgress={inProgress} />
      }));
    } else {
      educationStats = null;
    }

    return (
      <Fragment>
        <div className="section">
          <div className="heading">
            <div className="title">Education</div>
            {otherUser ? (
              ''
            ) : (
                <Fragment>
                  {addEducation ? (
                    <Button onClick={this.cancelAddEducation} primary>
                      Cancel
                    </Button>
                  ) : (
                    <Button onClick={this.addEducation} primary>
                      Add
                    </Button>
                  )}
                  {reordering ? (
                    <Fragment>
                      <Button onClick={this.saveNewOrder} primary>
                        Save
                      </Button>
                      <Button onClick={this.cancelReordering} primary>
                        Cancel
                      </Button>
                    </Fragment>
                  ) : (
                    <Button onClick={this.startReordering} primary>
                      Reorder
                    </Button>
                  )}
                </Fragment>
              )}
          </div>
          <div className="section-padding">
            {addEducation ? (
              <NewEducationEntry
                toggleState={() => {
                  //to hide form after successful interaction
                  this.setState({
                    addEducation: false,
                  });
                }}
              />
            ) : null}
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="educations">
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {educationStats.map((education, key) => (
                      <Draggable isDragDisabled={!reordering} draggableId={education.education._id} index={key} key={key}>
                        {provided => (
                          <div className="draggable-card"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {education.component}
                            
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}                    
                  </div>      
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default inject('profileStore', 'onboardingStore')(observer(EducationList));
