import React, { Component } from 'react';
import { Segment, Container, Header, Form, Input, Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { goTo } from '../../routing';
import LocationWithBubble from '../../components/Forms/LocationWithBubble';
import { hermes } from '../../utilities';
import SignalFormToggleButton from './SignalFormToggleButton'

class SignalBroadCast extends Component {
  constructor(props){
    super(props)
    this.state = {
      visitingLocation: true,
      numberOfDaysVisiting: true,
      changingWhereYouReside: true
    }
  }
  handleChange = ({ target: { name } }, { value }) => {
    if (name == 'minutes') {
      this.props.profileStore.updateMinutesToCall(parseInt(value) || '')
    } else if (name == 'days') {
      this.props.profileStore.updateDaysVisitingLocation(parseInt(value) || '')
    }
  }

  handleClick = (type) => () => {
    this.props.profileStore.sendSignal(type)
  }

  handleLocationSelect = type => async value => {
    const { profileStore, loadingStore } = this.props;
    let response;
    if (value) {
      try {
        loadingStore.setLoading()
        response = await profileStore.updateLocation(value);
        profileStore.updateLocationSignalObject(type, response);
        loadingStore.setLoaded();
      } catch (error) {
        console.log(error)
        // hermes.error(error);
      }
    }
    this.handleLocationChange(type + 'Location')((response ? response.name : value) || '')
  };

  handleLocationRemove = type => async () => {
    const { profileStore } = this.props;
    profileStore.updateLocationSignalObject(type, null);
    this.handleLocationChange(type + 'Location')('')
  };

  handleLocationChange = type => value => {
    this.props.profileStore.updateLocationSignalName(type, value);
  }

  submitSignals = () => {
    this.props.profileStore.submitSignals(this.props.history);
  }

  render() {
    const { history, profileStore: { signals }, profileStore } = this.props;
    return  (
      <div className="form-template-wrapper">
      <div className="form-template ui fluid container"> 
        <Header inverted>Signal</Header>
        <Segment>
          <Form onSubmit={this.submitSignals} autoComplete="off">
          <div className="form-section">
          <SignalFormToggleButton name="minutesToCall"
              label= "You'd like to spontaneously call"/>
              {profileStore.formInputsVisibility.minutesToCall? 
              <Form.Field>
                <label className="normalized-font">Number of minutes you'd like to spontaneously call within</label>
                <Input name="minutes" type="number" min="0" onChange={this.handleChange} value={signals.minutesToCall} />
              </Form.Field> : ""}
          </div>

          
          <div className="form-section">
              <SignalFormToggleButton name="visitingALocation" 
              label= "You're visiting a town or city"/>
              {
                profileStore.formInputsVisibility.visitingALocation ? 
                  <Form.Field>
                    <label className="normalized-font">City or town you're visiting</label>
                    <LocationWithBubble
                      name="visitingLocation"
                      value={signals.visitingLocation}
                      selectedEntity={signals.visitingLocationObject.name}
                      handleChange={this.handleLocationChange('visitingLocation')}
                      handleSelect={this.handleLocationSelect('visiting')}
                      handleRemove={this.handleLocationRemove('visiting')}
                      placeholder="Ex: San Francisco"
                    />
                  </Form.Field>
                  : ""}
          </div>
          
          {profileStore.formInputsVisibility.visitingALocation? 
            <div className="form-section">
              <Form.Field>
                  <label className="normalized-font">The number of days you're visiting there</label>
                  <Input
                    name="days"
                    type="number"
                    min="0"
                    value={signals.daysToExpire}
                    onChange={this.handleChange}
                  />
              </Form.Field>
            </div> : ""
          }
          
          <div className="form-section">
            <SignalFormToggleButton name="changingCurrentLocation" 
              label= "You're relocating to a new town or city"/>
              {profileStore.formInputsVisibility.changingCurrentLocation ? 
              <Form.Field>
                <label className="normalized-font">City or town you're relocating to</label>
                <LocationWithBubble
                  value={signals.currentLocation}
                  placeholder="Ex: San Francisco"
                  selectedEntity={signals.currentLocationObject.name}
                  handleChange={this.handleLocationChange('currentLocation')}
                  handleSelect={this.handleLocationSelect('current')}
                  handleRemove={this.handleLocationRemove('current')}
                  className="extra-margin-bottom-15"
                />
              </Form.Field>
               : ""}
          </div>
          <Button primary type="submit">Submit</Button>
          <Button basic type="button" onClick={() => goTo('/', history)}>Go Back</Button>
          </Form>
        </Segment>
      </div>
      </div>  
    )
  }
}

export default withRouter(inject('profileStore', 'loadingStore')(observer(SignalBroadCast)));