import * as yup from 'yup';
import { errors } from '../messages';

export const emailSchema = yup.object().shape({
  email: yup.string().email(),
  currentPassword: yup
    .string()
    .required(errors.CURRENT_PASSWORD_REQUIRED)
    .min(8),
});

export const passwordSchema = yup.object().shape({
  newPassword: yup.string().min(8),
  confirmPassword: yup.string().oneOf([yup.ref('newPassword'), null], errors.PASSWORDS_DONT_MATCH),
  currentPassword: yup
    .string()
    .required(errors.CURRENT_PASSWORD_REQUIRED)
    .min(8),
});
