import React, { Fragment, Component } from 'react';
import IcpLogo from '../../images/icp-logo.svg';
import { withRouter } from 'react-router-dom';
import '../../css/navbar.css';
import { observer, inject } from 'mobx-react';
import { Container, Popup, Image } from 'semantic-ui-react';
import LinkWithLoader from '../LinkWithLoader';

const adminEmail = "joshua.vantard@gmail.com";

const MorePopupItems = [
  {
    title: 'Facebook Group',
    description: 'Brainstorm, ask, poll and connect',
    link: 'https://www.facebook.com/groups/joinicp',
  },

  {
    title: 'Discord Chat',
    description: 'Chat anonymously with voice care-free',
    link: 'https://discord.gg/3Mkeue5',
  },
  
  {
    title: 'Report Bug',
    description: 'Report any glitch to help us improve our platform',
    link: '/report-bug',
    routerLink: true
  },
];

const MorePopupMobileItems = [
  {
    title: 'Meetups',
    description: 'View upcoming meetups, Host your own meetup.',
    link: '/meetups',
    routerLink: true
  },
  {
    title: 'Signal',
    description: "Ping everyone who cares you're in a new city or want to call",
    link: '/signal',
    routerLink: true
  },
  {
    title: 'Listen',
    description: "Get feed and email notifications about signals you care about",
    link: '/subscribe',
    routerLink: true
  },
  {
    title: 'Feed',
    description: 'Get general and specific updates from our members',
    link: '/',
    routerLink: true
  },
  {
    title: 'Saved',
    description: 'Save members privately and take private notes',
    link: '/favourites',
    routerLink: true
  }
].concat(MorePopupItems);


const MoreSectionPopupItem = ({ link, title, description, onClick, routerLink }) => {
  if (routerLink) {
    return (
      <LinkWithLoader to={link} onClick={onClick}>
        <div className="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M20 9.999v-2h4v12.001h-3v4l-5.333-4h-7.667v-4h12v-6.001zm-2 4.001h-9.667l-5.333 4v-4h-3v-14.001h18v14.001z" />
          </svg>
        </div>
        <span className="label">
          {title}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
          </svg>
        </span>
        <span className="description">{description}</span>
      </LinkWithLoader>
    )
  }
  return (
    <a href={link} onClick={onClick} target="_blank">
      <div className="icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M20 9.999v-2h4v12.001h-3v4l-5.333-4h-7.667v-4h12v-6.001zm-2 4.001h-9.667l-5.333 4v-4h-3v-14.001h18v14.001z" />
        </svg>
      </div>
      <span className="label">
        {title}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
        </svg>
      </span>
      <span className="description">{description}</span>
    </a>
  )
};


class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProfilePopupOpen: false,
      isMorePopupOpen: false,
      isMorePopupMobileOpen: false,
      isOnLegal: false,
    }

    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onOpen(option) {
    this.setState({ [option]: true })
  }

  onClose(option) {
    this.setState({
      [option]: false
    })
  }

  componentDidMount() {
    const { location } = this.props;

    if (location.pathname == '/privacy' || location.pathname == '/terms') {
      this.state.isOnLegal || this.setState({ isOnLegal: true })
    } else {
      !this.state.isOnLegal || this.setState({ isOnLegal: false })
    }
  }

  componentDidUpdate() {
    const { location } = this.props;

    if (location.pathname == '/privacy' || location.pathname == '/terms') {
      this.state.isOnLegal || this.setState({ isOnLegal: true })
    } else {
      !this.state.isOnLegal || this.setState({ isOnLegal: false })
    }
  }


  render() {
    const { match, history, authStore } = this.props;

    const currentUserName = authStore.currentUser ? authStore.currentUser.name : 'User';
    const currentUserSlug = authStore.currentUser ? authStore.currentUser.slug : 'user';

    if (!authStore.loggedIn && history.location.pathname == '/') {
      return null;
    } else {
      return (
        <Fragment>
          <div className={"navbar" + ((this.state.isOnLegal && " legal-nav") || "")}>
            <Container fluid>
              <LinkWithLoader to="/" className="icp-logo">
                <img src={IcpLogo} alt="icpLogo" width="24" height="24" />
                <div className="icp-name tablet-and-up">Intelligent Crazy People</div>
              </LinkWithLoader>

              <div className="right">
                {match.path === '/' && !authStore.loggedIn ? (
                  <Fragment>
                    <LinkWithLoader
                      to="/"
                      role="button"
                      onClick={e => {
                        e.preventDefault();
                        authStore.logout();
                      }}
                    >
                      <span className="name">Home</span>
                    </LinkWithLoader>
                  </Fragment>
                ) : (
                    <Fragment>
                      <div className="nav-menu ">
                        <LinkWithLoader to="/" className="tablet-and-up">
                          Feed
                    </LinkWithLoader>

                    <LinkWithLoader to="/favourites" className="tablet-and-up">
                          Saved
                    </LinkWithLoader>

                        <Popup
                          on='click'
                          position='bottom right'
                          basic
                          inverted
                          open={this.state.isMorePopupOpen}
                          onOpen={() => this.onOpen('isMorePopupOpen')}
                          onClose={() => this.onClose('isMorePopupOpen')}
                          verticalOffset={8}
                          className="tablet-and-up"
                          trigger={
                            <a className="tablet-and-up" role="button">
                              <span className="name pointer">
                                More{' '}
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                              </svg>
                            </a>
                          }
                          content={
                            <div className="more-popup">
                              {MorePopupItems.map((item, i) => (
                                <MoreSectionPopupItem {...item} onClick={() => this.onClose('isMorePopupOpen')} key={i} />
                              ))}
                            </div>
                          }
                        />

                        <Popup
                          on='click'
                          position='bottom right'
                          basic
                          inverted
                          open={this.state.isMorePopupMobileOpen}
                          onOpen={() => this.onOpen('isMorePopupMobileOpen')}
                          onClose={() => this.onClose('isMorePopupMobileOpen')}
                          className="wrapper-mobile-popup"
                          trigger={
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="menu-icon mobile-only">
                              <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z" />
                            </svg>
                          }
                          content={
                            <div className="mobile-popup">
                              <header>
                                <LinkWithLoader to="/" className="icp-logo">
                                  <img className="logo" src={IcpLogo} />
                                  <div className="name">Intelligent Crazy People</div>
                                </LinkWithLoader>
                                <div className="close" onClick={() => this.onClose('isMorePopupMobileOpen')}>
                                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
                                  </svg>
                                </div>
                              </header>

                              {MorePopupMobileItems.map((item, i) => (
                                <MoreSectionPopupItem {...item} onClick={() => this.onClose('isMorePopupMobileOpen')} key={i} />
                              ))}
                            </div>
                          }
                        />


                        <LinkWithLoader to="/search" className="search-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="search-icon">
                            <path d="M23.111 20.058l-4.977-4.977c.965-1.52 1.523-3.322 1.523-5.251 0-5.42-4.409-9.83-9.829-9.83-5.42 0-9.828 4.41-9.828 9.83s4.408 9.83 9.829 9.83c1.834 0 3.552-.505 5.022-1.383l5.021 5.021c2.144 2.141 5.384-1.096 3.239-3.24zm-20.064-10.228c0-3.739 3.043-6.782 6.782-6.782s6.782 3.042 6.782 6.782-3.043 6.782-6.782 6.782-6.782-3.043-6.782-6.782zm2.01-1.764c1.984-4.599 8.664-4.066 9.922.749-2.534-2.974-6.993-3.294-9.922-.749z" />
                          </svg>
                        </LinkWithLoader>
                        <Popup
                          on='click'
                          position='bottom right'
                          basic
                          inverted
                          open={this.state.isProfilePopupOpen}
                          onOpen={() => this.onOpen('isProfilePopupOpen')}
                          onClose={() => this.onClose('isProfilePopupOpen')}
                          verticalOffset={-1}
                          trigger={
                            <Image
                              circular
                              src={
                                this.props.profileStore.userData
                                  ? this.props.profileStore.userData.image ? this.props.profileStore.userData.image
                                    : 'https://t4.ftcdn.net/jpg/02/15/84/43/240_F_215844325_ttX9YiIIyeaR7Ne6EaLLjMAmy4GvPC69.jpg'
                                  : 'https://t4.ftcdn.net/jpg/02/15/84/43/240_F_215844325_ttX9YiIIyeaR7Ne6EaLLjMAmy4GvPC69.jpg'
                              }
                              width="38"
                              height="38"
                              className="profile-img"
                              alt="Profile Picture"
                            />
                          }
                          content={
                            <div className="profile-popup">
                              <LinkWithLoader onClick={() => this.onClose('isProfilePopupOpen')} to={`/${currentUserSlug}`}>{currentUserName}</LinkWithLoader>


                              <LinkWithLoader onClick={() => this.onClose('isProfilePopupOpen')} to={`/settings`}>Settings</LinkWithLoader>

                              <LinkWithLoader onClick={() => this.onClose('isProfilePopupOpen')} to="/faq" target="_blank">
                                <span>FAQ</span>
                              </LinkWithLoader>

                              <a
                                role="button"
                                onClick={e => {
                                  e.preventDefault();
                                  this.onClose('isProfilePopupOpen');
                                  authStore.logout();
                                }}
                              >
                                <span>Logout</span>
                              </a>
                            </div>
                          }

                        />
                      </div>
                    </Fragment>
                  )}
              </div>
            </Container>
          </div>
        </Fragment>
      );
    }
  }
}

export default withRouter(inject('usertimelineStore', 'authStore', 'profileStore')(observer(Navbar)));
