import config from './config';
import requests from './http';
const parseResponse = res => res.data;

const endpoint = `${config.getApiEndpoint()}/company`;

export default {
  searchForCompany(name) {
    return requests.get(`${endpoint}/search?q=${name}`).then(parseResponse);
  },
  addCompany(data) {
    return requests.post(endpoint, data).then(parseResponse);
  },
};
