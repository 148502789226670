import { action, observable, decorate } from 'mobx';
import { storage, history, handleError } from '../utilities';
import { axios } from '../api/http';
import api from '../api';
import profileStore from './profileStore';
import usertimelineStore from './usertimelineStore';
import subscriptionStore from './subscriptionStore';
import loadingStore from './loadingStore';

import * as constants from '../constants';
import { goTo } from '../routing';

class AuthStore {
  constructor() {
    this.load();
    this.verifyPassword = this.verifyPassword.bind(this);
    this.logout = this.logout.bind(this);
    this.logUserIntoApplication = this.logUserIntoApplication.bind(this);
    this.loginUser = this.loginUser.bind(this);
    this.syncLoggedInStatus = this.syncLoggedInStatus.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.verifyEmail = this.verifyEmail.bind(this);
    this.verifyMembership = this.verifyMembership.bind(this);
    this.verifyFBIDForUniqueness = this.verifyFBIDForUniqueness.bind(this);
    this.switchUser = this.switchUser.bind(this);
  }

  loggedIn = false;
  signupData = null;
  authenticationToken = null;
  facebookConfirmationDone = true;
  facebookDetails = null;
  currentUser = null;

  async verifyMembership(name) {
    try {
      const response = await api.user.verifyMembership({ name });
      return response;
    } catch (error) {
      throw handleError(error);
    }
  }

  async verifyFBIDForUniqueness(id) {
    try {
      const response = await api.user.verifyFbID({ facebook_id: id });
      return response;
    } catch (error) {
      throw handleError(error);
    }
  }

  async verifyEmail(data) {
    try {
      const response = await api.user.verifyEmail(data);
      return response;
    } catch (error) {
      throw handleError(error);
    }
  }

  load() {
    const user = storage.loadState(constants.currentUser);
    const token = storage.loadState(constants.token);
    const loggedIn = storage.loadState(constants.loggedIn);
    const facebookData = storage.loadState(constants.facebookDetails);
    const signupData = storage.loadState(constants.signupData);

    this.syncLoggedInStatus(loggedIn);
    this.syncSignupData(signupData);
    this.syncFBData(facebookData);
    this.syncToken(token);
    this.syncUser(user);
  }

  resetValues() {
    this.loggedIn = false;
    this.signupData = null;
    this.authenticationToken = null;
    this.facebookConfirmationDone = true;
    this.facebookDetails = null;
    this.currentUser = null;
    loadingStore.setLoaded()
  }

  syncSignupData(data) {
    if (data) {
      storage.updateState(constants.signupData, data);
      this.signupData = data;
    }
  }

  syncToken(token) {
    if (token) {
      storage.updateState(constants.token, token);
      this.authenticationToken = token;
      this.updateHttpHeaders(token);
    }
  }

  syncUser(user) {
    if (user) {
      this.currentUser = user;
      storage.updateState(constants.currentUser, user);
    }
  }

  syncLoggedInStatus(status) {
    status = status || false;
    storage.updateState(constants.loggedIn, status);
    this.loggedIn = status;
  }

  syncFBData(data) {
    if (data) {
      storage.updateState(constants.facebookDetails, data);
      this.facebookDetails = data;
    }
  }

  updateHttpHeaders(token) {
    const commons = axios.defaults.headers.common;
    const payload = `Bearer ${token}`;
    if (token) {
      commons[constants.authorization] = payload;
    }
  }

  async apply(details) {
    try {
      this.syncSignupData(details);
      loadingStore.setLoading()
      const response = await api.user.apply(details);
      this.syncUser(response);
      this.syncToken(response.token);
      loadingStore.setLoaded()
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async signUp(details) {
    try {
      this.syncSignupData(details);
      loadingStore.setLoading()
      const response = await api.user.signUp(details);
      this.syncUser(response);
      this.syncToken(response.token);
      loadingStore.setLoaded()
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async loginUser(details) {
    try {
      loadingStore.setLoading()
      const response = await api.user.login(details);
      this.syncUser(response);
      this.syncToken(response.token);
      await profileStore.getUserData();
      if (!profileStore.userData.signUpPage && !profileStore.userData.isApplying) {
        this.syncLoggedInStatus(true);
      }
      usertimelineStore.getMeetups();
      loadingStore.setLoaded()
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async changeEmail(data) {
    try {
      loadingStore.setLoading()
      const response = await api.user.changeEmail(data);
      this.syncUser(response);
      this.syncToken(response.token);
      loadingStore.setLoaded()
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async changePassword(data) {
    try {
      loadingStore.setLoading()
      const response = await api.user.changePassword(data);
      this.syncUser(response);
      loadingStore.setLoaded()
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  logUserIntoApplication() {
    loadingStore.setLoading()
    const currentUser = storage.loadState(constants.currentUser);
    const token = storage.loadState(constants.token);

    storage.clearStorage();

    this.syncLoggedInStatus(true);
    this.syncUser(currentUser);
    this.syncToken(token);
    loadingStore.setLoaded()
    goTo('/', history);
  }

  logout() {
    loadingStore.setLoading();
    this.resetValues();
    profileStore.userData = null;
    storage.clearStorage();
    goTo('/', history);
    loadingStore.setLoaded();
  }

  async verifyPassword(data) {
    try {
      loadingStore.setLoading();
      const response = await api.user.verifyPassword(data);
      loadingStore.setLoaded();
      return response;
    } catch (error) {
      loadingStore.setLoaded();
      throw handleError(error);
    }
  }

  switchUser(data) {
    try {
      loadingStore.setLoading()
      this.resetValues();
      storage.clearStorage();
      this.syncUser(data);
      this.syncToken(data.token);
      this.syncLoggedInStatus(true);
      loadingStore.setLoaded()
      return data;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }
}

decorate(AuthStore, {
  facebookConfirmationDone: observable,
  facebookDetails: observable,
  syncFacebookDetails: action,
  loggedIn: observable,
  userIsLoggedIn: observable,
  logUserIntoApplication: action,
  syncLoggedInStatus: action,
  currentUser: observable,
  changePassword: action,
  changeEmail: action,
  apply: action,
  signUp: action,
  loginUser: action,
  logout: action,
  verifyPassword: action,
  switchUser: action,
  resetValues: action,
  syncUser: action,
  syncToken: action,
});

export default new AuthStore();
