import { Image, Button, Input, Form, Segment, Header, Popup } from 'semantic-ui-react';
import React, { Component, Fragment } from 'react';
import { hermes, createBlob } from '../../utilities';
import * as settingsSchema from '../../schemas/settingsSchema';
import { withFormik, ErrorMessage } from 'formik';
import ForgotPasswordButton from '../../components/Settings/ForgotPasswordBtn';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import profileStore from '../../stores/profileStore';
import { errors, success } from '../../messages';

class SettingsForm extends Component {
  constructor(props) {
    super(props);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.state = {
      verifyOpen: false
    }
  }

  componentDidMount() {
    this.lastVerificationNumberSent = '';
    this.setState({ verifyOpen: false });
  }

  async handleImageChange(e) {
    await e.persist();
    this.props.handleImageChange(e);
    const filename = this.props.selectedFile;
    this.props.setFieldValue('image', filename);
  }

  handleVerifyClick = async () => {
    const { values: { phoneNumber } } = this.props;

    if (isValidPhoneNumber(phoneNumber)) {
      if (this.lastVerificationNumberSent !== phoneNumber) {
        this.lastVerificationNumberSent = phoneNumber;
        try {
          await this.props.sendVerificationCode(phoneNumber);
        } catch (e) {
          hermes.error(e);
          return
        }
      }
      this.setState({ verifyOpen : true });
    } else {
      hermes.error(errors.INVALID_PHONE_NUMBER)
    }
  }

  handleVerifyClose = () => {
    this.setState({verifyOpen: false});
  }

  handlePhoneNumberChange = (value) => {
    const { setFieldValue } = this.props;
    setFieldValue('phoneNumber', value)
  }

  verifyCode = async () => {
    const { 
      values: {
        phoneNumber,
        verificationCode
      },
      verifyCode,
      setFieldValue
    } = this.props;

    if (verificationCode.length !== 6) {
      hermes.error(errors.INVALID_VERIFICATION_CODE_LENGTH);
      return
    }
    try {
      await verifyCode(phoneNumber, verificationCode);
      setFieldValue('lastPhoneNumbeVerified', phoneNumber);
      this.setState({ verifyOpen: false });
      hermes.success(success.PHONE_VERIFIED)
    } catch(e) {
      hermes.error(errors.INVALID_VERIFICATION_CODE)
    }
  }

  render() {
    const {
      values,
      handleChange,
      handleBlur,
      handleSubmit,
      handleCancel,
      savingSettingsData,
      uploadingImage,
      selectedImage,
      sendingResetLink,
      sendResetEmail,
    } = this.props;

    console.log({props: this.props})
    let { email, image, name, confirmPassword, newPassword, currentPassword, phoneNumber, lastPhoneNumbeVerified, verificationCode } = values;
    image = selectedImage ? createBlob(selectedImage) : image;
    return (
      <Fragment>
        <Header inverted>Settings</Header>
        <Segment>
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Header>
              <Image src={image || 'https://t4.ftcdn.net/jpg/02/15/84/43/240_F_215844325_ttX9YiIIyeaR7Ne6EaLLjMAmy4GvPC69.jpg'} />
              <Header.Content>
                {name}
                <Header.Subheader>
                  <a role="button" onClick={() => { document.querySelector('.sub.header input').click() }}>
                  Change profile photo
                  </a>
                  <Input
                    type="file"
                    name="image"
                    placeholder=" Change Profile Picture"
                    onChange={this.handleImageChange}
                    onBlur={handleBlur}
                  />
                </Header.Subheader>
              </Header.Content>
            </Header>

            <div className="form-section">
              <Form.Field>
                <label>Change your email</label>
                <Input
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={email}
                  placeholder="aaroneisenberg@gmail.com"
                  autoComplete="new-password"
                  type="text"
                  name="email"
                />
                <div className="error-message" hidden>
                  <ErrorMessage name="email" />
                </div>
              </Form.Field>
            </div>

            <div className="form-section">
              <Form.Field>
                <label>Phone number</label>
                <PhoneInput
                  value={phoneNumber}
                  name="phoneNumber"
                  placeholder="Enter your phone number"
                  autoComplete="new-password"
                  onChange={this.handlePhoneNumberChange}
                />
              </Form.Field>
              {phoneNumber !== lastPhoneNumbeVerified ? (
                  <Popup
                    inverted
                    on="click"
                    open={this.state.verifyOpen}
                    onOpen={this.handleVerifyClick}
                    onClose={this.handleVerifyClose}
                    content={
                      <Form className="form-template">
                        <Form.Field>
                          <label>Enter verification code</label>
                          <input name="verificationCode" autoComplete="new-password" value={verificationCode} onChange={handleChange}/>
                        </Form.Field>
                        <Button primary type="button" onClick={this.verifyCode}>Verify</Button>
                      </Form>
                    }
                    trigger={
                      <Button type="button" className="verify-button" primary>Verify</Button>
                    }
                  />
                ): null}
            </div>

            <div className="form-section">
              <Form.Field style={{ marginBottom: "13px" }}>
                <label>Change your password</label>
                <Input
                  value={newPassword}
                  placeholder="At least 8 characters"
                  type="password"
                  name="newPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="error-message" hidden>
                  <ErrorMessage name="newPassword" />
                </div>
              </Form.Field>

              <Form.Field>
                <Input
                  placeholder="Confirm your new password"
                  value={confirmPassword}
                  type="password"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="error-message" hidden>
                  <ErrorMessage name="confirmPassword" />
                </div>
              </Form.Field>

            </div>

            <div className="form-section">
              <Form.Field>
                <label>To save any changes, enter your current password</label>
                <Input
                  value={currentPassword}
                  name="currentPassword"
                  type="password"
                  placeholder="Enter your current password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="error-message" hidden>
                  <ErrorMessage name="currentPassword" />
                </div>
              </Form.Field>

              <div className="forgotten-password">
                <ForgotPasswordButton inProgress={sendingResetLink} sendEmail={sendResetEmail} />
              </div>

            </div>

            
            <Button
              primary
              type="submit"
              disabled={savingSettingsData || uploadingImage}
            >
              Save
            </Button>
            <Button type="button" onClick={handleCancel} basic>Cancel</Button>

          </Form>
        </Segment >
      </Fragment>
    );
  }
}

function createNeatObject({ email, name, image, phoneNumber, verifiedPhoneNumber }) {
  return {
    email: email || '',
    newPassword: '',
    confirmPassword: '',
    image: image,
    name: name,
    currentPassword: '',
    phoneNumber: phoneNumber || '',
    verificationCode: '',
    lastPhoneNumbeVerified: phoneNumber || ''
  };
}

const MyEnhancedForm = withFormik({
  mapPropsToValues: props => {
    return createNeatObject(props);
  },

  validate: async (values) => {
    const { email, phoneNumber, lastPhoneNumbeVerified, newPassword, confirmPassword, currentPassword } = values;

    if (!currentPassword) {
      hermes.error(errors.CURRENT_PASSWORD_REQUIRED)
      throw errors.CURRENT_PASSWORD_REQUIRED;
    }

    if (email !== profileStore.userData.email) {
      const error = await settingsSchema.emailSchema.validate(values).then(() => false).catch(err => {
        hermes.error(err.errors[0]);
        return err
      })
      if (error) {
        throw error
      }
    }

    if (newPassword || confirmPassword) {
      const error = await settingsSchema.passwordSchema.validate(values).then(() => false).catch(err => {
        hermes.error(err.errors[0]);
        return err
      })
      if (error) {
        throw error
      }
    }

    if (phoneNumber) {
      if (!isValidPhoneNumber(phoneNumber)) {
        hermes.error(errors.INVALID_PHONE_NUMBER);
        throw Error('Invalid phone number');
      }

      if (phoneNumber.trim() !== lastPhoneNumbeVerified.trim()) {
        hermes.error(errors.PHONE_VERIFICATION_REQUIRED);
        throw Error('Phone not verified');
      }
    }

    return {};
  },

  validateOnChange: false,
  validateOnBlur: false,

  handleSubmit: async (values, bag) => {
    await bag.props.submitData(values);
  },

  displayName: 'SettingsForm',
})(SettingsForm);

export default MyEnhancedForm;
