import * as yup from 'yup';
import { errors } from '../messages';

const website_regex = /(^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$)/i

const schema = yup.object().shape({
  readMediumArticle: yup.boolean(),
  requestToJoinFacebookGroup: yup.boolean().required().test('requestToJoinFacebookGroup', errors.REQUEST_TO_JOIN_GROUP_REQUIRED, value=>value),
  linkedin: yup.string().trim()
    .required(errors.LINKEDIN_URL_REQUIRED),
  facebook: yup.string().trim()
    .required(errors.FACEBOOK_URL_REQUIRED),
  otherLinks: yup.string(),
  referredBy: yup.string(),
  messagedJoshua: yup.boolean().required().test('messagedJoshua', errors.MESSAGED_JOSHUA_REQUIRED, value=>value),
  fetchDataFromLinkedIn: yup.boolean(),
});

export default schema;