import loginSchema from '../../schemas/loginSchema';
import React, { Fragment, Component } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { withFormik, ErrorMessage } from 'formik';
import { hermes } from '../../utilities';

const { Input, Field } = Form;

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { values, touched, errors, handleChange, handleBlur, handleSubmit, inProgress } = this.props;
    let { email, password } = values;

    return (
      <Fragment>
        <div className="login form" data-testid="form-div">
          {/* <h3>Login Form</h3> */}
          <Form onSubmit={handleSubmit} autoComplete="off" data-testid="form">
            <Field>
              <Input
                required
                placeholder="Enter your email"
                type="email"
                name="email"
                value={email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* <ErrorMessage name="email" /> */}
            </Field>
            <Field>
              <Input
                required
                placeholder="Password (min 8 characters)"
                type="password"
                name="password"
                value={password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* <ErrorMessage name="password" /> */}
            </Field>

            <Field className="center">
              <Button type="submit" primary>
                Login
              </Button>
            </Field>
          </Form>
        </div>
      </Fragment>
    );
  }
}

export const MyEnhancedForm = withFormik({
  mapPropsToValues: props => {
    return {
      email: '',
      password: '',
    };
  },
  validate: async (values) => {
    const error = await loginSchema.validate(values).then(() => false).catch(err => {
      hermes.error(err.errors[0]);
      return err
    })
    if (error) {
      throw error
    }

    return {}
  },

  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, bag) => {
    bag.props.handleSubmit(values);
  },

  displayName: 'LoginForm',
})(LoginForm);

export default inject('authStore')(observer(MyEnhancedForm));
