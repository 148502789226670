import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { errors, success } from '../../messages';
import SignupForm from '../../components/Forms/SignupForm';
import ForgottenPassword from '../../components/Forms/ForgottenPassword';
import LoginForm from '../../components/Forms/LoginForm';
import hermes from '../../utilities/hermes';
import api from '../../api';
import { Button, Icon, Container, Label } from 'semantic-ui-react';
import '../../css/landingPage.css';
import LinkWithLoader from '../../components/LinkWithLoader';
import { goTo } from '../../routing';
import einstein from '../../images/albert-einstein.jpg';
import curie from '../../images/marie-curie.jpg'

const backgroundImages = [
  curie,
  einstein,
]

const ToggleComponent = ({ text, toggleForm, buttonContent }) => {
  return (
    <p className="toggler">
      {text}
      <a onClick={toggleForm}>
        {buttonContent}
      </a>
    </p>
  );
};

class Slide1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socialIconSize: 60,
      bgIndex: 0,
      login: false,
      signup: false,
      isMember: true,
      forgottenPassword: false,
      apply: false,
    };

    this.handleApply = this.handleApply.bind(this);
    this.handleSignup = this.handleSignup.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.sendResetEmail = this.sendResetEmail.bind(this);
  }

  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  // handleToggle(type) {
  //   return e => {
  //     e.preventDefault();
  //     const { login, forgottenPassword } = this.state;
  //     if (type === 'login' || type === 'signup') {
  //       this.setState({
  //         login: !login,
  //         forgottenPassword: false,
  //       });
  //     } else {
  //       this.setState({
  //         forgottenPassword: !forgottenPassword,
  //         login: false,
  //       });
  //     }
  //   };
  // }

  async handleApply(data) {
    const { history, authStore } = this.props;
    try {
      this.setState({ isMember: true });
      await authStore.apply({ ...data, signUpPage: "2" });
      goTo('/onboarding/2', history);
    } catch (e) {
      hermes.error(e);
    }
  }


  async handleSignup(data) {
    const { history, authStore } = this.props;
    try {
      const isMember = await authStore.verifyMembership(data.name);
      if (isMember) {
        this.setState({ isMember: true });
        await authStore.signUp({ ...data, signUpPage: "2" });
        goTo('/onboarding/2', history);
      } else {
        this.setState({ isMember: false });
      }
    } catch (e) {
      hermes.error(e);
    }
  }

  async handleLogin(data) {
    const { history, authStore } = this.props;
    try {
      const response = await authStore.loginUser(data);
      if (authStore.loggedIn) {
        goTo('/', history);
      } else {
        goTo('/onboarding/2', history);
      }
    } catch (e) {
      // console.error(e, 'Slide1');
      hermes.error(errors.LOGIN_FAILED);
    }
  }

  componentDidMount() {
    window.ga('set', {
      page: '/landing',
      title: 'Landing Page'
    })

    window.ga('send', 'pageview')

    this.setState({
      bgInterval: setInterval(this.updateBgIndex, 300000)
    })

    const { authStore, history } = this.props;
    if (authStore.currentUser && authStore.loggedIn) {
      goTo('/', history);
    } else if (authStore.currentUser) {
      hermes.success(success.COMPLETE_SIGNUP);
      goTo('/onboarding/2', history);
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.bgInterval)
  }

  updateBgIndex = () => {
    this.setState({
      bgIndex: (this.state.bgIndex + 1) % backgroundImages.length
    })
  }


  async sendResetEmail(e) {
    const email = e.target.querySelector('input').value;

    console.log(email);

    try {
      const response = await api.user.sendPasswordResetLink({
        email: email
      });
      if (response.sentEmail) {
        hermes.success(success.SENT_PASSWORD_RESET_EMAIL)
        this.setState({ login: true, signup: false, forgottenPassword: false });
      } else {
        hermes.error(errors.EMAIL_NOT_REGISTERED)
      }
    } catch (e) {
      hermes.error(errors.FAILED_SEND_PASSWORD_EMAIL);
    }
    return true;
  }

  render() {
    const { isMember, bgIndex } = this.state;
    const {
      authStore: { inProgress },
      className,
      login,
      signup,
      forgottenPassword,
      apply,
      handleToggle
    } = this.props;

    console.log(this.props);

    let view;
    if (login) {
      view = (
        <Fragment>
          <LoginForm handleSubmit={this.handleLogin} inProgress={inProgress} />
          <ToggleComponent text="Not a member?" buttonContent="Apply >>" toggleForm={handleToggle('apply')} />
          <ToggleComponent buttonContent="Forgot password?" toggleForm={handleToggle('forgottenPassword')} />
        </Fragment>
      );
    } else if (signup) {
      view = (
        <Fragment>
          <SignupForm submitButtonText="Join" handleSubmit={this.handleSignup} />
          {!isMember && <div style={{ color: 'red', textAlign: 'center', margin: '4px 0 10px' }}>
          Use your Facebook name as your name.
          </div>}
          <ToggleComponent
            text="Already have an account?"
            buttonContent="Log In >>"
            toggleForm={handleToggle('login')}
          />
          <ToggleComponent text="Not a member?" buttonContent="Apply >>" toggleForm={handleToggle('apply')} />
        </Fragment>
      );
    } else if (forgottenPassword) {
      view = (
        <Fragment>
          <ForgottenPassword sendResetEmail={this.sendResetEmail} />
          <ToggleComponent
            text="Already have an account?"
            buttonContent="Log In >>"
            toggleForm={handleToggle('login')}
          />
        </Fragment>
      );
    } else if (apply) {
      view = (
        <Fragment>
          <SignupForm submitButtonText="Apply" handleSubmit={this.handleApply} />
          <ToggleComponent
            text="Already have an account?"
            buttonContent="Log In >>"
            toggleForm={handleToggle('login')}
          />
        </Fragment>
      )
    } else {
      view = (
        <Fragment>
          <div className="form-toggler">
            <Button onClick={handleToggle('apply')} primary>
              apply
            </Button>

            <Button onClick={handleToggle('login')} className="landing-secondary">
              Log In
            </Button>
          </div>
        </Fragment>
      );
    }


    return (
        <div className={className + " landing-page-container"}>
          {/* <video autoPlay muted loop id="bg-video">
            <source src="/videos/videoCover.mp4" type="video/mp4" />
          </video> */}
          <div className="landing-page-bg" style={{ backgroundImage: `url(${backgroundImages[bgIndex]})` }}/>
          <div className="landing-page">
            <div className="landing-page-wrapper">

              {/* company tagline */}
              <div className="tagline-container">
                <h2>
                  Building friendships across disciplines
                  <br />
                  <code>~2000 members</code> and <code>~15,000 applications</code>
                  {/* <br />
                  Apply to join our exclusive community */}
                </h2>
              </div>

              {/* form toggler */}
              <div className="width-limit-wrapper">
                {view}
              </div>
            </div>

            {
              // <div className="footer">
              //
              //   <div className="legal-links">
              //     <LinkWithLoader to="/privacy">Privacy Policy</LinkWithLoader>
              //   </div>
              //
              //
              //   <div className="photographer">
              //     <span>Posted by </span> <span className="credit"> Intelligent Crazy Artist | Lihui Wang </span>
              //     <div>
              //       <img src="https://tinyfac.es/data/avatars/B0298C36-9751-48EF-BE15-80FB9CD11143-500w.jpeg" alt="" />
              //     </div>
              //   </div>
              //   </div>
            }

            <div onClick={this.props.handleSlideControl(1)} className="scroll-hint">
              What is Intelligent Crazy People?
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(inject('authStore')(observer(Slide1)));
