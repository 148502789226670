import { action, computed, observable, decorate } from 'mobx';
import { storage, geoCodeAndParse, handleError, runValidation } from '../utilities';
import * as constants from '../constants';
import authStore from './authStore';
import api from '../api';
import loadingStore from './loadingStore';

class OnboardingStore {
  constructor() {
    this.load();
    this.updateBio = this.updateBio.bind(this);
    this.addSkill = this.addSkill.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.updateselectedSkills = this.updateselectedSkills.bind(this);
    this.searchForSkills = this.searchForSkills.bind(this);
    this.addCompany = this.addCompany.bind(this);
    this.searchForCompany = this.searchForCompany.bind(this);
    this.updateAreaOfStudy = this.updateAreaOfStudy.bind(this);
    this.addUniversity = this.addUniversity.bind(this);
    this.searchForUniverity = this.searchForUniverity.bind(this);
    this.syncSkills = this.syncSkills.bind(this);
    this.syncLocation = this.syncLocation.bind(this);
    this.syncOnboardingPage = this.syncOnboardingPage.bind(this);
    this.savePage4Details = this.savePage4Details.bind(this);
    this.savePage5Details = this.savePage5Details.bind(this);
    this.updateName = this.updateName.bind(this);
    this.checkForClaimableData = this.checkForClaimableData.bind(this);
    this.claimData = this.claimData.bind(this)
    this.lookupLocation = this.lookupLocation.bind(this);
    this.toggleDataClaimed = this.toggleDataClaimed.bind(this);
  }

  currentOnboardingPage = 1;
  pageOneState = 1.1;
  selectedLocation = '';
  progressPercentage = 16.6666666667;
  page4Details = null;
  customisedHeadline = '';
  skills = [];
  universitySearchResults = [];
  companySearchResults = [];
  selectedUniversity = '';
  selectedCompany = '';
  selectedSkills = [];
  currentLocation = '';
  previousLocation = '';
  initialSearch = true;
  initialUniversitySearch = true;
  initialCompanySearch = true;
  initialSkillSearch = true;
  dataClaimed = false;

  load() {
    const pageNum = storage.loadState(constants.currentOnboardingPage);
    const pageOneState = storage.loadState(constants.pageOne);
    // const previousLocation = storage.loadState(constants.previousLocation);
    // const currentLocation = storage.loadState(constants.currentLocation);
    const percentage = this.progressPercentage * pageNum;

    this.updateProgressPercentage(percentage);
    this.syncOnboardingPage(pageNum);
    this.syncPage1State(pageOneState);
    // this.syncLocation(constants.previousLocation, previousLocation);
    // this.syncLocation(constants.currentLocation, currentLocation);
  }

  get computedProgressValue() {
    if (this.dataClaimed) {
      return 100;
    }
    return this.currentOnboardingPage * 16.6666666667;
  }

  get computedCustomisedHeadline() {
    return this.customisedHeadline;
  }

  get computedCompanies() {
    const searchResults = Array.from(this.companySearchResults);
    const companyNames = {};
    searchResults.forEach(result => (companyNames[result.name] = result));
    return Object.keys(companyNames).map((name, index) => {
      return {
        id: index,
        _id: companyNames[name]._id,
        title: companyNames[name].name,
        name: companyNames[name].name,
        value: companyNames[name].name,
        usercount: companyNames[name].userCount,
      };
    });
  }

  get computedUniversities() {
    const searchResults = Array.from(this.universitySearchResults);
    const universityNames = {};
    searchResults.forEach(result => (universityNames[result.name] = result));
    return Object.keys(universityNames).map((name, index) => {
      return {
        id: index,
        _id: universityNames[name]._id,
        title: universityNames[name].name,
        name: universityNames[name].name,
        value: universityNames[name].name,
        usercount: universityNames[name].userCount,
      };
    });
  }

  toggleDataClaimed() {
    this.dataClaimed = true;
  }

  updateselectedSkills(data) {
    this.selectedSkills = data;
  }

  updateProgressPercentage(value) {
    if (value) {
      this.progressPercentage = value;
    }
  }

  syncOnboardingPage(pageNum) {
    if (pageNum) {
      this.currentOnboardingPage = pageNum;
      storage.updateState(constants.currentOnboardingPage, pageNum);
    }
  }

  syncPage1State(value) {
    if (value) {
      this.pageOneState = value;
      storage.updateState(constants.pageOne, value);
    }
  }

  syncLocation(type, value) {
    if (value) {
      this[type] = value;
      storage.updateState(constants[type], value);
    }
  }

  async lookupLocation(value) {
    loadingStore.setLoading()
    try {
      const payload = await geoCodeAndParse(value);
      const data = await api.location.lookupLocation(payload);
      loadingStore.setLoaded()
      return data;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async saveLocation(data) {
    try {
      loadingStore.setLoading()
      const response = await api.location.updateLocation(data);
      loadingStore.setLoaded()
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async searchForUniverity(value) {
    let q = encodeURIComponent(value);
    try {
      loadingStore.setLoading()
      this.initialUniversitySearch = true;
      const data = await api.university.searchForUniversity(q);
      loadingStore.setLoaded()
      this.initialUniversitySearch = false;
      this.universitySearchResults = data;
      return data;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async addUniversity(name) {
    try {
      loadingStore.setLoading()
      const data = await api.university.addUniversity(name);
      loadingStore.setLoaded()
      this.selectedUniversity = data.name;
      return data;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  updateAreaOfStudy(id, value) {
    const index = this.studentDetails.majors.findIndex(el => el.id == id);
    this.studentDetails = [
      ...this.studentDetails.majors.slice(0, index),
      { id, text: value },
      ...this.studentDetails.majors.slice(index + 1),
    ];
  }

  async searchForCompany(value) {
    let q = encodeURIComponent(value);
    try {
      loadingStore.setLoading()
      this.initialCompanySearch = true;
      const data = await api.company.searchForCompany(q);
      loadingStore.setLoaded()
      this.initialCompanySearch = false;

      this.companySearchResults = data;
      // console.log(this.companySearchResults, 'com');
      return data;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async addCompany(name) {
    try {
      loadingStore.setLoading()
      const data = await api.company.addCompany(name);
      loadingStore.setLoaded()
      this.selectedCompany = data.name;
      return data;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async addSkill(name) {
    try {
      loadingStore.setLoading()
      const data = await api.skill.addSkill(name);
      loadingStore.setLoaded()
      return data;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async searchForSkills(value) {
    let q = encodeURIComponent(value);

    try {
      loadingStore.setLoading()
      this.initialSkillSearch = true;
      const data = await api.skill.searchForSkill(q);
      this.initialSearch = false;
      this.initialSkillSearch = false;
      loadingStore.setLoaded()
      this.skills = data;
      return data;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async savePage4Details(option, data) {
    let reqType = '';
    if (option == 'student') {
      reqType = 'saveEducation';
    } else {
      reqType = 'saveExperience';
    }

    try {
      loadingStore.setLoading()
      const response = await api.user[reqType](data);
      loadingStore.setLoaded()
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  updateCustomisedHeadline(value) {
    this.customisedHeadline = value;
  }

  async savePage5Details(payload) {
    try {
      loadingStore.setLoading()
      const response = await api.user.updateBio(payload);
      loadingStore.setLoaded()
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  syncSkills(data) {
    if (data) {
      this.skills = data;
    }
  }

  async updateName(name) {
    try {
      loadingStore.setLoading()
      authStore.currentUser.name = name;
      const response = await api.user.updateName({ name });
      authStore.syncUser(response);
      loadingStore.setLoaded()
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async updateFacebookData(data) {
    try {
      loadingStore.setLoading()
      const response = await api.user.updateFacebookData(data);
      authStore.syncUser(response);
      loadingStore.setLoaded()
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async checkForClaimableData(name) {
    try {
      loadingStore.setLoading()
      const response = await api.user.checkForClaimableData(name);
      loadingStore.setLoaded()
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async uploadImage(data) {
    try {
      loadingStore.setLoading()
      const response = await api.user.uploadImage(data);
      loadingStore.setLoaded()
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async updateBio(data) {
    try {
      loadingStore.setLoading()
      const response = await api.user.updateBio(data);
      loadingStore.setLoaded()
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async claimData(name) {
    try {
      loadingStore.setLoading()
      const response = await api.user.claimData(name);
      loadingStore.setLoaded()
      return response;
    } catch (error) {
      loadingStore.setLoaded()
      throw handleError(error);
    }
  }

  async updatePage(page) {
    try {
      await api.user.updateSignUpPage(page);
    } catch (e) {
      console.error("Failed save page number", e);
    }
  }
}

decorate(OnboardingStore, {
  updateFacebookData: action,
  toggleDataClaimed: action,
  dataClaimed: observable,
  initialSkillSearch: observable,
  initialCompanySearch: observable,
  initialUniversitySearch: observable,
  initialSearch: observable,
  computedCompanies: computed,
  selectedCompany: observable,
  workerDetails: observable,
  studentDetails: observable,
  selectedLocation: observable,
  currentOnboardingPage: observable,
  progressPercentage: observable,
  customisedHeadline: observable,
  skills: observable,
  companySearchResults: observable,
  syncPage1State: action,
  syncOnboardingPage: action,
  updateUserDetails: action,
  syncLocation: action,
  saveLocation: action,
  updateCustomisedHeadline: action,
  updateProgressPercentage: action,
  computedProgressValue: computed,
  computedCustomisedHeadline: computed,
  savePage4Details: action,
  syncSkills: action,
  updateName: action,
  checkForClaimableData: action,
  selectedUniversity: observable,
  universitySearchResults: observable,
  searchForUniverity: action,
  addUniversity: action,
  updateAreaOfStudy: action,
  addCompany: action,
  searchForCompany: action,
  searchForSkills: action,
  updateselectedSkills: action,
  uploadImage: action,
  addSkill: action,
  updateBio: action,
  lookupLocation: action,
  claimData: action,
});

export default new OnboardingStore();
