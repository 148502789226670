import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { hermes } from '../../utilities';
import api from '../../api';
import { Container } from 'semantic-ui-react';
import SettingsForm from './SettingsForm';
import '../../css/settings.css';
import { errors, success } from '../../messages';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      uploadingImage: false,
      sendingResetLink: false,
    };
    this.sendResetEmail = this.sendResetEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.ga('set', {
      page: '/settings',
      title: 'Settings'
    })

    window.ga('send', 'pageview')
  }

  async handleSubmit(data) {
    const { selectedFile } = this.state;
    const { authStore, profileStore } = this.props;

    try {
      let result;
      await authStore.verifyPassword({
        password: data.currentPassword,
      });


      if (profileStore.userData.email !== data.email) {
        result = await authStore.changeEmail({ email: data.email });
      }

      if (data.newPassword.length > 0) {
        result = await authStore.changePassword({ password: data.newPassword });
      }

      if (selectedFile) {
        this.setState({ uploadingImage: true });
        result = await profileStore.uploadImage({ selectedFile });
        this.setState({ uploadingImage: false });
      }

      if (result) {
        profileStore.updateUserObject(result);
      }
    } catch (error) {
      return hermes.error(error);
    }

    // take not of the return in the catch block
    hermes.success(success.SETTINGS_UPDATED);
  }

  handleImageChange = e => {
    const fileblob = e.target.files[0];
    this.setState({
      selectedFile: fileblob,
    });
  };

  handleCancel = (e) => {
    const { history } = this.props
    history.goBack();
  }

  async sendResetEmail() {
    await this.setState({ sendingResetLink: true });
    try {
      const response = await api.user.sendPasswordResetLink({
        email: this.props.profileStore.userData.email,
      });
      await this.setState({ sendingResetLink: false });
    } catch (e) {
      await this.setState({ sendingResetLink: false });
      hermes.error(errors.FAILED_SEND_PASSWORD_EMAIL);
    }
    return true;
  }

  render() {
    const { profileStore, authStore } = this.props;
    const { selectedFile, sendingResetLink, uploadingImage } = this.state;

    if (!profileStore.userData) {
      return null;
    } else {
      const { inProgress: profileInProgress } = profileStore;
      const { inProgress: authInProgress } = authStore;
      console.log("Whats this", profileInProgress, authInProgress)
      let savingSettingsData = profileInProgress || authInProgress;
      return (
        <div className="form-template-wrapper">
          <Container fluid className="form-template settings">
            <SettingsForm
              uploadingImage={uploadingImage}
              submitData={this.handleSubmit}
              sendingResetLink={sendingResetLink}
              sendResetEmail={this.sendResetEmail}
              selectedImage={selectedFile}
              handleImageChange={this.handleImageChange}
              handleCancel={this.handleCancel}
              sendVerificationCode={profileStore.sendVerificationCode}
              verifyCode={profileStore.verifyCode}
              savingSettingsData={savingSettingsData}
              {...profileStore.userData}
            />
          </Container>
        </div>

      );
    }
  }
}

export default inject('profileStore', 'authStore')(observer(Settings));
