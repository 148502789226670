import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { Menu, Label } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { mapIndexToReadableTitle, mapReadableTitleToIndex, mapSearchTitleToPlural } from '../../constants';
import { goTo } from '../../routing';

const EntityItem = props => {
  const { title, count, activeView, changeActiveView, history } = props;
  return (
    <Menu.Item
      active={activeView === title.toLowerCase()}
      onClick={() => {
        if (activeView === title.toLowerCase()) {
          return;
        }
        changeActiveView(title.toLowerCase(), history);
      }}
      name={title}
    >
      <span className="title">{mapSearchTitleToPlural[title]}</span>
      <Label className="search-count">{count}</Label>
    </Menu.Item>
  );
};

class EntityFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidUpdate(prevProps) {
    const { history, location, searchStore } = this.props;

    if (location !== prevProps.location) {
      let splitPath = location.pathname.split('/')
      let view = splitPath[2];
      let params;
      let search = ''

      if ((splitPath.length == 3 || (splitPath.length == 4 && splitPath[3] === '/')) && mapReadableTitleToIndex[view]) {
        searchStore.activeView = view
        params = location.search.slice(1);
      }

      let pathname = '/search/' + searchStore.activeView;


      let parsed_params = {}
      if (params) {
        let paramList = params.split('&');
        for (let param of paramList) {
          let sp = param.split('=');
          if (sp.length == 2) {
            let [name, value] = sp;
            parsed_params[name] = decodeURIComponent(value);
          }
        }
      }

      if (parsed_params['q']) {
        search = '?q=' + encodeURIComponent(parsed_params['q']);
        if (parsed_params['q'] !== searchStore.searchValue) {
          searchStore.searchValue = parsed_params['q'];
        }
      } else if (searchStore.searchValue) {
        searchStore.searchValue = '';
      }


      if (!parsed_params['filters']) {
        parsed_params['filters'] = '{}'
      }


      if (searchStore.activeView == 'people') {
        let filters = JSON.parse(parsed_params['filters']);
        let clearedFilters = {};
        for (let key of Object.keys(searchStore.selectedFilters)) {
          searchStore.selectedFilters[key] = [...filters[key] || []];
          searchStore.selectedValues[key] = [...filters[key] || []];
          clearedFilters[key] = [...filters[key] || []];

        }

        for (let key in clearedFilters) {
          if (!clearedFilters[key].length) {
            delete clearedFilters[key];
          }
        }

        if (Object.keys(clearedFilters).length) {
          searchStore.makeFilteredSearch()
          search = (search ? search + '&filters=' : '?filters=') + encodeURIComponent(JSON.stringify(clearedFilters))
          searchStore.qfilters = encodeURIComponent(JSON.stringify(clearedFilters))
        } else {
          searchStore.qfilters = ''
        }

      } else if (searchStore.changedFromClick) {
        searchStore.changedFromClick = false;
      } else {
        searchStore.changeActiveView(searchStore.activeView);
      }

      if (location.pathname !== pathname && location.search !== search) { 
        history.push(pathname + search)
      }
    }
  }


  render() {
    const { searchStore, entities, history } = this.props;
    const { activeView, changeActiveView, peopleCount, qfilters } = searchStore;
    return (
      <Fragment>
        <Menu secondary pointing>
          {entities.map((entity, i) => (
            <EntityItem {...{ ...entity, count: entity.title == "People" && activeView == "people" && qfilters ? peopleCount : entity.count }} key={i} history={history} changeActiveView={changeActiveView} activeView={activeView} />
          ))}
        </Menu>
      </Fragment>
    );
  }
}

// const entity = {
//   title,
//   count,
// };

export default withRouter(inject('searchStore')(observer(EntityFilter)));
