import React, { Component } from 'react';
import { Button, Card, Image } from 'semantic-ui-react';
import { capitalize, hermes } from '../../utilities';
import { inject, observer } from 'mobx-react';
import { DEFAULT_IMAGES } from '../../constants';
import { success } from '../../messages';
import LinkWithLoading from '../LinkWithLoader';

const validResultRegExp = /^[A-Z0-9]|\.NET/i


const PersonSearchItem = props => {
  const { _id, image, name, bio, location, addToFavourites, slug } = props;

  return (
    <Card>
      <div className="people-search-item search-item">
        <div className="left">
          <div>
            <Image
              circular
              width="60"
              height="60"
              src={image == "https://t4.ftcdn.net/jpg/02/15/84/43/240_F_215844325_ttX9YiIIyeaR7Ne6EaLLjMAmy4GvPC69.jpg" ? 'https://res.cloudinary.com/intcrzyppl/image/upload/v1558895698/default_waerse.png' : image}
            />
          </div>
          <div className="info">
            <div className="name">
              <LinkWithLoading to={`/${encodeURIComponent(slug)}`}>
                {name}
              </LinkWithLoading>
            </div>
            <div className="location">
              <LinkWithLoading to={`/location/${location && location.currentLocation ? location.currentLocation.slug : null}`}>
                {location && location.currentLocation ? location.currentLocation.name : null}
              </LinkWithLoading>
            </div>
            <div className="headline">{bio ? bio.headline : null}</div>
          </div>
        </div>
        <div className="right">
          <Button
            onClick={() => {
              addToFavourites(_id);
            }}
            basic
          >
            Save
          </Button>
        </div>
      </div>
    </Card>
  );
};

const ReferenceSearchItem = ({ _id, type, name, img, slug, subscribe }) => {
  let image = img || DEFAULT_IMAGES[capitalize(type)];

  // if (!validResultRegExp.test(name)) return null;

  return (
    <Card>
      <div className="search-item">
        <div className="left">
          <Image
            width="60"
            height="60"
            src={image}
          />
          <div className="info">
            <div className="name">
              <LinkWithLoading to={`/${type.toLowerCase()}/${encodeURIComponent(slug)}`}>{name}</LinkWithLoading>
            </div>
            <div className="location">{capitalize(type)}</div>
          </div>
        </div>

        {/* 
          type.toLowerCase() === 'location' ? (
            <div className="right">
              <Button
                onClick={() => {
                  subscribe(_id, type);
                }}
                basic
              >
                Subscribe
              </Button>
            </div> 
          ) : null 
        */}
      </div>
    </Card>
  );
};

class SearchItem extends Component {
  subscribe = async (id, type) => {
    try {
      await this.props.subscriptionStore.subscribe(id, type);
      hermes.success(success.SUBSCRIBED);
    } catch (error) {
      hermes.error(error);
    }
  };

  render() {
    if (this.props.reference) {
      return <ReferenceSearchItem {...this.props} subscribe={this.subscribe} />;
    } else {
      return <PersonSearchItem {...this.props} />;
    }
  }
}

// const SearchItem = props => {
//   if (props.reference) {
//     return <ReferenceSearchItem {...props} />;
//   } else {
//     return <PersonSearchItem {...props} />;
//   }
// };

export default inject('subscriptionStore')(observer(SearchItem));
export { PersonSearchItem };
