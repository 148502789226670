import loadingStore from './loadingStore';
import { action, observable, decorate, toJS, computed } from 'mobx';
import { storage, mapIndexToReadableTitle, mapReadableTitleToIndex, DEFAULT_USER_IMAGE, DEFAULT_USER_SEARCH_IMAGES } from '../constants';
import { hermes } from '../utilities';
import { errors } from '../messages';
import { searchMultipleIndexes, initIndex } from '../api/algolia' ;
const constants = require('../constants');


function buildQuery(q, that, newSearch) {
  let indexNames = [
    constants.ALGOLIA_COMPANY_INDEX,
    constants.ALGOLIA_LOCATION_INDEX,
    constants.ALGOLIA_UNIVERSITY_INDEX,
    constants.ALGOLIA_SKILL_INDEX,
    constants.ALGOLIA_USER_INDEX,
  ];
  if (!newSearch) {
    indexNames = indexNames.filter(
      e => !that.endOfResults[mapIndexToReadableTitle[e]]
    );
  }
  const hitsPerPage = 20;

  return indexNames.map(index => {
    return {
      indexName: index,
      query: q,
      params: {
        hitsPerPage,
        page: newSearch ? 0 : that.allSearchResults.filter(e => e.index == index)[0].page + 1
      }
    };
  });
}

function buildIndexes() {
  const data = {};
  const indexNames = [
    constants.ALGOLIA_COMPANY_INDEX,
    constants.ALGOLIA_LOCATION_INDEX,
    constants.ALGOLIA_UNIVERSITY_INDEX,
    constants.ALGOLIA_SKILL_INDEX,
    constants.ALGOLIA_USER_INDEX,
  ];

  indexNames.forEach(index => (data[index] = initIndex(index)));

  return data;
}

const indexToObjProp = {
  [constants.ALGOLIA_COMPANY_INDEX]: 'profile.experiences.company.name',
  [constants.ALGOLIA_LOCATION_INDEX]: 'location.currentLocation.name',
  [constants.ALGOLIA_UNIVERSITY_INDEX]: 'profile.education.university.name',
  [constants.ALGOLIA_SKILL_INDEX]: 'bio.skills.name',
  [constants.mapReadableTitleToIndex.graduationYear]: 'profile.education.dates.end',
  [constants.mapReadableTitleToIndex.degreeType]: 'profile.education.degreeType',
};

class SearchStore {
  constructor() {
    this.handler = buildIndexes();
    this.search = this.search.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.makeFilteredSearch = this.makeFilteredSearch.bind(this);
    this.selectFilterEntityValue = this.selectFilterEntityValue.bind(this);
    this.searchForFilterEntity = this.searchForFilterEntity.bind(this);
    this.changeActiveView = this.changeActiveView.bind(this);
    this.searchForEntity = this.searchForEntity.bind(this);
    this.searchForAll = this.searchForAll.bind(this);
    this.updateSearchValue = this.updateSearchValue.bind(this);
    this.makePaginatedQuery = this.makePaginatedQuery.bind(this);
    this.clearAllSearchResults = this.clearAllSearchResults.bind(this);
    this.addToActiveViewResults = this.addToActiveViewResults.bind(this);
  }

  initialSearch = false;
  page = 0;
  /* entity page count */
  // peoplePage = 0;
  // locationsPage = 0;
  // skillsPage = 0;
  // universitiesPage = 0;
  // companiesPage = 0;
  activeIndex = null;
  endOfResults = {};
  firstFilteredQueryMade = false;
  filters = [];
  degreeTypeFilters = [
    { key: 0, title: "Bachelor's", name: "Bachelor's", usercount: 0 },
    { key: 1, title: "Master's", name: "Master's", usercount: 0 },
    { key: 2, title: "PhD", name: "PhD", usercount: 0 },
    { key: 3, title: "Other", name: "Other", usercount: 0 }
  ]
  peopleCount = 0;
  /* entity page count */

  /* generic application containers */
  searchValue = '';
  activeView = 'all';
  allSearchResults = [];
  searchResults = [];
  /* generic application containers */

  /* People filter section */
  filterSearchValue = '';
  selectedValues = {
    [mapReadableTitleToIndex.location]: [],
    [mapReadableTitleToIndex.skill]: [],
    [mapReadableTitleToIndex.university]: [],
    [mapReadableTitleToIndex.company]: [],
    [mapReadableTitleToIndex.degreeType]: [],
    [mapReadableTitleToIndex.graduationYear]: [],
  };
  filterSearchResults = {};
  selectedFilters = {
    [mapReadableTitleToIndex.location]: [],
    [mapReadableTitleToIndex.skill]: [],
    [mapReadableTitleToIndex.university]: [],
    [mapReadableTitleToIndex.company]: [],
    [mapReadableTitleToIndex.degreeType]: [],
    [mapReadableTitleToIndex.graduationYear]: [],
  };

  async search() {
    const promisses = [
      this.searchForAll(this.searchValue)
    ]
  
    
    if (this.activeView == 'people') {
      promisses.push(this.makeFilteredSearch());
    }

    await Promise.all(promisses);
  }


  /* People filter section */
  clearAllSearchResults() {
    Object.keys(this.allSearchResults).forEach(key => {
      this.allSearchResults[key].searchResults = []
    });
  }


  async paginatedFilteredSearch() {
    console.log(this.searchResults);

    if (typeof this.searchResults.page == 'undefined') {
      this.searchResults = this.allSearchResults.filter(result => result.index == 'dev_users')[0];
    }

    if (this.searchResults.page < this.searchResults.nbPages) {
      loadingStore.setLoading()
      const query = this.filteredQueryBuilder({
        page: this.searchResults.page + 1,
      });
      const results = await this.searchByFilter(query);

      this.searchResults = {
        ...results,
        hits: this.searchResults.hits ? this.searchResults.hits.concat(results.hits) : results.hits,
      };
      loadingStore.setLoaded();
    } else {
      this.endOfResults[this.activeView] = true;
    }
  }

  filteredQueryBuilder({ page }) {
    let searchValue = this.searchValue;
    const filters = Object.keys(this.selectedFilters)
      .map(index => {
        let filters = this.selectedFilters[index].map(value => `${indexToObjProp[index]}:${value}`);
        if (filters.length == 1) {
          return filters[0]
        } else {
          return filters
        }
      })
      .filter(value => value.length);

    return {
      query: searchValue,
      facetFilters: filters,
      hitsPerPage: 20,
      page: page,
    };
  }

  async searchByFilter(query) {
    const handler = this.handler[constants.ALGOLIA_USER_INDEX];
    const results = await handler.search(query);
    return results;
  }


  async makeFilteredSearch() {
    this.endOfResults[this.activeView] = false;
    loadingStore.setLoading();

    try {
      const query = this.filteredQueryBuilder({ page: 0 });
      const results = await this.searchByFilter(query);
      this.searchResults = results
      this.peopleCount = this.searchResults.nbHits
    } catch (error) {
      hermes.error(errors.SEARCH_FAILED);
    }
    this.firstFilteredQueryMade = true;
    loadingStore.setLoaded();
  }

  get computedFilterSearchResults() {
    return this.filterSearchResults.facetHits
      ? this.filterSearchResults.facetHits.map((item, index) => {
        return {
          key: index,
          name: item.value,
          value: item.value,
          text: item.value,
          title: item.value,
          usercount: item.count
        };
      })
      : [];
  }

  async searchForFilterEntity(name, query) {
    // if (name.toLowerCase() == mapIndexToReadableTitle.all.toLowerCase()) {
    //   // do nothing when the user clicks on All.
    //   return;
    // }
    const index = this.handler[constants.ALGOLIA_USER_INDEX];
    loadingStore.setLoading();
    this.initialSearch = true;

    try {
      const results = await index.searchForFacetValues({ facetName: indexToObjProp[name], facetQuery: query, maxFacetHits: 4 });
      this.filterSearchResults = results;
    } catch (error) {
      hermes.error(errors.SEARCH_FAILED);
    }
    this.initialSearch = false;
    loadingStore.setLoaded();
  }

  selectFilterEntityValue(index, value) {
    this.selectedValues[index] = [...this.selectedValues[index], value];
  }

  async searchForAll(q, newSearch = true) {
    const query = buildQuery(q, this, newSearch);

    loadingStore.setLoading();
    const { results } = await searchMultipleIndexes(query);

    if (newSearch) {
      this.clearAllSearchResults();
    }

    // loadingStore.setLoading();
    // let endOfResults = true;
    // for (let result of results) {
    //   if (result.nbPages > page) {
    //     endOfResults = false;
    //   }
    // }
    // this.endOfResults[this.activeView] = endOfResults;

    for (const result of results) {
      console.log(result.index, mapIndexToReadableTitle[result.index], this.endOfResults)
      this.endOfResults[mapIndexToReadableTitle[result.index].toLowerCase()] = result.page >= results.nbPages
    }

    try {
      if (!newSearch) {
        this.allSearchResults = this.allSearchResults.map((result, i) => {
          return {
            ...results[i],
            hits: result.hits.concat(results[i].hits)
          }
        });

      } else {
        this.page = 0;

        // This makes the menu stay in the current order
        this.allSearchResults = [
          results.find(result => result.index == mapReadableTitleToIndex.location),
          results.find(result => result.index == mapReadableTitleToIndex.skill),
          results.find(result => result.index == mapReadableTitleToIndex.university),
          results.find(result => result.index == mapReadableTitleToIndex.company),
          results.find(result => result.index == mapReadableTitleToIndex.people),
        ]
      }


    } catch (error) {
      console.log(error);
      hermes.error(errors.SEARCH_FAILED);
    }

    loadingStore.setLoaded();
  }

  async searchForEntity(name, query) {
    const index = this.handler[name];
    loadingStore.setLoading();
    try {
      const results = await index.search({ ...query });
      this.addToActiveViewResults(results);
    } catch (error) {
      console.log(error);
      hermes.error(errors.SEARCH_FAILED);
    }

    loadingStore.setLoaded();
  }

  addToActiveViewResults(results) {
    this.allSearchResults = this.allSearchResults.map(el => {
      if (el.index === mapReadableTitleToIndex[this.activeView]) {
        return {
          ...el,
          hits: el.hits ? el.hits.concat(results.hits) : results.hits,
          page: results.page
        };
      }
      return el;
    })
  }

  get activeViewResults() {
    return this.allSearchResults.filter(
      e => e.index == mapReadableTitleToIndex[this.activeView]
    )[0] || {}
  }

  async makePaginatedQuery() {
    const view = this.activeView;
    const results = this.activeViewResults;


    if (results.page < results.nbPages) {
      loadingStore.setLoading()
      let name = constants.mapReadableTitleToIndex[view];
      await this.searchForEntity(name, {
        query: this.searchValue,
        hitsPerPage: 20,
        page: results.page + 1,
      });
    } else if (this.activeView == 'all') {
      if (!this.endOfResults[this.activeView]) {
        loadingStore.setLoading()
        await this.searchForAll(this.searchValue, false);
      }
    } else {
      this.endOfResults[this.activeView] = true;
    }
    loadingStore.setLoaded();
  }

  changeActiveView(view, history) {

    this.activeView = view;
    let name = constants.mapReadableTitleToIndex[view];

    // reset these four values
    this.endOfResults[this.activeView] = false;
    this.firstFilteredQueryMade = false;

    if (history) {
      history.push('/search/' + this.activeView + history.location.search);
      this.changedFromClick = true;
    }
  }

  updateSearchValue(value, history) {

    this.clearAllSearchResults();
    this.searchResults = {}
    let search = '';
    let q = encodeURIComponent(value);;
    let qfilters = this.qfilters || '';

    if (q && qfilters) {
      search = `?q=${q}&filters=${qfilters}`;
    } else if (q) {
      search = `?q=${q}`
    } else if (qfilters) {
      search = `?filters=${qfilters}`
    }

    history.push(history.location.pathname + search);

    this.searchValue = value;
  }

  updateFilters(filters, history) {
    this.filters = filters;
    this.selectedFilters[this.activeIndex] = [...this.filters];

    const clearedFilters = {};

    for (let key of Object.keys(this.selectedFilters)) {
      if (this.selectedFilters[key].length) {
        clearedFilters[key] = [...this.selectedFilters[key]]
      }
    }


    let q = encodeURIComponent(this.searchValue);

    let qfilters = Object.keys(clearedFilters).length ? encodeURIComponent(JSON.stringify(clearedFilters)) : '';
    this.qfilters = qfilters

    let search = '';

    if (q && qfilters) {
      search = `?q=${q}&filters=${qfilters}`;
    } else if (q) {
      search = `?q=${q}`
    } else if (qfilters) {
      search = `?filters=${qfilters}`
    }

    history.push(history.location.pathname + search)
  }

  updateActiveIndex(index) {
    this.activeIndex = index;
  }
}

decorate(SearchStore, {
  search: action,
  updateActiveIndex: action,
  activeIndex: observable,
  filters: observable,
  updateFilters: action,
  paginatedFilteredSearch: action,
  firstFilteredQueryMade: observable,
  makePaginatedQuery: action,
  endOfResults: observable,
  searchValue: observable,
  peopleCount: observable,
  activeView: observable,
  searchResults: observable,
  allSearchResults: observable,
  initialSearch: observable,
  searchForAll: action,
  searchForEntity: action,
  updateSearchValue: action,
  changeActiveView: action,
  searchForFilterEntity: action,
  selectedValues: observable,
  filterSearchResults: observable,
  clearAllSearchResults: action,
  makeFilteredSearch: action,
  selectFilterEntityValue: action,
  computedFilterSearchResults: computed,
  activeViewResults: computed,
  addToActiveViewResults: action
});

export default new SearchStore();
