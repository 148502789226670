import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Form, Button, Card, Label, Input } from 'semantic-ui-react';
import SearchWithAddition from '../../components/Forms/SearchWithAddition';
import InputWithCounter from '../../components/Forms/InputWithCounter';
import { customDebounce, hermes, capitalize, fbToMsg } from '../../utilities';
import { withFormik, ErrorMessage } from 'formik';
import page5Schema from '../../schemas/page5Schema';
import { goTo } from '../../routing';

const Skill = observer(({ name, removeSkill }) => {
  return (
    <Fragment>
      <Label basic className="entity-label">
        {name}
        <div
          onClick={() => {
            removeSkill(name);
          }}
          className="remove"
        >
          <span>x</span>
        </div>
      </Label>
    </Fragment>
  );
});

class Page5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headline: '',
      skill: '',
      skills: [],
      selectedSkills: [],
      searching: false,
    };

    this.handleResultSelect = this.handleResultSelect.bind(this);
    this.removeSkill = this.removeSkill.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.searchForSkills = this.searchForSkills.bind(this);
    this.addNewSkill = this.addNewSkill.bind(this);
  }

  handleChange(option) {
    return value => {
      // console.log(value, 'va');
      this.setState({ [option]: value });
    };
  }

  async addNewSkill(name) {
    const { onboardingStore } = this.props;
    try {
      const data = await onboardingStore.addSkill({ name });
      this.props.setFieldValue('skill', '');
      this.props.setFieldValue('skills', [...this.props.values.skills, data.name]);
      this.props.setFieldValue('selectedSkills', [...this.props.values.selectedSkills, data]);
    } catch (error) {
      hermes.error(error);
    }
  }

  async searchForSkills(d, { value }) {
    const { onboardingStore } = this.props;
    this.props.setFieldValue('skill', value);

    // change this guy, use the actual fn here
    customDebounce({
      that: this,
      state: this.state,
      args: [value],
      fn: onboardingStore.searchForSkills,
    });
  }

  removeSkill(name) {
    const { skills, selectedSkills } = this.props.values;
    const filteredSelected = selectedSkills.filter(skill => skill.name.toLowerCase() !== name.toLowerCase());
    const filtered = skills.filter(skill => skill.toLowerCase() !== name.toLowerCase());

    this.props.setFieldValue('skills', filtered);
    this.props.setFieldValue('selectedSkills', filteredSelected);
  }

  handleResultSelect(e, { result }) {
    // const { onboardingStore } = this.props;

    this.props.setFieldValue('skill', '');
    this.props.setFieldValue('skills', [...this.props.values.skills, result.name]);
    this.props.setFieldValue('selectedSkills', [...this.props.values.selectedSkills, result]);
  }

  async saveAndContinue(e) {
    e.preventDefault();
    const { onboardingStore, authStore, profileStore, usertimelineStore, subscriptionStore, history } = this.props;
    const payload = {
      existing: this.state.selectedSkills.map(el => el._id),
      headline: this.state.headline,
    };

    try {
      const response = await onboardingStore.savePage5Details(payload);
      onboardingStore.updatePage("");
      if (!profileStore.userData.isApplying) {
        authStore.logUserIntoApplication();
        profileStore.getUserData();
        usertimelineStore.getMeetups();
      } else {
        goTo('/onboarding/6', history);
      }
    } catch (error) {
      hermes.error(error);
    }
  }

  render() {
    const { values, onboardingStore, handleChange, handleBlur, handleSubmit } = this.props;

    let { initialSkillSearch } = onboardingStore;
    const searchOptions = onboardingStore.skills.map((skill, index) => {
      return {
        key: index,
        _id: skill._id,
        title: skill.name,
        name: skill.name,
        text: skill.name,
        value: skill.value,
        usercount: skill.userCount,
      };
    });

    const { skill, headline, facebook, skills } = values;

    const exists = searchOptions[0] && searchOptions[0].name.toLowerCase().trim() === skill.toLowerCase().trim();

    if (!initialSkillSearch) {
      searchOptions.push({
        name: capitalize(skill.trim()),
        isAddition: true,
        onClick: () => this.addNewSkill(capitalize(skill.trim()))
      })
    }

    return (
      <div className="onboarding-5-container">
        <div className="onboarding-heading">Create your profile</div>
        <Card className="profile-form">
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Form.Field required={true} className="headline">
              <label>Add a headline</label>
              <InputWithCounter
                name="headline"
                value={headline}
                onKey
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Ex: Software Engineer at Facebook"
                maxCount={60}
              />
              {/* <ErrorMessage name="headline" /> */}
            </Form.Field>
            <Form.Field required={true} className="skill-form">
              <label>Tag at least 3 skills</label>
              <SearchWithAddition
                icon=""
                className="mlr for-modals"
                results={searchOptions}
                value={skill}
                onChange={this.searchForSkills}
                onSelect={this.handleResultSelect}
                addNewValue={this.addNewSkill}
                placeholder="Ex: Python"
                noResultsMessage={(
                  <p className="searching">Searching for skill</p>
                )}
              />
              <div className="entities-wrapper">
                {skills.map((skill, i) => {
                  return <Skill name={skill} key={i} removeSkill={this.removeSkill} />;
                })}
              </div>
              {/* <ErrorMessage name="selectedSkills" /> */}
            </Form.Field>
            <Form.Field required={true} className="facebook">
              <label>Enter your Facebook profile link</label>
              <Input
                name="facebook"
                value={facebook}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="facebook.com/username"
              />
            </Form.Field>
            <Form.Field>
              <Button type="submit" primary className="save-continue">
                Save & Continue
                </Button>
            </Form.Field>
          </Form>
        </Card>
      </div>
    );
  }
}

const MyEnhancedForm = withFormik({
  mapPropsToValues: props => {
    return {
      headline: '',
      skill: '',
      skills: [],
      facebook: '',
      selectedSkills: [],
    };
  },

  validate: async (values) => {
    const error = await page5Schema.validate(values).then(() => false).catch(err => {
      hermes.error(err.errors[0]);
      return err
    })
    if (error) {
      throw error
    }

    return {}
  },

  handleSubmit: async (values, bag) => {
    console.log(values);
    const { onboardingStore, authStore, profileStore, usertimelineStore, history } = bag.props;
    const payload = {
      existing: values.selectedSkills.map(skill => skill._id),
      headline: values.headline,
      facebook: values.facebook,
      fbMessenger: fbToMsg(values.facebook)
    };

    try {
      await onboardingStore.savePage5Details(payload);
      onboardingStore.updatePage("6")
      goTo('/onboarding/6', history);
    } catch (error) {
      hermes.error(error);
    }
  },

  validateOnChange: false,
  validateOnBlur: false,

  displayName: 'BasicForm',
})(observer(Page5));

export default withRouter(inject('authStore', 'onboardingStore', 'profileStore', 'usertimelineStore', 'subscriptionStore')(observer(MyEnhancedForm)));
