import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { hermes } from '../../utilities';
import { errors } from '../../messages';
import api from '../../api';
import Bug from './Bug';
import { observer, inject } from 'mobx-react';
import { goTo } from '../../routing';
import loadingStore from '../../stores/loadingStore';

function ReportedBugs({ history }) {
  const [bugs, setBugs] = useBugs(history)
  

  return (
    <Container style={{marginTop: "50px"}}>
      {bugs.length ? bugs.map((bug) => <Bug key={bug._id} {...bug} setBugs={setBugs} />
      ) : <h1>No Bugs found!</h1>}
    </Container>
  ) 
}


function useBugs(history) {
  const [bugs, setBugs] = useState([])

  useEffect(() =>  {
    (async () => {
      try {
        const { allowed, bugs } = await api.bug.getBugs();
        if (allowed) {
          setBugs(bugs);
        } else {
          goTo('/', history);
        }
      } catch (e) {
        console.log(e);
        hermes.error(errors.DEFAULT);
      }
    })();
  }, [])

  return [bugs, setBugs];
}

export default inject('loadingStore')(withRouter(ReportedBugs));