import React, { Component } from 'react';
import { Card, Image, Button } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import TextareaAutosize from "react-textarea-autosize";
import LinkWithLoader from '../LinkWithLoader';


class Favourite extends Component {
  constructor(props) {
    super(props);
    this.state = { note: '' };
    this.saveNote = this.saveNote.bind(this);
    this.removeFavourite = this.removeFavourite.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const note = this.props.favourite.note;
    this.setState({ note });
  }

  handleChange(e) {
    this.setState({
      note: e.target.value,
    });
  }

  async saveNote() {
    const { favourite, loadingStore } = this.props;
    const data = {
      _id: favourite._id,
      note: this.state.note,
    };

    loadingStore.setLoading();
    const response = await this.props.saveNote(data);
    loadingStore.setLoaded();
    // this.setState({
    //   note: response.note,
    // });
  }

  removeFavourite() {
    const { favourite } = this.props;
    const data = {
      _id: favourite._id,
    };
    this.props.removeFavourite(data);
  }

  render() {
    const { favourite, inProgress } = this.props;
    const { member } = favourite;
    const { note } = this.state;
    // console.log(member);
    if (!member) {
      return null;
    }

    return (
      <Card>
        <div className="favourite">
          <div className="user">
            <Image circular src={member.image} className="user-image" width="60" height="60" />
            <div className="user-details">
              <div className="name">
                <LinkWithLoader to={`/${member.slug}`}>{member.name}</LinkWithLoader>
              </div>
              <div className="location">
                <LinkWithLoader
                  to={`/location/${member.location && member.location.currentLocation ? member.location.currentLocation.slug : ''}`}
                >
                  {member.location && member.location.currentLocation
                    ? member.location.currentLocation.name
                    : 'No Location'}
                </LinkWithLoader>
              </div>
              <div className="headline">{member.bio ? member.bio.headline : null}</div>
            </div>
          </div>
          <div className="notes">
            <TextareaAutosize
              value={note}
              onChange={this.handleChange}
            />
          </div>
          <div className="control">
            <Button basic onClick={this.saveNote}>
              Save
            </Button>
            <Button basic onClick={this.removeFavourite}>
              Delete
            </Button>
          </div>
        </div>
      </Card>
    );
  }
}

export default inject('loadingStore')(observer(Favourite));
