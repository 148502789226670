import React, { Component } from 'react';
import { Container, Header } from 'semantic-ui-react'
import MeetupChat from '../../components/UserTimeline/MeetupChat'
import SidebarItem from '../../components/UserTimeline/SidebarItem'
import { inject, observer } from 'mobx-react'
import '../../css/meetup.css';
import LinkWithLoader from '../../components/LinkWithLoader';

class Meetup extends Component {
    constructor(props){
        super(props)
    }
    
    render() {
        const { usertimelineStore } = this.props
        const { meetup_chats, meetups } = usertimelineStore;
    
        return (
            <Container className="meetup-page">
                <section>
                    <span className="title">
                        <span>Meetup Chats</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            className="icon"
                        >
                            <path d="M23.961 8.429c-.831.982-1.614 1.918-1.961 3.775v6.683l-4 2.479v-9.161c-.347-1.857-1.13-2.793-1.961-3.775-.908-1.075-2.039-2.411-2.039-4.629l.019-.345-2.019-1.456-5.545 4-6.455-4v18l6.455 4 5.545-4 5.545 4 6.455-4v-11.618l-.039.047zm-12.961 9.826l-4 2.885v-13.067l4-2.886v13.068zm9-18.255c-2.1 0-4 1.702-4 3.801 0 3.121 3.188 3.451 4 8.199.812-4.748 4-5.078 4-8.199 0-2.099-1.9-3.801-4-3.801zm0 5.5c-.828 0-1.5-.671-1.5-1.5s.672-1.5 1.5-1.5 1.5.671 1.5 1.5-.672 1.5-1.5 1.5z" />
                        </svg>
                    </span>

                    {meetup_chats.map((item, i) => <MeetupChat key={i} {...item} />)}
                </section>

                <section>
                <span className="title">
                    <span>Meetups</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            className="icon">
                            <path d="M23.961 8.429c-.831.982-1.614 1.918-1.961 3.775v6.683l-4 2.479v-9.161c-.347-1.857-1.13-2.793-1.961-3.775-.908-1.075-2.039-2.411-2.039-4.629l.019-.345-2.019-1.456-5.545 4-6.455-4v18l6.455 4 5.545-4 5.545 4 6.455-4v-11.618l-.039.047zm-12.961 9.826l-4 2.885v-13.067l4-2.886v13.068zm9-18.255c-2.1 0-4 1.702-4 3.801 0 3.121 3.188 3.451 4 8.199.812-4.748 4-5.078 4-8.199 0-2.099-1.9-3.801-4-3.801zm0 5.5c-.828 0-1.5-.671-1.5-1.5s.672-1.5 1.5-1.5 1.5.671 1.5 1.5-.672 1.5-1.5 1.5z" />
                        </svg>
                    </span>
                    <div className="links-list">
                        {meetups.map((meetup, i) => {
                            return <SidebarItem key={i} {...meetup} />;
                        })}
                    <div className="end-links">
                        <LinkWithLoader to="/host-meetup">
                            <label>Host Your Own</label>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
                            </svg>
                        </LinkWithLoader>
                    </div>
                    </div>
              </section>
            </Container>
        );
    }
}

export default inject('usertimelineStore')(observer(Meetup));