import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { inject } from 'mobx-react';

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        const { authStore } = rest;
        const { loggedIn } = authStore;
        if (loggedIn) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/" />;
        }
      }}
    />
  );
}

export default inject('authStore')(PrivateRoute);
