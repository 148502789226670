import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { goTo } from '../../routing';
import { Button } from 'semantic-ui-react';

class LinkWithLoader extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault();

    
    const { to, history } = this.props;
    if (typeof to !== 'undefined')
      goTo(to, history);
  }
  
  render() {
    const { to, button, onClick, ...rest } = this.props; 

    delete rest.staticContext;
    
    if (button){
      return <Button as='a' href={to} onClick={(...args) => {this.handleClick(...args); onClick && onClick(...args)}} {...rest} />
    }
    return (
      <a href={to} {...rest} onClick={(...args) => {this.handleClick(...args); onClick && onClick(...args)}}/>
    )
  }
}

export default withRouter(LinkWithLoader);

