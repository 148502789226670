// import PrivateRoute from '../../componentsMain/routes/PrivateRoute';
// import {protectedRoutes, openRoutes} from '../../routes';
// import FacebookVerification from '../../components/auth/FacebookVerification';
import Page1 from './Page1'
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import Page5 from './Page5';
import ApplyPage2 from './ApplyPage2';
import Page6 from './Page6'
import {
  Route,
  withRouter,
} from 'react-router-dom';
import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Progress, Container, Divider } from 'semantic-ui-react';
import '../../css/onboarding.css';
import { hermes } from '../../utilities';
import { errors, success } from '../../messages';
import { goTo } from '../../routing';
import  checkForIcognitoOnFirefox from './script'

const SubRouteSelector = ({ match }) => {
  const index = match.params.id;

  const subRoutes = [<Page1 />, <Page2 />, <Page3 />, <Page4 />, <Page5 />, <Page6 />, <ApplyPage2 />];

  return subRoutes[index - 1] ? (
    subRoutes[index - 1]
  ) : (
      <div>
        Nothing Here <span>:)</span>
      </div>
    );
};

class OnboardingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginData: {
        email: null,
        password: null,
      },
      authenticationToken: null,
      userIsLoggedIn: false,
    };

    this.handleLoginSuccess = this.handleLoginSuccess.bind(this);
    this.handleLoginFailure = this.handleLoginFailure.bind(this);
  }

  static propTypes = {
    history: PropTypes.object.isRequired,
    authStore: PropTypes.object.isRequired,
  };

  async componentDidMount() {
    // // check if user is using icognito mode
    // checkForIcognitoOnFirefox()
    window.ga('set', {
      page: '/onboarding/2',
      title: 'Sign up flow'
    })

    window.ga('send', 'pageview')

    const { history, authStore, usertimelineStore, profileStore } = this.props;

    await profileStore.getUserData();

    if (profileStore.userData.signUpPage) return goTo('/onboarding/' + profileStore.userData.signUpPage.split(".")[0] + window.location.search, history);

    if (profileStore.userData.isApplying) return goTo('/onboarding/7', history);

    authStore.logUserIntoApplication();
    profileStore.getUserData();
    usertimelineStore.getMeetups();
  }

  componentDidUpdate(prevProps) {
    // console.log(prevProps.authStore.loggedIn, this.props.authStore.loggedIn);
    // if (prevProps.authStore.loggedIn != this.props.authStore.loggedIn) {
    //   this.props.history.push('/');
    // }
  }

  handleLoginFailure(data) {
    // const {authStore} = this.props;
    // probably show some error modal,
    // yet to be determinded
    hermes.error(errors.FACEBOOK_LOGIN_FAILED);
    // console.log(data, 'failure');
  }

  handleLoginSuccess(data) {
    const { authStore, history } = this.props;
    authStore.syncFacebookDetails({
      ...data,
      successToken: history.location.search,
    });
    hermes.success(success.FACEBOOK_LOGIN_SUCCESS);

    // console.log(data, 'sucess');
  }

  render() {
    const { match, onboardingStore } = this.props;
    const { computedProgressValue } = onboardingStore;
    return (
      <Fragment>
        <Progress percent={computedProgressValue} className="onboarding-progress-bar" />
        <Container fluid>
          <Route path={match.url + '/:id'} component={SubRouteSelector} />
        </Container>
      </Fragment>
    );
  }
}
export default withRouter(inject('authStore', 'onboardingStore', 'profileStore', 'usertimelineStore')(observer(OnboardingPage)));
