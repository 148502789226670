import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import SearchWithAddition from '../Forms/SearchWithAddition';
import { Checkbox, Button } from 'semantic-ui-react';
import { customDebounce } from '../../utilities';
import { inject, observer } from 'mobx-react';

class PeopleFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: '',
      searchValue: '',
      filters: [],
    };
    this.applyFilter = this.applyFilter.bind(this);
    this.addOrRemoveFilter = this.addOrRemoveFilter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  async addOrRemoveFilter(e, { label, checked }) {
    const { updateSelected, index } = this.props;

    updateSelected(index, label, checked)

    let filters = this.state.filters;
    if (checked) {
      await this.setState({
        filters: [...filters, label],
      });
    } else {
      filters = filters.filter(item => item != label);
      await this.setState({ filters });
    }
  }

  componentDidMount() {
    const { selectedValues, index } = this.props;
    this.setState({ filters: selectedValues[index].filter(item => item.checked).map(item => item.label) });
  }

  handleSelect(e, { result }) {
    const { searchStore, index } = this.props;

    // console.log(result.name);
    this.setState({
      searchValue: '',
      selectedValue: result.name,
    });

    this.props.addToSelected(index, result.name)

    this.setState({
      filters: [...this.state.filters, result.name],
    });

    searchStore.selectFilterEntityValue(index, result.name);
  }

  async handleChange(e, { value }) {
    const { searchStore, index } = this.props;
    await this.setState({
      searchValue: value,
    });
    searchStore.filterSearchValue = value;


    customDebounce({
      state: this.state,
      that: this,
      args: [index, value],
      fn: searchStore.searchForFilterEntity,
    });

  }

  applyFilter() {
    const { searchStore, index, history } = this.props;
    const { filters } = this.state;
    searchStore.updateActiveIndex(index);
    searchStore.updateFilters(filters, history);
    console.log({filters})
    searchStore.makeFilteredSearch();
    this.props.updateFilterCount(searchStore.selectedFilters[index]);
  }

  render() {
    const { placeholder, searchStore, selectedValues, index } = this.props;
    const { searchValue } = this.state;
    const results = searchStore.computedFilterSearchResults;
    const initialSearch = searchStore.initialSearch;
    //use computed
    return (
      <Fragment>
        <div className="people-filter">
          <SearchWithAddition
            icon=""
            results={results}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
            placeholder={placeholder}
            value={searchValue}
            addNewValue={e => { }}
            noResultsMessage={!initialSearch ? <p>No Results Found</p> : <p>Searching for {searchValue}</p>}
          />
          <div className="mini-menu">
            <div className="checkbox-wrapper">
              {selectedValues[index].length > 0
                ? selectedValues[index].map((item, index) => {
                  // console.log(item);
                  return (
                    <li key={index}>
                      <Checkbox label={item.label} onChange={this.addOrRemoveFilter} checked={item.checked} />
                    </li>
                  );
                })
                : null}
            </div>


            <Button onClick={this.applyFilter} primary>
              Apply
              </Button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(inject('searchStore')(observer(PeopleFilter)));
