import React, { Component } from 'react';
import { withFormik } from 'formik';
import { withRouter } from 'react-router';
import { Container, Header, Form, Input, Segment, Button, TextArea } from 'semantic-ui-react';
import { hermes } from '../../utilities';
import { errors, success } from '../../messages';
import schema from '../../schemas/reportBugSchema';
import api from '../../api';
import LinkWithLoader from '../../components/LinkWithLoader';


class ReportBug extends Component {
  componentDidMount() {
    window.ga('set', {
      page: '/report-bug',
      title: 'Report Bug'
    });
    window.ga('send', 'pageview');
  }

  render() {
    const { values, handleSubmit, handleChange } = this.props;
    const { browser, description } = values;

    return (
      <div className="form-template-wrapper">
        <Container fluid className="form-template host-a-meetup">
          <Header inverted>Report Bug</Header>
          <Segment>
            <Form onSubmit={handleSubmit} autoComplete="off">
              <div className="form-section">
                <Form.Field>
                  <label>Browser name and version</label>
                  <Input
                    name="browser"
                    value={browser}
                    onChange={handleChange}
                    placeholder="Ex: Safari Version 12.1"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Describe bug</label>
                  <TextArea
                    name="description"
                    value={description}
                    onChange={handleChange}
                    placeholder="Description"
                  />
                </Form.Field>
              </div>

              <Button primary type="submit">Save</Button>
              <LinkWithLoader to='/' secondary button>Cancel</LinkWithLoader>
            </Form>
          </Segment>
        </Container>
      </div >
    )
  }
}

export default withRouter(withFormik({
  mapPropsToValues: () => ({
    browser: '',
    description: ''
  }),

  // Custom sync validation
  validate: async values => {
    const error = await schema.validate(values).then(() => false).catch(err => {
      hermes.error(err.errors[0]);
      return err
    })
    if (error) {
      throw error
    }

    return {}
  },

  handleSubmit: async (values, { setSubmitting }) => {
    try {
      await api.bug.postBug(values);
      hermes.success(success.BUG_REPORTED);
      window.mh.push('/')
    } catch (e) {
      console.log(e);
      hermes.error(errors.DEFAULT)
    }
    setSubmitting(false);
  },

  validateOnBlur: false,
  validateOnChange: false

})(ReportBug));
