import * as yup from 'yup';
import { errors } from '../messages';

const currentDate = new Date()
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth();

const schema = yup.object({
  company: yup
    .string()
    .min(1)
    .required(),
  startYear: yup.number().integer().required(errors.MISSING_START_YEAR),
  startMonht: yup.number().integer().max(12),
  currentlyWorkHere: yup.boolean(),
  endYear: yup.mixed().when('currentlyWorkHere', {
    is: true, // alternatively: (val) => val == true
    then: yup.number().integer(),
    otherwise: yup.number().integer().max(currentYear).required(errors.MISSING_END_YEAR),
  }),
  endMonht: yup.mixed().when('endYear', {
    is: endYear => endYear == currentYear,
    then: yup.number().integer().max(currentMonth),
    otherwise: yup.number().integer()
  }),
  title: yup
    .string()
    .min(1)
    .required(),
  description: yup.string().max(120),
  selectedCompany: yup.object().required(),
});

export default schema;
