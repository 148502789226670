import { Button, Label, Divider } from 'semantic-ui-react';
import SearchWithAddition from '../../components/Forms/SearchWithAddition';
import React, { Fragment, Component } from 'react';
import { inject, observer } from 'mobx-react';
import '../../css/profile.css';
import {
  generateEmtpyEntityMessage,
  generateConsiderAdding,
  customDebounce,
  capitalize,
  hermes,
} from '../../utilities';
import _ from "lodash";
import LinkWithLoader from '../LinkWithLoader';

const searchOptions = [
  { key: 'aa', value: 'aa', text: 'Android' },
  { key: 'as', value: 'as', text: 'React' },
  { key: 'ad', value: 'ad', text: 'iOS' },
  { key: 'af', value: 'af', text: 'UI/UX' },
  { key: 'ag', value: 'ag', text: 'Vue' },
  { key: 'ah', value: 'ah', text: 'Angular' },
];

const Skill = observer(({ name, editSkills, removeSkill, slug }) => {
  // console.log(slug, 'slug');

  return (
    <Label className="entity-label" as={LinkWithLoader} {...editSkills ? '' : { to: `/skill/${slug}` }} basic>
      {name}
      {editSkills ? (
        <div
          onClick={() => {
            removeSkill(name);
          }}
          className="remove"
        >
          <span>x</span>
        </div>
      ) : (
          ''
        )}
    </Label>
  );
});

class SkillsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skill: '',
      selectedSkill: '',
      editSkills: false,
      firstSearchMade: false,
    };
    this.updateSkills = this.updateSkills.bind(this);
    this.removeSkill = this.removeSkill.bind(this);
    this.addNewSkill = this.addNewSkill.bind(this);
    this.searchForSkills = this.searchForSkills.bind(this);
    this.handleResultSelect = this.handleResultSelect.bind(this);
  }

  searchForSkills(e, { value }) {
    const { onboardingStore } = this.props;
    this.setState({
      skill: value,
    });

    customDebounce({
      that: this,
      state: this.state,
      fn: onboardingStore.searchForSkills,
      args: [value],
    });
  }

  handleResultSelect(e, { result }) {
    const { profileStore } = this.props;


    for (const skill of profileStore.userData.bio.skills) {
      if (result.name.toLowerCase() == skill.name.toLowerCase()) {
        return;
      }
    }

    this.setState({
      skill: '',
      selectedSkill: result,
    });

    const bioData = profileStore.userData.bio;

    profileStore.userData.bio && profileStore.userData.bio.skills
      ? profileStore.userData.bio.skills.push(result)
      : (profileStore.userData.bio = {
        ...(bioData ? profileStore.userData.bio : {}),
        skills: [result],
      });
  }

  async addNewSkill(name) {
    const { onboardingStore, profileStore } = this.props;
    try {
      const response = await onboardingStore.addSkill({ name });
      await this.setState({
        skill: '',
        selectedSkill: response,
      });
      const bioData = profileStore.userData.bio;
      profileStore.userData.bio && profileStore.userData.bio.skills
        ? profileStore.userData.bio.skills.push(response)
        : (profileStore.userData.bio = {
          ...(bioData ? profileStore.userData.bio : {}),
          skills: [response],
        });
    } catch (error) {
      hermes.error(error);
    }
  }

  async updateSkills() {
    const { profileStore, onboardingStore } = this.props;
    if (profileStore.userData.bio.skills.length > 0) {
      const existing = profileStore.userData.bio.skills.map(skill => skill._id);
      try {
        const response = await onboardingStore.updateBio({ existing });
      } catch (error) {
        hermes.error(error);
      }
    } else {
      // do nothing
    }
    this.editSkills();
  }

  removeSkill(name) {
    const { profileStore } = this.props;
    const existing = profileStore.userData.bio.skills.filter(skill => skill.name != name);
    profileStore.userData.bio.skills = existing;
  }

  editSkills = () => {
    this.setState({
      editSkills: !this.state.editSkills,
    });
  };

  render() {
    const { otherUser, onboardingStore, profileStore } = this.props;
    const { initialSearch } = onboardingStore;
    const { editSkills, skill } = this.state;
    const userData = otherUser ? profileStore.profileData : profileStore.userData;

    let subMessage = otherUser ? generateEmtpyEntityMessage('Skill') : generateConsiderAdding('Skill');

    const searchOptions = onboardingStore.skills.map((skill) => {
      return {
        _id: skill._id,
        title: skill.name,
        name: skill.name,
        text: skill.name,
        value: skill.value,
        usercount: skill.userCount,
      };
    });

    const exists = searchOptions[0] && searchOptions[0].name.toLowerCase().trim() === skill.toLowerCase().trim();

    if (!initialSearch && !exists) {
      searchOptions.push({
        name: capitalize(skill.trim()),
        isAddition: true,
        onClick: () => this.addNewSkill(capitalize(skill.trim()))
      })
    }

    if (!userData) {
      return null;
    }

    return (
      <div className="section">
        <div className="heading">
          <div className="title">Skills</div>

          {otherUser ? (
            ''
          ) : (
              <Fragment>
                {editSkills ? (
                  <Fragment>

                    <div className="control">
                      <SearchWithAddition
                        icon=""
                        results={searchOptions}
                        value={skill}
                        onChange={this.searchForSkills}
                        onSelect={this.handleResultSelect}
                        addNewValue={this.addNewSkill}
                        placeholder="Add a skill"
                        noResultsMessage={(
                          <p>Searching for {skill}</p>
                        )}
                      />
                    </div>
                    <div className="control">
                      <Button onClick={this.updateSkills} primary>
                        Save
                    </Button>
                    </div>
                    <div className="control">
                      <Button onClick={this.editSkills} primary>
                        Cancel
                      </Button>
                    </div>
                  </Fragment>
                ) : (
                    <div className="control">
                      <Button onClick={this.editSkills} primary>
                        Edit
                      </Button>
                    </div>
                  )
                }
              </Fragment>

            )}
        </div>

        <div className="entities-wrapper">
          {userData.bio && userData.bio.skills && userData.bio.skills.length > 0
            ? userData.bio.skills.map((skill, i) => {
              return <Skill {...skill} key={i} removeSkill={this.removeSkill} editSkills={editSkills} />;
            })
            : subMessage}
        </div>
      </div >
    );
  }
}

export default inject('profileStore', 'authStore', 'onboardingStore')(observer(SkillsContainer));
