import React, { Fragment } from 'react';
import { Form, Button } from 'semantic-ui-react';
const { Input, Field } = Form;

export const ForgottenPassword = ({ sendResetEmail }) => {
  return (
    <Fragment>
      <div className="form forgetton-password" data-testid="form-div">
        <Form onSubmit={sendResetEmail} autoComplete="off" data-testid="form">
          <Field>
            <Input type="email" placeholder="Enter your mail" required />
          </Field>
          <Field className="button">
            <Button primary>Send password reset info</Button>
          </Field>
        </Form>
      </div>
    </Fragment>
  );
};

export default ForgottenPassword;
