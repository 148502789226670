import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Container } from 'semantic-ui-react';
import { slugify } from '../../utilities';

function FAQ({ faqStore }) {
  const { questions } = faqStore;

  return (
    <Container className="legal-page">
      <h1>FAQ</h1>
      <ol>
        {questions.map((q, i) => (
          <li key={i}><a href={`#${slugify(q.question)}`}>{q.question}</a></li>
        ))}
      </ol>
      {questions.map((q, i) => (
        <div key={i}>
          <h2 id={slugify(q.question)}>{q.question}</h2>
          <p>{q.answer}</p>
        </div>
      ))}
    </Container>
  )
}

export default inject('faqStore')(observer(FAQ));
