import React, { Fragment } from 'react';
import { Card, Image, Button } from 'semantic-ui-react';
import { mapDegreeToRanking, mapDegreeWithSubTypes } from '../../constants';
import LinkWithLoader from '../LinkWithLoader';

function getRanking(education) {
  if (mapDegreeWithSubTypes.bachelors.includes(education.degreeType)) {
    return mapDegreeToRanking.bachelors;
  }
  if (mapDegreeWithSubTypes.masters.includes(education.degreeType)) {
    return mapDegreeToRanking.masters;
  }
  if (mapDegreeWithSubTypes.phd.includes(education.degreeType)) {
    return mapDegreeToRanking.phd;
  }
  return 0;
}

const Person = ({ _id, name, location, bio, image, addNewFavourite, slug, profile, entitySlug }) => {

  
  let school;
  // find schools that match current school and filter by highest degree type
  school = profile && profile.education && profile.education
    .filter(edu => {
      if (edu.university) {
        return edu.university.slug == entitySlug;
      }
    })
     .reduce((prev, next) => {
       return getRanking(prev) > getRanking(next) ? prev : next;
    }, {});
    
  const year = school && school.dates ? school.dates.end : null;
  return (
    <Card>
      <div className="reference-item">
        <div className="user">
          <Image circular width="60" height="60" src={image || 'https://avatars2.githubusercontent.com/u/44?v=4'} />
          <div className="user-details">
            <div className="name">
              <LinkWithLoader to={`/${slug}`}>{name}</LinkWithLoader>
            </div>
            <div className="location">
              <LinkWithLoader to={`/location/${location.currentLocation.slug}`}>
                {location && location.currentLocation ? location.currentLocation.name : ''}
              </LinkWithLoader>
            </div>
            <div className="headline">{bio ? bio.headline : null}</div>
          </div>
        </div>

        <div className="year">
          <span className="mobile-only">Final year</span>
          <span>{year ? year : 'Not specified'}</span>
        </div>

        <div className="level">
          <span className="mobile-only">Level</span>
          <span>{school && school.degreeType ? school.degreeType : 'Other'}</span>
        </div>

        <div className="save">
          <Button
            onClick={() => {
              addNewFavourite(_id);
            }}
            basic
          >
            Save
          </Button>
        </div>


      </div>
    </Card>
  );
};

const UniversityTable = ({ users, slug, addNewFavourite }) => {
  return (
    <div className="references universities">

      <header className={users.length == 0 ? 'hidden' : 'tablet-and-up'}>
        <div className="user">Person</div>
        <div className="year">Final year</div>
        <div className="level">Level</div>
      </header>

      {users.map((user, index) => (
        <Person key={index} {...user} addNewFavourite={addNewFavourite} entitySlug={slug} />
      ))}
    </div>
  );
};

export default UniversityTable;
