import { hermes } from '../../utilities';

// const checkForIcognitoOnFirefox = () => {
//   const openRequest = window.indexedDB.open('firefox-private-test', 1);
//   let response
//   console.log(openRequest, '........//////')
//   openRequest.onerror = function (evt) {
//     if (evt.target.error.message.includes('aborted')) {
//       // response = false
//       return false;
//     } else {
//       const opts = {
//         timeout: 3000
//       };
//       console.log('hhhhhhhhhyrtvvvggsgfh')
//       // hermes.error('To continue with your regstration, kindly activated normal browser mode', opts);
//       response = true
//       return true;
//     }
//   };
//   return response
// };
const checkForIcognitoOnFirefox = () =>{
  const db =  indexedDB.open("firefox-private-test", 1)
  // this returns when on icognito mode on firefox
  db.onerror =  function() {
   return  true
  }
  db.onsuccess =  function() {
    return  false
  }
    if (db.onerror()) {
      return true
    } else {
      return false
    }
}

export default checkForIcognitoOnFirefox;
