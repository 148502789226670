import config from './config';
import requests from './http';

const parseResponse = res => res.data

const endpoint = `${config.getApiEndpoint()}/user/applications`

export default {
    async getApplications(){
        const res = await requests.get(endpoint)
        return parseResponse(res)
    },

    async acceptApplication(applicantId){
        const res = await requests.put(endpoint, {applicantId})
        return parseResponse(res)
    }
}