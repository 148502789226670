export default {
  getBaseUrl() {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3002';
  },
  getApiEndpoint() {
    return `${this.getBaseUrl()}/api/${this.apiVersion}`;
  },
  getApiEndpointWithoutProtocol() {
    return `${this.getBaseUrl().replace(/^https?:\/\//i, '')}/api/${this.apiVersion}`;
  },
  apiVersion: 'v1.0',
};
