import React, { Fragment } from 'react';
import { Dropdown, Button, Header, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import '../../css/sectionHeader.css';

const _Header = ({ locations, names, handleLocationChange, handleNameChange, filter }) => {
  return (
    <div className="column">
      <Header as="h2" textAlign="center"> Saved </Header>
      <div className="favourite-filter">
        <Dropdown
          onChange={handleNameChange}
          placeholder="Select a person"
          fluid
          search
          selection
          options={names || []}
        />

        <Dropdown
          placeholder="Select a location"
          search
          fluid
          selection
          onChange={handleLocationChange}
          options={locations || []}
        />

        <Button
          onClick={() => {
            filter();
          }}
          primary
        >
          Filter
          </Button>
      </div>
    </div>
  );
};

_Header.propTypes = {
  locations: PropTypes.array,
  names: PropTypes.array,
};

export default _Header;
