import config from './config';
import requests from './http';
const parseResponse = res => res.data;

const endpoint = `${config.getApiEndpoint()}/meetup`;

export default {
  async getMeetups(data) {
    const res = await requests.get(endpoint);
    return parseResponse(res);
  },
  async postMeetup(data) {
    const res = await requests.post(endpoint, data);
    return parseResponse(res);
  },
  async deleteMeetup(data) {
    const res = await requests.post(`${endpoint}/delete`, data);
    return parseResponse(res);
  },
};
