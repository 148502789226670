import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { Button, Form, Header, Segment } from 'semantic-ui-react';
import SubscriptionFormItem from './SubscriptionFormItem';
import SubscriptionFormToggleItem from './SubscriptionFormToggleItem'
import { inject, observer } from 'mobx-react';
import { hermes } from '../../utilities';
import { success } from '../../messages';
import { goTo } from '../../routing';

class SubscriptionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.updateSubscriptions = this.updateSubscriptions.bind(this);
  }

  async updateSubscriptions() {
    const { subscriptionStore } = this.props;
    const { history } = this.props;

    try {
      const response = await subscriptionStore.updateSubscriptions();
      hermes.success(success.SUBSCRIPTIONS_SAVED);
      goTo('/', history);
    } catch (error) {
      hermes.error(error);
    }
  }

  render() {
    const { subscriptionStore, history } = this.props;
    const { subscriptions, entities, loadingSubscriptions, inProgress } = subscriptionStore;
    let children = null;
    if (subscriptions.length == 0 && loadingSubscriptions) {
      children = null;
    } else {
      children = Object.keys(entities).map((entityName, i) => {
        if (entities[entityName].type == 'toggle') {
          return <SubscriptionFormToggleItem entity={entities[entityName]}  key={i} />
        }
        return <SubscriptionFormItem entityType={entities[entityName]} key={i} />;
      });
    }
    return (
      <Fragment>
        <Header inverted>Listen To Members</Header>
        <Segment >

          <Form autoComplete="off">
            {children}
            <Button disabled={inProgress} onClick={this.updateSubscriptions} primary>Save</Button>
            <Button basic onClick={() => goTo('/', history)}>Cancel</Button>
          </Form>
        </Segment>
      </Fragment>
    );
  }
}

export default withRouter(inject('usertimelineStore', 'subscriptionStore')(observer(SubscriptionForm)));
