import Pages from '../pages';

export const protectedRoutes = [
  {
    path: '/',
    exact: true,
    component: Pages.UserTimeline,
  },
  {
    path: '/settings',
    exact: true,
    component: Pages.Settings,
  },
  {
    path: '/favourites',
    exact: true,
    component: Pages.Favourite,
  },
  {
    path: '/company',
    component: Pages.Reference,
  },
  {
    path: '/university',
    component: Pages.Reference,
  },
  {
    path: '/skill',
    component: Pages.Reference,
  },
  {
    path: '/location',
    component: Pages.Reference,
  },
  {
    path: '/search',
    component: Pages.Search,
  },
  {
    path: '/verify-email/:id',
    component: Pages.EmailVerification,
    exact: true,
  },
  {
    path: '/subscribe',
    component: Pages.SubscribePage,
    exact: true
  },
  {
    path: '/profile/edit',
    component: Pages.EditProfile,
    exact: true
  },
  {
    path: '/privacy',
    component: Pages.Privacy,
    exact: true
  },
  {
    path: '/pitch',
    component: Pages.Pitch,
    exact: true
  },
  {
    path: '/password/new/:token',
    component: Pages.ResetPassword,
    exact: true
  },
  {
    path: '/host-meetup',
    component: Pages.HostMeetup,
    exact: true
  },
  {
    path: '/report-bug',
    component: Pages.ReportBug,
    exact: true
  },
  {
    path: '/sent-meetups',
    component: Pages.SentMeetups,
    exact: true
  },
  {
    path: '/meetups', 
    component: Pages.Meetups,
    exact: true
  },
  {
    path: '/reported-bugs',
    component: Pages.ReportedBugs,
    exact: true
  },
  {
    path: '/applications',
    component: Pages.Applications,
    exact: true
  },
  {
    path: '/faq',
    component: Pages.FAQ,
    exact: true
  },
  {
    path: '/signal',
    component: Pages.SignalBroadcast,
    exact: true
  },
  {
    path: '/:id',
    component: Pages.Profile,
    exact: true
  }
];

export const openRoutes = [
  {
    path: '/',
    component: Pages.LandingPage,
    exact: true,
  },
  {
    path: '/privacy',
    component: Pages.Privacy,
    exact: true
  },
  {
    path: '/pitch',
    component: Pages.Pitch,
    exact: true
  },
  {
    path: '/verify-email/:id',
    component: Pages.EmailVerification,
    exact: true,
  },
  {
    path: '/onboarding',
    // exact: true,
    component: Pages.Onboarding,
  },
  {
    path: '/password/new/:token',
    component: Pages.ResetPassword,
    exact: true,
  },
];
