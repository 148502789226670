import React, { Fragment, Component } from 'react';
import { Image, Button, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { hermes, domain_from_url, expired } from '../../utilities';
import '../../css/profile.css';
import { success } from '../../messages';
import LinkWithLoader from '../LinkWithLoader';

class PersonalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editProfile: false,
    };
    this.addNewFavourite = this.addNewFavourite.bind(this);
  }

  async addNewFavourite(id) {
    const { favouriteStore } = this.props;
    try {
      const response = await favouriteStore.addNewFavourite({ member: id });
      hermes.success(success.ADDED_TO_FAVOURITES);
    } catch (error) {
      hermes.error(error);
    }
  }

  render() {
    const { editProfile } = this.state;
    const { otherUser, profileStore } = this.props;

    let userData = null;

    if (!otherUser) {
      userData = profileStore.userData;
    } else {
      userData = this.props.userData;
    }

    if (!userData) return null;

    console.log(userData.location, 'user location');
    return (
      <Fragment>
        <div className="personal-details">
          <Image
            width="145"
            height="145"
            circular
            src={
              userData.image ||
              'https://t4.ftcdn.net/jpg/02/15/84/43/240_F_215844325_ttX9YiIIyeaR7Ne6EaLLjMAmy4GvPC69.jpg'
            }
          />

          <div className="header">
            <div className="name">{userData.name} {/* !userData.scrapedUser ? <Image src="https://cdn1.iconfinder.com/data/icons/basic-ui-elements-coloricon/21/15-512.png" height="25" className="tick" /> : null */}</div>
          <div>
              {otherUser ? (
                <>
                  <Button
                    onClick={() => {
                      this.addNewFavourite(userData._id);
                    }}
                    primary
                  >
                    Save
                  </Button>
                  {(userData.bio && userData.bio.fbMessenger) ? (
                    <Button
                      as="a"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        userData.bio.fbMessenger.startsWith('http') ? 
                        userData.bio.fbMessenger :
                        'http://' + userData.bio.fbMessenger
                      }
                      primary
                    >
                      Message  
                    </Button>
                  ) : null}
                 </>
              ) : (
                  <LinkWithLoader to='/profile/edit' primary button>
                    Edit Profile
                  </LinkWithLoader>
                )}
            </div>
          </div>

          <div className="bio">

            <div className="top">
              {userData.location && userData.location.previousLocation ? (
                <span>
                  From
                  &nbsp;
                  <LinkWithLoader to={`/location/${userData.location.previousLocation.slug}`}>
                    <span className="">{userData.location.previousLocation.name.split(',')[0].trim()}</span>
                  </LinkWithLoader>
                  &nbsp;
                </span>
              ) : (
                  ''
                )}

              {userData.location && userData.location.currentLocation ? (
                <span>
                  In
                  &nbsp;
                  <LinkWithLoader to={`/location/${userData.location.currentLocation.slug}`}>
                    <span className="">{userData.location.currentLocation.name.split(',')[0].trim()}</span>
                  </LinkWithLoader>
                  &nbsp;
                </span>
              ) : (
                  ''
                )}

              {userData.location && userData.location.visitingLocation && !expired(userData.location.visitingLocationExpireAt) ? (
                <span>
                  Visiting
                  &nbsp;
                  <LinkWithLoader to={`/location/${userData.location.visitingLocation.slug}`}>
                    <span className="">{userData.location.visitingLocation.name.split(',')[0].trim()}</span>
                  </LinkWithLoader>
                  &nbsp;
                </span>
              ) : (
                  ''
                )}
            </div>

            <div className="middle">
              <div className="heading">{userData.bio ? userData.bio.headline : null}</div>
              {/* <div className="what-i-do">{'Programmer'}</div> */}
            </div>

            <div className="bottom">
              <a href={userData.bio && userData.bio.website ? userData.bio.website : ''} target="_blank">
                {userData.bio && userData.bio.website ? domain_from_url(userData.bio.website) : ''}
              </a>
              <div className="social">
                {[
                  {
                    name: 'github',
                    url: 'https://github.com/',
                  },
                  {
                    name: 'facebook',
                    url: 'https://facebook.com/',
                  },
                  {
                    name: 'instagram',
                    url: 'https://instagram.com/',
                  },
                  {
                    name: 'twitter',
                    url: 'https://twitter.com/',
                  },
                  {
                    name: 'linkedin',
                    url: 'https://linkedin.com/in/',
                  },
                  {
                    name: 'dribbble',
                    url: 'https://dribbble.com/',
                  },
                ].map(({ name, url }, key) => {
                  if (userData.bio && userData.bio[name]) {
                    return (
                      <Fragment key={key}>
                        <a href={userData.bio[name].startsWith('http') ? userData.bio[name] : `http://${userData.bio[name]}`} target="_blank">
                          <i className={'fa ' + `fa-${name}`} />
                        </a>
                      </Fragment>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          </div>
        </div>
        {/* userData.bio && userData.bio.miniResume && <div>{userData.bio.miniResume}</div> */}
      </Fragment>
    );
  }
}

export default inject('profileStore', 'favouriteStore')(observer(PersonalInfo));
