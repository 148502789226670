import React, { Component } from 'react';
import withRouter from 'react-router-dom/withRouter';
import { inject } from 'mobx-react';
import '../../css/reference.css';
import { hermes } from '../../utilities';
import { success } from '../../messages';
import { goTo } from '../../routing';

class EmailVerification extends Component {
  async componentDidMount() {
    window.ga('set', {
      page: '/verify',
      title: 'Verify Email'
    })

    window.ga('send', 'pageview')

    const { match, authStore, profileStore, history, loadingStore } = this.props;
    try {
      loadingStore.setLoading()
      const response = await authStore.verifyEmail({
        token: match.params.id,
      });
      if (authStore.loggedIn) {
        profileStore.updateUserObject(response);
        authStore.syncUser(response);
      }
      hermes.success(success.EMAIL_VERIFIED(response.first_name || response.name.split(' ')[0]), { timeout: 10000 })
    } catch (error) {
      hermes.error(error);
      console.log(error);
    } finally {
      loadingStore.setLoaded();
      history.push("/");
    }
  }

  render() {
    return null
  }
}

export default withRouter(inject('profileStore', 'authStore', 'loadingStore')(EmailVerification));
