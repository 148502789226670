import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Container } from 'semantic-ui-react';
import SubscriptionForm from '../../components/Subscription/SubscriptionForm.js';


class SubscribePage extends Component {
  componentDidMount() {
    window.ga('set', {
      page: '/subscribe',
      title: 'Subscribe Page'
    })

    window.ga('send', 'pageview')
  }

  render() {
    return (
      <div className="form-template-wrapper">
        <Container fluid id="form-template-container" className="form-template subscribe-page">
          <SubscriptionForm />
        </Container >
      </div >
    );
  }
}

export default withRouter(inject('subscriptionStore')(observer(SubscribePage)));
