import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { Popup, Label, Button } from 'semantic-ui-react';
import { PersonSearchItem as SearchItem } from './SearchItem';
import PeopleFilter from './PeopleFilter';
import DegreeTypePeopleFilter from './DegreeTypePeopleFilter';
import { mapReadableTitleToIndex } from '../../constants';
import { inject } from 'mobx-react';
import { async } from 'q';

class PeopleSearchView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      specialProps: {},
      selectedValues: {}
    };
    this.updateFilterCount = this.updateFilterCount.bind(this);
    this.clearFilterResults = this.clearFilterResults.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.addToSelected = this.addToSelected.bind(this);
    this.updateSelected = this.updateSelected.bind(this);
  }

  componentDidMount() {
    const { searchStore } = this.props;

    let selectedValues = {}

    Object.keys(searchStore.selectedFilters).forEach(value => {

      const formatedValues = searchStore.selectedValues[value].map(i => {
        return {
          label: i,
          checked: true
        }
      })
      this.setState(
        {
          [value]: searchStore.selectedFilters[value].length,
        },
      );
      selectedValues = {
        ...selectedValues,
        [value]: formatedValues
      }
    })

    this.setState({ selectedValues })
  }

  updateFilterCount(option) {
    return value => {
      this.setState({ [option]: value.length });
    };
  }

  clearFilterResults() {
    const { searchStore } = this.props;
    searchStore.initialSearch = true;
    searchStore.filterSearchResults = {}
  }

  clearFilters() {
    const { searchStore, history } = this.props;

    for (let key of Object.keys(searchStore.selectedFilters)) {
      searchStore.selectedFilters[key] = [];
      this.setState({ [key]: 0 })
    }

    searchStore.updateFilters([], history);
    searchStore.makeFilteredSearch();
  }

  addToSelected(index, value) {
    if (!this.state.selectedValues[index].find(i => i.label === value)) {
      this.setState({
        selectedValues: {
          ...this.state.selectedValues,
          [index]: [...this.state.selectedValues[index], { label: value, checked: true }]
        }
      })
    }
  }

  updateSelected(index, label, checked) {
    const newValues = this.state.selectedValues[index].map(value => {
      if (value.label == label) {
        return {
          label: label,
          checked: checked
        }
      }
      return value
    })

    this.setState({
      selectedValues: {
        ...this.state.selectedValues,
        [index]: newValues
      }
    })
  }

  handlePopupOpen(type) {
    return () => {
      this.setState({ [`is${type}Open`] : true })
    }
  }

  handlePopupClose(type) {
    return () => {
      this.setState({ [`is${type}Open`] : false })
    }
  }

  render() {
    let { results, addToFavourites, searchStore } = this.props;
    let children;
    if (results && results.hits) {
      children = results.hits
        .map((item, i) => <SearchItem addToFavourites={addToFavourites} {...item} key={i} />);
    } else {
      children = null;
    }

    return (
      <Fragment>
        <div className="people-filter-box mobile-tablet-only">
          <Popup
            inverted
            basic
            className="search-popup"
            verticalOffset={-20}
            position="bottom left"
            on="click"
            onOpen={this.handlePopupOpen('Location')}
            onClose={this.handlePopupClose('Location')}
            trigger={
              <a className="dropdown-toggle" style={this.state.isLocationOpen ? {color: 'white'} : undefined} onClick={this.clearFilterResults}>
                <span>Location</span>
                <Label className="search-count">{searchStore.selectedFilters[mapReadableTitleToIndex.location].length || ''}</Label>

                <svg style={this.state.isLocationOpen ? {fill: 'white'} : undefined} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="sp-icon-caret" id="more-btn">
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </a>
            }
            content={
              <PeopleFilter
                placeholder="Enter a location"
                index={mapReadableTitleToIndex.location}
                addToSelected={this.addToSelected}
                updateSelected={this.updateSelected}
                selectedValues={this.state.selectedValues}
                updateFilterCount={this.updateFilterCount(mapReadableTitleToIndex.location)}
              />
            }
          />

          <Popup
            inverted
            basic
            className="search-popup"
            verticalOffset={-20}
            position="bottom left"
            on="click"
            onOpen={this.handlePopupOpen('Skill')}
            onClose={this.handlePopupClose('Skill')}
            trigger={
              <a className="dropdown-toggle" style={this.state.isSkillOpen ? {color: 'white'} : undefined} onClick={this.clearFilterResults}>
                <span>Skill</span>
                <Label className="search-count">{searchStore.selectedFilters[mapReadableTitleToIndex.skill].length || ''}</Label>

                <svg style={this.state.isSkillOpen ? {fill: 'white'} : undefined} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="sp-icon-caret" id="more-btn">
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </a>
            }
            content={
              <PeopleFilter
                placeholder="Enter a skill"
                index={mapReadableTitleToIndex.skill}
                addToSelected={this.addToSelected}
                updateSelected={this.updateSelected}
                selectedValues={this.state.selectedValues}
                updateFilterCount={this.updateFilterCount(mapReadableTitleToIndex.skill)}
              />
            }
          />

          <Popup
            inverted
            basic
            className="search-popup"
            verticalOffset={-20}
            position="bottom left"
            on="click"
            onOpen={this.handlePopupOpen('University')}
            onClose={this.handlePopupClose('University')}
            trigger={
              <a className="dropdown-toggle" style={this.state.isUniversityOpen ? {color: 'white'} : undefined} onClick={this.clearFilterResults}>
                <span>University</span>
                <Label className="search-count">{searchStore.selectedFilters[mapReadableTitleToIndex.university].length || ''}</Label>

                <svg style={this.state.isUniversityOpen ? {fill: 'white'} : undefined} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="sp-icon-caret" id="more-btn">
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </a>
            }
            content={
              <PeopleFilter
                placeholder="Enter a university"
                index={mapReadableTitleToIndex.university}
                addToSelected={this.addToSelected}
                updateSelected={this.updateSelected}
                selectedValues={this.state.selectedValues}
                updateFilterCount={this.updateFilterCount(mapReadableTitleToIndex.university)}
              />
            }
          />

          <Popup
            inverted
            basic
            className="search-popup"
            verticalOffset={-20}
            position="bottom left"
            on="click"
            onOpen={this.handlePopupOpen('Company')}
            onClose={this.handlePopupClose('Company')}
            trigger={
              <a className="dropdown-toggle" style={this.state.isCompanyOpen ? {color: 'white'} : undefined} onClick={this.clearFilterResults}>
                <span>Company</span>
                <Label className="search-count">{searchStore.selectedFilters[mapReadableTitleToIndex.company].length || ''}</Label>

                <svg style={this.state.isCompanyOpen ? {fill: 'white'} : undefined} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="sp-icon-caret" id="more-btn">
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </a>
            }
            content={
              <PeopleFilter
                placeholder="Enter a company"
                index={mapReadableTitleToIndex.company}
                addToSelected={this.addToSelected}
                updateSelected={this.updateSelected}
                selectedValues={this.state.selectedValues}
                updateFilterCount={this.updateFilterCount(mapReadableTitleToIndex.company)}
              />
            }
          />

          <Popup
            inverted
            basic
            className="search-popup"
            verticalOffset={-20}
            position="bottom left"
            on="click"
            onOpen={this.handlePopupOpen('GraduationYear')}
            onClose={this.handlePopupClose('GraduationYear')}
            trigger={
              <a className="dropdown-toggle" style={this.state.isGraduationYearOpen ? {color: 'white'} : undefined} onClick={this.clearFilterResults}>
                <span>Graduation Year</span>
                <Label className="search-count">{searchStore.selectedFilters[mapReadableTitleToIndex.graduationYear].length || ''}</Label>

                <svg style={this.state.isGraduationYearOpen ? {fill: 'white'} : undefined} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="sp-icon-caret" id="more-btn">
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </a>
            }
            content={
              <PeopleFilter
                placeholder="Enter a graduation year"
                index={mapReadableTitleToIndex.graduationYear}
                addToSelected={this.addToSelected}
                updateSelected={this.updateSelected}
                selectedValues={this.state.selectedValues}
                updateFilterCount={this.updateFilterCount(mapReadableTitleToIndex.graduationYear)}
              />
            }
          />

          <Popup
            inverted
            basic
            className="search-popup"
            verticalOffset={-20}
            position="bottom left"
            on="click"
            onOpen={this.handlePopupOpen('DegreeType')}
            onClose={this.handlePopupClose('DegreeType')}
            trigger={
              <a className="dropdown-toggle" style={this.state.isDegreeTypeOpen ? {color: 'white'} : undefined} onClick={this.clearFilterResults}>
                <span>Degree Type</span>
                <Label className="search-count">{searchStore.selectedFilters[mapReadableTitleToIndex.degreeType].length || ''}</Label>

                <svg style={this.state.isDegreeTypeOpen ? {fill: 'white'} : undefined} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="sp-icon-caret" id="more-btn">
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </a>
            }
            content={
              <DegreeTypePeopleFilter
                placeholder="Enter a degree type"
                selectedValues={this.state.selectedValues}
                addToSelected={this.addToSelected}
                updateSelected={this.updateSelected}
                index={mapReadableTitleToIndex.degreeType}
                updateFilterCount={this.updateFilterCount(mapReadableTitleToIndex.degreeType)}
              />
            }
          />
          <div className="clear-wrapper">
            <Button primary onClick={this.clearFilters}>Clear</Button>
          </div>
        </div>


        <div>{children}</div>
      </Fragment >
    );
  }
}

export default withRouter(inject('searchStore')(PeopleSearchView));
